import Vue from 'vue';
import moment from 'moment';
import { getVariantByStatus } from '@/helpers/ComponentHelper';

Vue.filter('getVariantByStatus', (value) => {
  if (value) {
    return getVariantByStatus(value);
  }

  return null;
});

Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY');
  }

  return value;
});

Vue.filter('manageEmpty', (value) => {
  if (!value || value === undefined) {
    return null;
  }

  return value;
});
