<template>
  <SideBar
    class="SideForm__bar"
    :id="id"
    :isLoading="isLoading"
    @handleAccept="handleCreate"
    @onHiddenSidebar="handleCancel"
    showFooter
  >
    <slot></slot>
    <RequestForm
      v-if="show"
      id="createRequestForm"
      ref="createRequestForm"
      :title="$i18n.t('lb_new_request')"
      :validationState="validationState"
      :isLoading="isLoading"
      @handleCancel="handleCancel"
    />
  </SideBar>
</template>

<script>
import { uuid } from 'vue-uuid';
import SideBar from '@/components/atoms/SideBar/SideBar.vue';
import RequestForm from '@/components/organisms/Request/RequestForm.vue';
import { getCaseNumber } from '@/helpers/ApiHelper';
import { getValidationState, isValidForm } from '@/helpers/ValidationHelper';
import { getCurrentDate } from '@/helpers/ComponentHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      validationState: {
        patient: null,
        specialty: null,
        level: null,
        pathology: null,
        medicalProcedure: null,
        clinic: null,
      },
    };
  },
  methods: {
    handleCreate() {
      const data = this.$refs.createRequestForm.getData();

      const patientValidationState = getValidationState(
        data.patient?.id,
      );

      const specialtyValidationState = getValidationState(
        data.specialty?.id,
      );

      const levelValidationState = getValidationState(
        data.level,
      );

      const pathologyValidationState = getValidationState(
        data.pathology,
      );

      const clinicValidationState = this.isClinic ? this.user.uuid : getValidationState(
        data.clinic?.id,
      );

      const medicalProcedureValidationState = getValidationState(
        data.medicalProcedure,
      );

      if (isValidForm([
        patientValidationState,
        specialtyValidationState,
        levelValidationState,
        pathologyValidationState,
        clinicValidationState,
        medicalProcedureValidationState,
      ])) {
        this.create(data);
      } else {
        this.validationState.patient = patientValidationState;
        this.validationState.specialty = specialtyValidationState;
        this.validationState.level = levelValidationState;
        this.validationState.pathology = pathologyValidationState;
        this.validationState.clinic = clinicValidationState;
        this.validationState.medicalProcedure = medicalProcedureValidationState;
      }
    },

    getNotesForRequest(notes) {
      if (!notes) return null;

      const newNotes = notes.map((note) => {
        const newNote = {
          id: note.id,
          content: note.content,
        };

        return newNote;
      });

      return newNotes;
    },

    getDocumentsForRequest(documents) {
      if (!documents) return null;

      const newDocuments = documents.map((document) => {
        const newDocument = {
          id: uuid.v4(),
          documentId: document.id,
        };

        return newDocument;
      });

      return newDocuments;
    },

    create(data) {
      const oldData = { ...data };
      const caseNumber = getCaseNumber();

      const newData = {
        caseNumber,
        patient: data.patient?.id,
        specialty: data.specialty?.id,
        level: data.level,
        pathology: data.pathology,
        medicalProcedure: data.medicalProcedure,
        hasInsurance: data.hasInsurance,
        hasRecentReports: data.hasRecentReports,
        healthManager: data.healthManager?.id,
        clinic: this.isClinic ? this.user.uuid : data.clinic?.id,
        doctor: data.doctor?.id,
        notes: this.getNotesForRequest(data.notes),
        documents: this.getDocumentsForRequest(data.documents),
      };

      this.isLoading = true;

      this.$store.dispatch('request/createRequest', newData)
        .then((res) => {
          const resData = { ...res };
          resData.caseNumber = caseNumber;
          resData.requestStage = {
            id: '71a95825-9fed-43a3-b3da-ae181881b720', // I´m not srure to hardcode this.
            name: 'Recibida',
          };
          resData.patient = oldData.patient;
          resData.clinic = oldData.clinic;
          resData.healthManager = oldData.healthManager;
          resData.doctor = oldData.doctor;
          resData.createdAt = getCurrentDate();
          resData.specialty = oldData.specialty;
          resData.status = oldData.status;
          this.$emit('addRequest', resData);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    handleCancel() {
      this.$emit('handleCancel');
      this.resetValidations();
    },

    resetValidations() {
      this.validationState.patient = null;
      this.validationState.specialty = null;
      this.validationState.level = null;
      this.validationState.pathology = null;
      this.validationState.medicalProcedure = null;
      this.validationState.clinic = null;
    },
  },
  computed: {
    user() {
      return this.$store.getters['user/getCurrentUser'];
    },

    isClinic() {
      return this.$store.getters['user/isClinic'];
    },
  },
  components: {
    SideBar,
    RequestForm,
  },
};
</script>

<style lang="sass">
@import './SideForm.sass'
</style>
