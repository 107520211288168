import {
  login, userPasswordReset, userPasswordUpdate, verifyUser,
} from './authActions';

import logUser from './authMutations';
import hasAuth from './authGetters';
import StorageManager from '../../helpers/StorageManager';

const auth = {
  namespaced: true,
  state: () => ({
    token: StorageManager.getToken() || null,
    refreshToken: StorageManager.getRefreshToken() || null,
  }),
  mutations: {
    logUser,
  },
  actions: {
    login,
    verifyUser,
    userPasswordReset,
    userPasswordUpdate,
  },
  getters: {
    hasAuth,
  },
};

export default auth;
