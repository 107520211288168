<template>
  <div class="Table">
    <b-table
      :items="items"
      :fields="fields"
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
    >
      <template #cell(name)="data">
        <UserSmallInfo
          :icon="getIcon(data.item.avatar)"
          :label="data.item.name"
          :text="data.item.city"
        />
      </template>
      <template #cell(city)="data">
        <Badge variant="neutral">
          {{ data.item.city }}
        </Badge>
      </template>
      <template #cell(requests)="data">
        <span class="text__sr text-secondary">{{ data.item.requests }}</span>
      </template>
      <template #cell(totalRequests)="data">
        <span class="text__sr text-secondary">{{ data.item.totalRequests }}</span>
      </template>
      <template #cell(patients)="data">
        <span class="text__sr text-secondary">{{ data.item.patients }}</span>
      </template>
      <template #cell(actions)="data">
        <IconActionsGroup
          @edit="showUpdateForm"
          @delete="showDeleteModal"
          :objectData="data.item"
          :canCopy="false"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import IconActionsGroup from '@/components/atoms/Icon/IconActionsGroup/IconActionsGroup.vue';
import UserSmallInfo from '@/components/atoms/User/UserSmallInfo/UserSmallInfo.vue';
import Badge from '@/components/atoms/Badge/Badge.vue';
import fields from './ClinicTableFields';
import { getAvatarIcon } from '@/helpers/UserHelper';

export default {
  props: {
    clinicItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy: 'case',
      sortDesc: false,
      items: this.clinicItems,
      fields,
    };
  },
  methods: {
    onRowSelected(items) {
      this.$emit('handleSelectRow', items[0]);
    },

    showUpdateForm(data) {
      this.$emit('showUpdateForm', data);
    },

    showDeleteModal(data) {
      this.$emit('showDeleteModal', data);
    },

    getIcon(avatar) {
      return getAvatarIcon(avatar);
    },
  },
  watch: {
    sortBy(newValue) {
      this.$emit('handleSortAsc', newValue);
    },

    sortDesc(newValue) {
      if (newValue === true) {
        this.$emit('handleSortDesc', this.sortBy);
      } else {
        this.$emit('handleSortAsc', this.sortBy);
      }
    },
  },
  components: {
    IconActionsGroup,
    UserSmallInfo,
    Badge,
  },
};
</script>

<style lang="sass" scoped>
  @import '../Table.sass'
</style>
