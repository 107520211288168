import getAllKpi from './kpiActions';

const kpi = {
  namespaced: true,
  actions: {
    getAllKpi,
  },
};

export default kpi;
