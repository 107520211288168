import axios from '../services/index';
import AuthFactory from './AuthFactory';

const AUTH = '/api/auth/login';
const VERIFY = '/api/users/activate';
const PASSWORD_RESET = '/api/auth/password/reset';
const PASSWORD_UPDATE = '/api/auth/password/update';

class AuthAPI {
  constructor() {
    this.client = axios;
  }

  getJWTToken(credentials) {
    return this.client
      .post(AUTH, credentials)
      .then((res) => AuthFactory.getAuth(res.data))
      .catch((error) => Promise.reject(error));
  }

  verifyUser(data) {
    return this.client
      .post(VERIFY, data)
      .then((res) => AuthFactory.getAuth(res.data))
      .catch((error) => Promise.reject(error));
  }

  passwordReset(data) {
    return this.client
      .post(PASSWORD_RESET, data)
      .then(() => true)
      .catch((error) => Promise.reject(error));
  }

  passwordUpdate(data) {
    return this.client
      .post(PASSWORD_UPDATE, data)
      .then((res) => AuthFactory.getAuth(res.data))
      .catch((error) => Promise.reject(error));
  }
}

export default AuthAPI;
