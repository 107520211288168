<template>
  <ModalAction
    :id="id"
    :title="getTitle"
    :okLabel="$i18n.t('btn_update')"
    :cancelLabel="$i18n.t('btn_cancel')"
    @handleOk="uploadNote"
    :hideFooter="isDisabled"
    :isLoading="isLoading"
  >
    <Textarea
      @handlerChange="handlerChange"
      :value="this.note.content"
      :disabled="isDisabled"
    />
  </ModalAction>
</template>

<script>
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';
import Textarea from '@/components/atoms/Textarea/Textarea.vue';
import { getCurrentDate } from '@/helpers/ComponentHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      note: {
        id: null,
        content: '',
        createdAt: '',
      },
    };
  },
  methods: {
    setNote(note) {
      this.note.id = note.id;
      this.note.content = note.content;
      this.note.createdAt = note.createdAt;
    },

    handlerChange(content) {
      this.note.content = content;
    },

    uploadNote() {
      const newNote = {
        id: this.note.id,
        content: this.note.content,
        createdAt: getCurrentDate(),
      };

      this.$emit('handleUploadNote', newNote);
    },
  },
  computed: {
    isDisabled() {
      return !this.isEditable;
    },

    getTitle() {
      return this.isDisabled ? this.$i18n.t('lb_note') : this.$i18n.t('lb_add_note');
    },
  },
  components: {
    ModalAction,
    Textarea,
  },
};
</script>
