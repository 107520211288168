import Vue from 'vue';
import VueI18n from 'vue-i18n';
import esMessages from '../translations/spanish/es-messages';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'es',
  messages: {
    es: esMessages,
  },
});

export default i18n;
