import NotificationApi from '../../domain/notification/NotificationApi';

export function getAllNotification({ commit }, data) {
  const notificationApi = new NotificationApi();
  const getAllRequest = notificationApi.getAllNotification(data);

  return getAllRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteNotification({ commit }, uuid) {
  const notificationApi = new NotificationApi();
  const deleteRequest = notificationApi.deleteNotification(uuid);

  return deleteRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
