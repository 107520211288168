<template>
  <div>
    <b-form>
      <b-form-group
        class="text__psb"
        :id="`${id}-group`"
        :label="label"
        :label-for="`${id}-datepicker`"
      >
        <Datepicker
          :id="`${id}-group`"
          :placeholder="placeholder"
          :value="value"
          @handleChangeDate="handleChangeDate"
          :isValid="isValid"
          :error="error"
          :help="help"
        />
        <b-form-invalid-feedback
          v-if="isValid === false"
          class="text__sr"
        >
          {{ error }}
        </b-form-invalid-feedback>
        <b-form-text
          v-if="showHelp"
          class="text__sr"
        >
          {{ help }}
        </b-form-text>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import Datepicker from '@/components/atoms/Datepicker/Datepicker.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: String,
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: null,
    },
    help: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleChangeDate(date) {
      this.$emit('handleChangeDate', date);
    },
  },
  computed: {
    showHelp() {
      return this.help.length > 0;
    },
  },
  components: {
    Datepicker,
  },
};
</script>
