import { render, staticRenderFns } from "./ClinicTable.vue?vue&type=template&id=49aa6374&scoped=true&"
import script from "./ClinicTable.vue?vue&type=script&lang=js&"
export * from "./ClinicTable.vue?vue&type=script&lang=js&"
import style0 from "./ClinicTable.vue?vue&type=style&index=0&id=49aa6374&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49aa6374",
  null
  
)

export default component.exports