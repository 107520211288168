<template>
  <div>
    <div class="sp__v__3x"/>
    <HeadingBar
      :title="$i18n.t('cp_patient_contact_title')"
      :text="$i18n.t('cp_patient_contact_text')"
      :isSmall="true"
    />

    <hr />

    <div v-if="hasValidEmailFormat" class="sp__v"/>
    <LabelGroup
      v-if="hasValidEmailFormat"
      :label="`${$i18n.t('lb_email')}:`"
      :text="patient.email"
    />
    <div class="sp__v"/>
    <LabelGroup
      :label="`${$i18n.t('lb_phone')}:`"
      :text="patient.phone"
    />

    <div class="sp__v__2x"/>

    <WhatsappButton
      v-if="patient.contactWhatsApp && patient.phone"
      :phone="patient.phone"
    />
  </div>
</template>

<script>
import LabelGroup from '@/components/atoms/Label/LabelGroup.vue';
import HeadingBar from '@/components/molecules/HeadingBar/HeadingBar.vue';
import WhatsappButton from '@/components/atoms/Buttons/WhatsappButton/WhatsappButton.vue';
import { checkEmailFormat } from '@/helpers/UserHelper';

export default {
  props: {
    patient: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleEdit() {
      this.$emit('showUpdateForm', this.patient);
    },

    handleDelete() {
      this.$emit('showDeleteConfirm', this.patient);
    },

    hideDetail() {
      this.$emit('hideDetail');
    },
  },
  computed: {
    getFullName() {
      return `${this.patient.name} ${this.patient.lastname}`;
    },

    hasValidEmailFormat() {
      return checkEmailFormat(this.patient.id, this.patient.email);
    },
  },
  components: {
    LabelGroup,
    HeadingBar,
    WhatsappButton,
  },
};
</script>
