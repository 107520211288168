<template>
  <HealthManagerForm
    id="update-health-manager"
    ref="updateHealthManagerForm"
    :title="$i18n.t('lb_update_health_manager')"
    :healthManager="getHealthManager"
    :isLoading="isLoading"
    @handleOk="update"
  />
</template>

<script>
import HealthManagerForm from './HealthManagerForm.vue';
import { getAvatarBase64 } from '@/helpers/UserHelper';

export default {
  props: {
    healthManager: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    update(data) {
      this.isLoading = true;

      const requestData = {
        id: data.id,
        body: {
          ...(this.getAvatar(data.avatar)),
          email: data.email,
          lastname: data.lastname,
          market: data.market.id,
          name: data.name,
          phone: data.phone,
        },
      };

      this.$store.dispatch('healthManager/updateHealthManager', requestData)
        .then(() => {
          this.$emit('updateHealthManager', data);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getAvatar(avatar) {
      return getAvatarBase64(avatar);
    },
  },
  computed: {
    getHealthManager() {
      return this.healthManager;
    },
  },
  components: {
    HealthManagerForm,
  },
};
</script>
