<template>
  <b-list-group>
    <b-list-group-item
      v-for="(item, index) of items"
      :key="index"
      href="#"
      @click="handleSuggestion(item.text)">
      {{ item.text }}
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      selectedSuggestion: null,
    };
  },
  methods: {
    handleSuggestion(val) {
      this.selectedSuggestion = val;
      this.$emit('handlerSelectSuggestion', this.selectedSuggestion);
    },
  },
};
</script>
