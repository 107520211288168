<template>
  <SideBar
    class="SideForm__bar"
    :id="id"
    @onHiddenSidebar="handleCancel"
  >
    <RequestDetailForm
      :request="request"
      @handleChangeRequest="handleChangeRequest"
    />
  </SideBar>
</template>

<script>
import SideBar from '@/components/atoms/SideBar/SideBar.vue';
import RequestDetailForm from './RequestDetailForm.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    request: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleChangeRequest(uuid, status) {
      this.$emit('handleChangeRequest', uuid, status);
    },

    handleCancel() {
      this.$emit('handleCancel');
    },
  },
  components: {
    SideBar,
    RequestDetailForm,
  },
};
</script>

<style lang="sass">
  @import './SideForm.sass'
</style>
