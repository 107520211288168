<template>
  <div class="Table Table__list">
    <b-table
      thead-class="d-none"
      :items="items"
      :fields="fields"
    >
      <template #cell(info)="data">
        <p class="text__psbb text-dark">{{ data.item.info.label }}</p>
        <div class="sp__v__1s5x"/>
        <p class="text__pr text-soft-dark">{{ data.item.info.text }}</p>
      </template>
      <template #cell(actions)="">

        <div class="Table__list__actions">
          <b-button variant="secondary">
            <img src="@/assets/actions/preview.svg" />
            <div class="sp__1s5x"/>
            Preview
          </b-button>
          <div class="sp"/>
          <b-button variant="outline-primary">
            Acciones
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>

export default {
  data() {
    return {
      sortBy: 'case',
      sortDesc: false,
      items: [
        {
          info: {
            label: 'Documento_1.pdf',
            text: 'Última actualización: 10/05/2021',
          },
        },
        {
          info: {
            label: 'Documento_2.pdf',
            text: 'Última actualización: 09/05/2021',
          },
        },
      ],
      fields: [
        { key: 'info', label: 'Name', sortable: true },
        { key: 'actions', label: '' },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
  @import './Table.sass'
</style>
