<template>
  <private-template>
    <template v-slot:private-template>
      <div class="UIKitComponents">
        <span class="sp__v__2x"/>
        <h1>Buttons</h1>
        <span class="sp__v__2x"/>
        <div class="d-flex">
          <b-button variant="primary">
            Aceptar
          </b-button>
          <span class="sp"/>
          <b-button variant="secondary">
            Rechazar
          </b-button>
          <span class="sp"/>
          <b-button variant="secondary">
            + Button CTA
          </b-button>
          <span class="sp"/>
          <b-button variant="secondary">
            <img src="@/assets/user/user-icon.svg" />
            <div class="sp__1s5x"/>
            Search candidate
          </b-button>
        </div>
        <span class="sp__v__2x"/>
        <div class="d-flex">
          <b-button variant="primary" size="sm">
            + Nueva
          </b-button>
          <span class="sp"/>
          <b-button variant="secondary" size="sm">
            + Button CTA
          </b-button>
        </div>
        <span class="sp__v__2x"/>
        <div class="d-flex">
          <Badge variant="secondary">
            Vidasprime
          </Badge>
          <span class="sp"/>
          <Badge variant="success">
            Badge success ✓
          </Badge>
          <span class="sp"/>
          <Badge variant="primary">
            Badge primary
          </Badge>
          <span class="sp"/>
          <Badge variant="danger">
            Badge error
          </Badge>
        </div>
        <span class="sp__v__2x"/>
        <ButtonComboAction />
        <span class="sp__v__2x"/>
        <div class="d-flex">
          <Dropdown text="Past 30 days">
            <b-dropdown-item-button @click="handleTest">Editar</b-dropdown-item-button>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item-button>Convertir en paciente</b-dropdown-item-button>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item-button>Nueva actividad</b-dropdown-item-button>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item-button>Borrar</b-dropdown-item-button>
            <b-dropdown-divider></b-dropdown-divider>
          </Dropdown>
          <span class="sp"/>
          <Dropdown text="Search">
            <Input
              id="searchId"
              type="text"
              placeholder="Search"
              @handlerInputChange="handleTestWithData"
            />
            <hr />
            <b-dropdown-item-button>
              <div class="d-flex">
                <img src="@/assets/user/user-icon.svg" />
                <div class="sp__1s5x"/>
                User Name
              </div>
            </b-dropdown-item-button>
            <div class="sp__v"/>
            <b-dropdown-item-button>
              <div class="d-flex">
                <img src="@/assets/user/user-icon.svg" />
                <div class="sp__1s5x"/>
                User Name
              </div>
            </b-dropdown-item-button>
            <div class="sp__v"/>
            <b-dropdown-item-button>
              <div class="d-flex">
                <img src="@/assets/user/user-icon.svg" />
                <div class="sp__1s5x"/>
                User Name
              </div>
            </b-dropdown-item-button>
            <div class="sp__v"/>
            <b-dropdown-item-button>
              <div class="d-flex">
                <img src="@/assets/user/user-icon.svg" />
                <div class="sp__1s5x"/>
                User Name
              </div>
            </b-dropdown-item-button>
            <div class="sp__v"/>
            <b-dropdown-item-button>
              <div class="d-flex">
                <img src="@/assets/components/plus-primary.svg" />
                <div class="sp__1s5x"/>
                <span class="text-primary">New User</span>
              </div>
            </b-dropdown-item-button>
            <div class="sp__v"/>
          </Dropdown>
        </div>
        <span class="sp__v__2x"/>
        <h1>Headings</h1>
        <span class="sp__v__2x"/>
        <div class="pr-4 w-100">
          <HeadingBar
            title="Solicitudes"
            text="Buenos días, Miguel. Explora tus solicitudes."
          >
          </HeadingBar>
          <div class="sp__v__2x"/>
          <HeadingBar
            title="Philippe Hong"
            text="Creado el 25/05/2021"
            :isData="true"
          >
            <div class="d-flex">
              <b-button variant="secondary">
                Rechazar
              </b-button>
              <span class="sp"/>
              <b-button variant="primary">
                Aceptar
              </b-button>
            </div>
          </HeadingBar>
          <div class="sp__v__2x"/>
          <HeadingBar
            title="Philippe Hong"
            text="Creado el 25/05/2021"
            :isData="true"
          >
            <div class="d-flex">
              <Dropdown text="Etapa: Revisión documentación">
                <b-dropdown-item-button>Hello World!</b-dropdown-item-button>
              </Dropdown>
              <span class="sp"/>
              <b-button variant="secondary">
                Acciones
              </b-button>
            </div>
          </HeadingBar>
          <div class="sp__v__2x"/>
          <HeadingBar
            title="Información del paciente"
            text="Información básica sobre el paciente."
            :isSmall="true"
          >
          </HeadingBar>
          <div class="sp__v__2x"/>
          <hr />
          <div class="sp__v__2x"/>
          <HeadingBar
            title="Philippe Hong"
            text="Nº de caso: 000 / 0000"
            :isData="true"
          >
            <div class="d-flex">
              <b-button variant="secondary">
                + Asignar Doctor
              </b-button>
              <span class="sp"/>
              <Dropdown text="Etapa: Revisión documentación">
                <b-dropdown-item-button>Hello World!</b-dropdown-item-button>
              </Dropdown>
              <span class="sp"/>
              <b-button variant="secondary">
                Acciones
              </b-button>
            </div>
          </HeadingBar>
          <div class="sp__v__2x"/>
          <HeadingBar
            title="Philippe Hong"
            text="Nº de caso: 000 / 0000"
            :isData="true"
          >
            <div class="d-flex">
            <b-button variant="secondary">
              <img src="@/assets/user/user-icon.svg" />
              <div class="sp__1s5x"/>
              Dr. Alfredo Castillo
            </b-button>
              <span class="sp"/>
              <Dropdown text="Etapa: Revisión documentación">
                <b-dropdown-item-button>Hello World!</b-dropdown-item-button>
              </Dropdown>
              <span class="sp"/>
              <b-button variant="secondary">
                Acciones
              </b-button>
            </div>
          </HeadingBar>
        </div>

        <div class="sp__v__2x"/>
        <h1>Tables</h1>
        <div class="sp__v__2x"/>

        <div class="pr-4 w-100">
          <TableClinicGroup/>
          <div class="sp__v__2x"/>
          <TableList />
        </div>

        <div class="sp__v__2x"/>
        <h1>Navigation</h1>
        <div class="sp__v__2x"/>

        <Breadcrumb
          :items="[
            {
              name: 'Inbox',
            },
            {
              name: 'Pillippe_Hong',
            },
          ]"
        />
        <div class="pr-4 w-100">
          <Pagination
            @handleRequest="paginate"
          />
        </div>
        <span class="sp__v__2x"/>
        <b-tabs>
          <b-tab title="Información">Hello world!</b-tab>
          <b-tab title="Documentos">¡Holi mundo!</b-tab>
          <b-tab>
            <template #title>
              <div class="d-flex">
                <inline-svg
                  class="Tabs__icon"
                  :src="require('@/assets/user/user-icon.svg')"
                />
                <div class="sp__1s5x"/>
                <span>Contacto</span>
              </div>
            </template>
            <p>¡Hola mundo!</p>
          </b-tab>
        </b-tabs>

        <div class="sp__v__2x"/>
        <h1>Forms</h1>
        <div class="sp__v__2x"/>

        <LabelGroup
          label="Nombre:"
          text="Phillippe"
        />

        <div class="sp__v__2x"/>

        <InputGroup
          id="testInputGroup"
          label="Nombre"
          :isValid="null"
        />

        <div class="sp__v"/>

        <SelectGroup
          id="testSelectGroup"
          label="Origen"
          :isValid="null"
          :options="[
            { value: null, text: 'Seleccionar origen' },
            { value: 'iraq', text: 'Iraq' },
            { value: 'turquía', text: 'Turquía' },
          ]"
          error="This is an error message"
        />

        <div class="sp__v"/>

        <DatepickerGropup
          id="testDatepickerGroup"
          placeholder="Pick your date"
          label="Date of Birth"
        />

        <div class="sp__v"/>

        <InputGroup
          id="testInputGroupError"
          label="Email address"
          :isValid="false"
          error="This is an error message"
        />

        <div class="sp__v"/>

        <TextareaGroup
          id="testTextAreaGroup"
          label="Descripción"
          placeholder="Type something"
        />

        <div class="sp__v"/>

        <TextGroup
          label="Descripción"
          text="Está situado en la confluencia de la V-35 y la Ronda Norte."
        />

        <div class="sp__v"/>

        <button id="tooltip-target-1">
          <img src="@/assets/components/question.svg" />
        </button>
        <b-tooltip target="tooltip-target-1" triggers="hover" placement="right">
          Something helpful
        </b-tooltip>

        <div class="sp__v"/>

        <Checkbox
          id="checkbox-2"
        />

        <div class="sp__v"/>

        <Checkbox
          id="checkbox-1"
          label="Checkbox"
        />

        <div class="sp__v"/>

        <Checkbox
          id="checkbox-3"
          label="Checkbox"
          description="Decription of what this will do."
        />

        <div class="sp__v"/>

        <Checkbox
          id="checkbox-4"
          label="Switch button"
          description="Decription of what this will do."
          isSwitch
        />

        <div class="sp__v__2x"/>

        <div class="pr-4 w-100">
          <FileUploader isImage/>
          <div class="sp__v__2x"/>
          <FileUploader />
        </div>

        <div class="sp__v__2x"/>
        <h1>Placeholders</h1>
        <div class="sp__v__2x"/>

        <div class="pr-4 w-100">
          <UserMiniCard
            icon="https://placekitten.com/30/30"
            label="Health Manager"
          />
        </div>

         <div class="sp__v__2x"/>

         <div class="d-flex">
          <ImagePlaceholder />
          <div class="sp__3x"/>
          <ImagePlaceholder isRounded/>
         </div>

        <div class="sp__v__2x"/>
        <h1>Modals</h1>
        <div class="sp__v__2x"/>

        <b-button v-b-modal.modal-action>Show Action Modal</b-button>
        <ModalAction
          id="modal-action"
          okLabel="Confirmar"
          cancelLabel="Cancelar"
          @handleOk="handleTest"
          isCentered
          :hideHeader="true"
        >
          <div class="text-center">
            <p class="text__title">¿Estás seguro?</p>
            <div class="sp__v__1s5x"/>
            <p class="text__pr">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.
            </p>
          </div>
        </ModalAction>

        <div class="sp__v__2x"/>

        <b-button v-b-modal.modal-form>Show Form Modal</b-button>
        <ModalAction
          id="modal-form"
          okLabel="Rechazar"
          cancelLabel="Cancelar"
          @handleOk="handleTest"
          title="Rechazar solicitud"
        >
          <TextareaGroup
            id="testTextAreaGroup2"
            label="Motivos"
            placeholder="Escribe los motivos por los que rechazas la solicitud"
            help="Es importante conocer tus motivos para mejorar..."
          />
        </ModalAction>

        <div class="sp__v__2x"/>
        <h1>Other</h1>
        <div class="sp__v__2x"/>

        <PasswordChange />

        <div class="sp__v__2x"/>

        <Spinner :isLoading="true" />

      </div>
    </template>
  </private-template>
</template>

<script>
import PrivateTemplate from '@/components/organisms/PrivateTemplate.vue';
import Badge from '@/components/atoms/Badge/Badge.vue';
import ButtonComboAction from '@/components/atoms/Buttons/ButtonComboAction/ButtonComboAction.vue';
import Dropdown from '@/components/atoms/Dropdown/Dropdown.vue';
import Input from '@/components/atoms/Input/Input.vue';
import HeadingBar from '@/components/molecules/HeadingBar/HeadingBar.vue';
import TableClinicGroup from '@/components/molecules/Tables/TableGroup/TableClinicGroup.vue';
import TableList from '@/components/molecules/Tables/TableList.vue';
import Breadcrumb from '@/components/atoms/Breadcrumb/Breadcrumb.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import LabelGroup from '@/components/atoms/Label/LabelGroup.vue';
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import DatepickerGropup from '@/components/atoms/Datepicker/DatepickerGropup.vue';
import TextareaGroup from '@/components/atoms/Textarea/TextareaGroup.vue';
import Checkbox from '@/components/atoms/Checkbox/Checkbox.vue';
import FileUploader from '@/components/atoms/User/FileUploader/FileUploader.vue';
import UserMiniCard from '@/components/molecules/User/UserMiniCard/UserMiniCard.vue';
import ImagePlaceholder from '@/components/atoms/Placeholder/ImagePlaceholder.vue';
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';
import PasswordChange from '@/components/molecules/PasswordChange/PasswordChange.vue';
import Spinner from '@/components/atoms/Spinner/Spinner.vue';
import TextGroup from '@/components/atoms/TextGroup/TextGroup.vue';

export default {
  components: {
    PrivateTemplate,
    Badge,
    ButtonComboAction,
    Dropdown,
    Input,
    HeadingBar,
    TableClinicGroup,
    TableList,
    Breadcrumb,
    Pagination,
    LabelGroup,
    InputGroup,
    SelectGroup,
    DatepickerGropup,
    TextareaGroup,
    Checkbox,
    FileUploader,
    UserMiniCard,
    ImagePlaceholder,
    ModalAction,
    PasswordChange,
    Spinner,
    TextGroup,
  },
  methods: {
    paginate(currentPage, perPage, rows) {
      /* eslint-disable no-console */
      console.log('currentPage', currentPage);
      console.log('perPage', perPage);
      console.log('rows', rows);
    },
    handleTest() {
      console.log('test');
    },
    handleTestWithData(text) {
      console.log('test with data:', text);
    },
  },
};
</script>

<style lang="sass" scoped>
.UIKitComponents
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-start
</style>
