<template>
  <div class="UserCard">
    <Avatar
      :image="getAvatarIcon(user.avatar)"
      size="30px"
    />
    <div class="UserCard__block">
      <div class="UserCard__info">
        <div>
          <p class="text__psb">{{ user.name }}</p>
          <p class="text__sr">{{ user.email }}</p>
        </div>
        <div class="sp__3x"/>
        <b-button
          variant="primary"
          @click.prevent="handleEdit"
        >
          {{ $i18n.t('btn_edit') }}
        </b-button>
      </div>
      <div class="spr"/>
      <button
        class="text__sr"
        @click="closeSession()"
      >
        {{ $i18n.t('btn_close_session') }}
      </button>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/atoms/Avatar/Avatar.vue';
import { logout, getAvatarByProps } from '@/helpers/UserHelper';

export default {
  props: {
    user: {
      type: Object,
    },
  },
  methods: {
    closeSession() {
      this.$store.commit('initState');
      logout();
    },

    handleEdit() {
      this.$emit('handleOpenEditUser');
    },

    getAvatarIcon(avatar) {
      return getAvatarByProps(avatar);
    },
  },
  components: {
    Avatar,
  },
};
</script>

<style lang="sass" scoped>
@import './UserCard.sass'
</style>
