import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import {
  getDataWithUuid,
  getParameterUrl,
  getSearchUrl,
} from '../../helpers/ApiHelper';
import RequestFactory from './RequestFactory';

import { removeAccents } from '../../helpers/ComponentHelper';

const REQUESTS = '/api/requests';
const SEARCH_REQUESTS = '/api/requests/search';
const STATUS = '/status';
const DOCUMENTS = '/documents/';
const DOWNLOAD_DOCUMENTS = '/api/documents/';

class requestAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getRequest(data) {
    const url = `${REQUESTS}/${data.requestId}${DOCUMENTS}/${data.documentId}`;

    return this.client
      .get(url)
      .then((res) => RequestFactory.getRequest(res.data))
      .catch((error) => Promise.reject(error));
  }

  getRequestDocuments(requestId) {
    const url = `${REQUESTS}/${requestId}${DOCUMENTS}`;

    return this.client
      .get(url)
      .then((res) => RequestFactory.getDocuments(res.data.collection))
      .catch((error) => Promise.reject(error));
  }

  getAllRequest(currentPage) {
    const defaultOrder = 'createdAt:desc';

    const parameterUrl = getParameterUrl(SEARCH_REQUESTS, currentPage, '', defaultOrder);

    return this.client
      .get(parameterUrl)
      .then((res) => RequestFactory.getAllRequest(res.data))
      .catch((error) => Promise.reject(error));
  }

  filterRequest(data) {
    const parameterUrl = getParameterUrl(
      SEARCH_REQUESTS,
      data.page,
      data.filters,
      data.ordenation,
    );

    return this.client
      .get(parameterUrl)
      .then((res) => RequestFactory.getAllRequest(res.data))
      .catch((error) => Promise.reject(error));
  }

  searchRequest(filters) {
    const filterUrl = getSearchUrl(SEARCH_REQUESTS, filters);

    return this.client
      .get(filterUrl)
      .then((res) => RequestFactory.getAllRequest(res.data))
      .catch((error) => Promise.reject(error));
  }

  createRequest(data) {
    const idData = getDataWithUuid(data);

    return this.client
      .post(REQUESTS, idData)
      .then(() => this.getRequestDocuments(data.id)
        .then((res) => {
          const newRequestData = { ...data };
          newRequestData.documents = res;

          return newRequestData;
        }))
      .catch((error) => Promise.reject(error));
  }

  updateRequest(requestData) {
    const url = `${REQUESTS}/${requestData.id}`;

    return this.client
      .put(url, requestData.body)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteRequest(id) {
    const url = `${REQUESTS}/${id}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }

  changeRequestStatus(requestData) {
    const url = `${REQUESTS}/${requestData.id}${STATUS}`;

    return this.client
      .put(url, requestData.body)
      .then()
      .catch((error) => Promise.reject(error));
  }

  downloadRequestDocument(data) {
    const url = `${DOWNLOAD_DOCUMENTS}${data.id}`;

    return this.client
      .get(url, { responseType: 'arraybuffer' })
      .then((response) => {
        const link = document.createElement('a');
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const documentUrl = URL.createObjectURL(blob);
        link.href = documentUrl;
        const newName = removeAccents(data.fileName);
        link.setAttribute('download', newName);

        link.click();
      });
  }
}

export default requestAPI;
