<template>
  <PatientForm
    id="create-patient"
    :title="$i18n.t('lb_new_patient')"
    @handleOk="create"
    @handleCancel="handleCancel"
    :isLoading="isLoading"
  />
</template>

<script>
import PatientForm from './PatientForm.vue';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.$bvModal.show('create-patient');
  },
  methods: {
    create(data) {
      const newData = {
        id: data.id,
        createdAt: data.createdAt,
        name: data.name,
        lastname: data.lastname,
        gender: data.gender,
        origin: data.origin?.id,
        dni: data.dni,
        dob: data.dob,
        tutor: data.tutor,
        hasInsurance: data.hasInsurance,
        insurance: data.insurance,
        hospital: data.hospital,
        email: data.email,
        phone: data.phone,
        requests: data.requests,
        contactWhatsApp: data.contactWhatsApp,
      };

      this.isLoading = true;

      this.$store.dispatch('patient/createPatient', newData)
        .then((res) => {
          const resData = data;
          resData.id = res.id;
          this.$emit('addPatient', resData);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    handleCancel() {
      this.$emit('handleCancel');
    },
  },
  components: {
    PatientForm,
  },
};
</script>
