import DoctorApi from '../../domain/doctor/DoctorApi';

export function getAllDoctor({ commit }, data) {
  const doctorApi = new DoctorApi();
  const getAllRequest = doctorApi.getAllDoctor(data);

  return getAllRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function filterDoctor({ commit }, data) {
  const doctorApi = new DoctorApi();
  const searchRequest = doctorApi.filterDoctor(data);

  return searchRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function searchDoctor({ commit }, data) {
  const doctorApi = new DoctorApi();
  const searchRequest = doctorApi.searchDoctor(data);

  return searchRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createDoctor({ commit }, data) {
  const doctorApi = new DoctorApi();
  const createRequest = doctorApi.createDoctor(data);

  return createRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updateDoctor({ commit }, data) {
  const doctorApi = new DoctorApi();
  const updateRequest = doctorApi.updateDoctor(data);

  return updateRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteDoctor({ commit }, uuid) {
  const doctorApi = new DoctorApi();
  const deleteRequest = doctorApi.deleteDoctor(uuid);

  return deleteRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
