import getAllInsurance from './insuranceActions';
import setInsurances from './insuranceMutations';
import { getInsurances, hasInsurances } from './insuranceGetters';

const insurance = {
  namespaced: true,
  state: () => ([]),
  actions: {
    getAllInsurance,
  },
  mutations: {
    setInsurances,
  },
  getters: {
    getInsurances,
    hasInsurances,
  },
};

export default insurance;
