<template>
  <div class="RequestDetailActions">
    <Spinner
      :isLoading="isLoading"
      :variant="variant"
    />
    <b-button
      v-if="!isLoading"
      @click.prevent="handleReject"
      variant="secondary"
    >
      {{ $i18n.t('btn_reject') }}
    </b-button>
    <div class="sp"/>
    <b-button
      v-if="!isLoading"
      @click.prevent="handleAccept"
      variant="primary"
    >
      {{ $i18n.t('btn_accept') }}
    </b-button>
    <span class="sp"/>
  </div>
</template>

<script>
import Spinner from '@/components/atoms/Spinner/Spinner.vue';

export default {
  props: {
    uuid: String,
    variant: {
      type: String,
      default: 'primary',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleAccept() {
      this.$emit('handleAccept', this.uuid);
    },

    handleReject() {
      this.$emit('handleReject', this.uuid);
    },
  },
  components: {
    Spinner,
  },
};
</script>

<style lang="sass" scoped>
@import './RequestDetailActions.sass'
</style>
