<template>
  <b-avatar
    variant="empty"
    class="Avatar"
    :src="getImage"
    :size="size"
  />
</template>

<script>
import { getImageFormatted } from '@/helpers/ComponentHelper';

export default {
  props: {
    image: {
      type: [Object, String, File],
      default: null,
    },
    size: {
      type: String,
      default: '2rem',
    },
  },
  computed: {
    getImage() {
      return getImageFormatted(this.image);
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './Avatar.sass'
</style>
