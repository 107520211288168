<template>
  <div class="Table Table__list">
    <b-table
      thead-class="d-none"
      :items="items"
      :fields="fields"
      fixed
    >
      <template #cell(info)="data">
        <p class="text__psbb text-dark ellipsis">{{ data.item.fileName }}</p>
        <div class="sp__v__1s5x"/>
        <p class="text__pr text-soft-dark">{{ data.item.createdAt | formatDate }}</p>
      </template>
      <template #cell(actions)="data">
        <div class="Table__list__actions">
          <b-button
            v-if="canDownload"
            class="btn btn-secondary"
            @click.prevent="handleDownload(data.item.fileName, data.item.id)"
          >
            <img src="@/assets/actions/preview.svg" />
          </b-button>
          <div class="sp__1s5x"/>
          <b-button
            v-if="canDelete"
            variant="outline-primary"
            @click.prevent="handleDelete(data.item.id)"
          >
            <inline-svg
              class="icon__primary"
              :src="require('@/assets/actions/delete.svg')"
            />
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleDelete(id) {
      this.$emit('handleDelete', id);
    },
    handleDownload(fileName, id) {
      this.isLoading = true;
      this.$store.dispatch('request/downloadRequestDocument', { fileName, id })
        .then(() => {
          this.$emit('handleDownload', this.id);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  data() {
    return {
      sortBy: 'case',
      sortDesc: false,
      fields: [
        { key: 'info', label: 'Name', sortable: true },
        { key: 'actions', label: '' },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
  @import '../Table.sass'
</style>
