import PatientApi from '../../domain/patient/PatientApi';

export function getAllPatient({ commit }, data) {
  const patientApi = new PatientApi();
  const getAllRequest = patientApi.getAllPatient(data);

  return getAllRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function filterPatient({ commit }, data) {
  const patientApi = new PatientApi();
  const searchRequest = patientApi.filterPatient(data);

  return searchRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function searchPatient({ commit }, filters) {
  const patientApi = new PatientApi();
  const searchRequest = patientApi.searchPatient(filters);

  return searchRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createPatient({ commit }, data) {
  const patientApi = new PatientApi();
  const createRequest = patientApi.createPatient(data);

  return createRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updatePatient({ commit }, data) {
  const patientApi = new PatientApi();
  const updateRequest = patientApi.updatePatient(data);

  return updateRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deletePatient({ commit }, uuid) {
  const patientApi = new PatientApi();
  const deleteRequest = patientApi.deletePatient(uuid);

  return deleteRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getPatientReport({ commit }, interval) {
  const patientApi = new PatientApi();
  const deleteRequest = patientApi.getPatientReport(interval);

  return deleteRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function importPatients({ commit }, data) {
  const patientApi = new PatientApi();
  const importPatientList = patientApi.importPatients(data);

  return importPatientList
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function exportPatients({ commit }, data) {
  const patientApi = new PatientApi();
  const exportPatientList = patientApi.exportPatients(data);

  return exportPatientList
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
