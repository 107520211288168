import KpiModel from './KpiModel';
import PaginationFactory from '../pagination/PaginationFactory';

class KpiFactory {
  constructor() {
    this.kpi = {};
  }

  getAllKpi(data) {
    const kpiResponse = {
      kpiList: this.getCollection(data?.collection),
      pagination: PaginationFactory.getPagination(data?.pagination),
    };

    return kpiResponse;
  }

  getKpi(kpi) {
    this.kpi = new KpiModel({
      amount: kpi.amount,
      name: kpi.name,
      percentage: kpi.percentage,
    });

    return this.kpi;
  }

  getCollection(kpiCollection) {
    const patientList = kpiCollection.map(
      (kpi) => this.getKpi(kpi),
    );

    return patientList;
  }
}

export default new KpiFactory();
