<template>
  <SelectGroup
    :id="`${id}-specialty`"
    :label="$i18n.t('lb_specialty')"
    :placeholder="placeholder"
    @handlerSelect="handleChangeSpecialty"
    :isValid="isValid"
    :error="error"
    :options="getSpecialties"
    :selectedOption="getValue"
    :isLoading="isLoading"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import { getSelectDefault } from '@/helpers/ComponentHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: 'No puede estar vacio',
    },
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    if (!this.hasSpecialties) {
      this.loadSpecialties();
    }
  },
  methods: {
    async loadSpecialties() {
      this.isLoading = true;
      await this.$store.dispatch('specialties/getAllSpecialties')
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    handleChangeSpecialty(value) {
      const currentSpecialty = this.specialties.find((specialty) => specialty.id === value);

      this.$emit('handleChangeSpecialty', currentSpecialty);
    },
  },
  computed: {
    ...mapGetters({
      specialties: 'specialties/getSpecialties',
      hasSpecialties: 'specialties/hasSpecialties',
    }),

    getSelectDefaultText() {
      return getSelectDefault('specialty');
    },

    getSpecialties() {
      const newSpecialties = this.specialties.map((specialty) => {
        const newSpecialty = {
          value: specialty.id,
          text: specialty.name,
        };
        return newSpecialty;
      });
      newSpecialties.unshift(this.getSelectDefaultText);
      return newSpecialties;
    },

    getValue() {
      return !(this.value && this.value.id) ? this.value : this.value.id;
    },
  },
  components: {
    SelectGroup,
  },
};
</script>
