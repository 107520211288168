<template>
  <div class="UserMiniCard">
    <img
      class="UserMiniCard__icon"
      :src="icon"
    />
    <span class="text__psbb">{{ label }}</span>
  </div>
</template>

<script>

export default {
  props: {
    icon: String,
    label: String,
  },
};
</script>

<style lang="sass" scoped>
  @import './UserMiniCard.sass'
</style>
