import getAxiosWithAuthHeader from '../../helpers/AuthHelper';

const PATIENTS = '/api/patients';
const DOCUMENTS = '/documents';
const REQUESTS = '/api/requests';

class documentAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  createDocument(requestData) {
    const url = `${PATIENTS}/${requestData.patientId}${DOCUMENTS}`;

    return this.client
      .post(url, requestData.document)
      .then()
      .catch((error) => Promise.reject(error));
  }

  updateDocument(requestData) {
    const url = `${PATIENTS}/${requestData.patientId}${DOCUMENTS}/${requestData.documentId}`;

    return this.client
      .put(url, { content: requestData.content })
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteDocument(requestData) {
    const url = `${PATIENTS}/${requestData.patientId}${DOCUMENTS}/${requestData.documentId}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteRequestDocument(requestData) {
    const url = `${REQUESTS}/${requestData.requestId}${DOCUMENTS}/${requestData.requestDocumentId}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default documentAPI;
