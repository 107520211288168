<template>
  <SelectGroup
    :id="id"
    :label="label"
    :placeholder="placeholder"
    @handlerSelect="handleChangeGender"
    :isValid="isValid"
    :error="error"
    :options="options"
    :selectedOption="value"
  />
</template>

<script>
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import { GENDER_OPTIONS } from '@/helpers/UserHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: String,
    error: {
      type: String,
      default: 'No puede estar vacio',
    },
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      options: GENDER_OPTIONS,
    };
  },
  methods: {
    handleChangeGender(value) {
      this.$emit('handleChangeGender', value);
    },

    getOptions() {
      return GENDER_OPTIONS;
    },
  },
  components: {
    SelectGroup,
  },
};
</script>
