import { ACCESS_TOKEN, ACCESS_REFRESH_TOKEN } from './AccessToken';

class StorageManager {
  constructor() {
    this.localStorage = localStorage;
  }

  saveToken(token) {
    this.localStorage.setItem(ACCESS_TOKEN, token);
  }

  saveRefreshToken(refreshToken) {
    this.localStorage.setItem(ACCESS_REFRESH_TOKEN, refreshToken);
  }

  getToken() {
    return this.localStorage.getItem(ACCESS_TOKEN);
  }

  removeToken() {
    this.localStorage.removeItem(ACCESS_TOKEN);
  }

  getRefreshToken() {
    return this.localStorage.getItem(ACCESS_REFRESH_TOKEN);
  }

  removeRefreshToken() {
    this.localStorage.removeItem(ACCESS_REFRESH_TOKEN);
  }

  removeLocalData() {
    this.removeToken();
    this.removeRefreshToken();
  }
}

export default new StorageManager();
