import axios from '../domain/services/index';
import StorageManager from './StorageManager';

export default function getAxiosWithAuthHeader() {
  const token = StorageManager.getToken();

  if (token !== null) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  return axios;
}
