import CityApi from '../../domain/city/CityApi';

export default function getAllCities({ commit }, data) {
  const citiesApi = new CityApi();
  const getAllCity = citiesApi.getAllCities(data);

  return getAllCity
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
