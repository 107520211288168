<template>
  <a
    class="WhatsappButton"
    target="_blank"
    :href="`https://api.whatsapp.com/send?phone=${phone}`"
  >
    <img src="@/assets/components/whatsapp.svg" />
    <span class="WhatsappButton__text">{{ $i18n.t('cp_whatsapp_contact') }}</span>
  </a>
</template>

<script>

export default {
  props: {
    phone: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './WhatsappButton.sass'
</style>
