import StorageManager from '../../helpers/StorageManager';

export default function logUser(state, auth) {
  const { token } = auth;
  const { refreshToken } = auth;

  state.token = token;
  state.refreshToken = refreshToken;

  StorageManager.saveToken(token);
  StorageManager.saveRefreshToken(refreshToken);
}
