import Vue from 'vue';

import {
  FormPlugin,
  FormGroupPlugin,
  ButtonPlugin,
  SidebarPlugin,
  PopoverPlugin,
  AvatarPlugin,
  BadgePlugin,
  DropdownPlugin,
  FormInputPlugin,
  TablePlugin,
  FormCheckboxPlugin,
  BreadcrumbPlugin,
  PaginationPlugin,
  TabsPlugin,
  FormSelectPlugin,
  FormDatepickerPlugin,
  FormTextareaPlugin,
  TooltipPlugin,
  FormFilePlugin,
  LayoutPlugin,
  ModalPlugin,
  CollapsePlugin,
  SpinnerPlugin,
  ListGroupPlugin,
  LinkPlugin,
} from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(ButtonPlugin);
Vue.use(SidebarPlugin);
Vue.use(PopoverPlugin);
Vue.use(AvatarPlugin);
Vue.use(BadgePlugin);
Vue.use(DropdownPlugin);
Vue.use(FormInputPlugin);
Vue.use(TablePlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(BreadcrumbPlugin);
Vue.use(PaginationPlugin);
Vue.use(TabsPlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(TooltipPlugin);
Vue.use(FormFilePlugin);
Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(CollapsePlugin);
Vue.use(SpinnerPlugin);
Vue.use(ListGroupPlugin);
Vue.use(LinkPlugin);
