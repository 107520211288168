import { uuid } from 'vue-uuid';

export const ITEMS_PER_PAGE = 10;

export function getDataWithUuid(data) {
  const newData = data;
  newData.id = uuid.v4();

  return newData;
}

export function calculateOffset(page, itemsPerPage) {
  const offset = (page - 1) * itemsPerPage;

  return offset;
}

export function getOffsetUrl(route, offset) {
  const urlWithParam = `${route}?offset=${offset}`;

  return urlWithParam;
}

export function getParameterUrl(route, page, filters = [], ordenation = []) {
  const filter = filters.length > 0 ? `&filter=[${filters}]` : '';
  const order = ordenation.length > 0 ? `&order=[${ordenation}]` : '';
  const parameterUrl = `${route}/?page=${page}&perPage=${ITEMS_PER_PAGE}${filter}${order}`;

  return parameterUrl;
}

export function getSearchUrl(route, filters = []) {
  const filter = filters.length > 0 ? `&filter=[${filters}]` : '';
  const searchUrl = `${route}/?${filter}`;

  return searchUrl;
}

export function getUrlWithParameters(url, params) {
  let urlWithParams = url;
  Object.entries(params).forEach(([key, value]) => {
    if (value !== null && typeof value !== 'undefined') {
      const param = `&${key}=${value}`;
      urlWithParams += param;
    }
  });

  return urlWithParams;
}

export function getCityUrl(route, param) {
  const urlWithParam = `${route}?name=${param}`;

  return urlWithParam;
}

export function getFiltersGroup(filtersGroup, filters) {
  Object.entries(filters).map((item) => {
    if (item[1] !== null) {
      filtersGroup.push(
        `${item[0]}:${item[1]}`,
      );
    }

    return null;
  });

  return filtersGroup;
}

export function getOrdenation(sort) {
  const ordenation = [];

  if (sort?.name !== null && sort?.type !== null) {
    ordenation.push(
      `${sort.name}:${sort.type}`,
    );
  }

  return ordenation;
}

function reverseTimestamp() {
  const timestamp = Math.round(new Date().getTime() / 1000).toString();
  return timestamp.split('').reverse().join('');
}

function random(min, max) {
  return Math.floor(Math.random() * max) + min;
}

export function getCaseNumber() {
  const firstRandom = random(0, 9);
  const secondRandom = 65 + random(0, 25);
  const extra = `${firstRandom}${String.fromCharCode(secondRandom)}`;

  let caseNumber = reverseTimestamp();
  const value = `${caseNumber}${extra}`;
  caseNumber = value.match(/.{1,3}/g);

  return `${caseNumber[0]}-${caseNumber[1]}-${caseNumber[2]}${caseNumber[3]}`;
}
