<template>
  <ModalAction
    :id="id"
    :title="$i18n.t('lb_add_document')"
    hideFooter
  >
    <FileUploader
      @handleUploadFile="handleUploadFile"
    />
  </ModalAction>
</template>

<script>
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';
import FileUploader from '@/components/atoms/User/FileUploader/FileUploader.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleUploadFile(document) {
      this.$emit('handleUploadFile', document);
    },
  },
  components: {
    ModalAction,
    FileUploader,
  },
};
</script>
