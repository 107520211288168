import getAllCountries from './countriesActions';
import setCountries from './countriesMutations';
import { getCountries, hasCountries } from './countriesGetters';

const countries = {
  namespaced: true,
  state: () => ([]),
  actions: {
    getAllCountries,
  },
  mutations: {
    setCountries,
  },
  getters: {
    getCountries,
    hasCountries,
  },
};

export default countries;
