import NotificationModel from './NotificationModel';

class NotificationFactory {
  constructor() {
    this.notification = {};
  }

  getNotificationList(notificationData) {
    const notificationList = notificationData?.data?.collection.map((notification) => {
      this.notification = {
        id: notification.id,
        createdAt: notification.createdAt,
        isDeleted: notification.isDeleted,
        message: notification.message,
        type: 'request', // This is hardcoded because theres only one type and back not send at the moment.
      };

      return new NotificationModel(this.notification);
    });

    return notificationList;
  }
}

export default new NotificationFactory();
