<template>
  <div class="SelectMiniCard w-card">
    <SelectGroup
      :id="id"
      :label="label"
      @handlerSelect="handleChangeSelect"
      :options="options"
      :selectedOption="value"
      :isValid="isValid"
      :isDisabled="isDisabled"
    />
  </div>
</template>

<script>
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Boolean],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    label: String,
    isValid: {
      type: Boolean,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChangeSelect(value) {
      this.$emit('handleChangeSelect', value);
    },
  },
  components: {
    SelectGroup,
  },
};
</script>

<style lang="sass" scoped>
@import './SelectMiniCard.sass'
</style>
