<template>
  <div class="filters">
    <p class="text__psb">Filtrar</p>
    <div class="sp__v__2x" />
    <MarketSelector
      id="marketHealthManagerFilter"
      :label="$i18n.t('lb_market')"
      :placeholder="$i18n.t('lb_type_something')"
      :value="getMarketSelected"
      @handlerSelect="handleChangeMarket"
    />
  </div>
</template>

<script>
import MarketSelector from '@/components/atoms/Select/MarketSelector.vue';

export default {
  props: {
    filters: {
      type: Object,
      default: () => ({
        filters: {
          market: null,
        },
      }),
    },
  },
  data() {
    return {
      marketSelected: this.filters.market,
    };
  },
  methods: {
    handleChangeMarket(value) {
      this.marketSelected = value;
      this.$emit('handlerSelectMarket', value);
    },
  },
  computed: {
    getMarketSelected() {
      return this.filters.market;
    },
  },
  components: {
    MarketSelector,
  },
};
</script>
