<template>
  <div class="filters">
    <p class="text__psb">Filtrar</p>
    <div class="sp__v__2x" />
    <DatepickerGropup
      id="dobPatientFilter"
      :label="$i18n.t('lb_dob')"
      :placeholder="$i18n.t('lb_select_date')"
      @handleChangeDate="handleChangeBirthdate"
      :value="getBirthdate"
    />

    <div class="sp__v__2x" />

    <CountrySelector
      id="originPatientFilter"
      :label="$i18n.t('lb_origin')"
      @handleChangeCountry="handleChangeOrigin"
      :value="getOrigin"
    />

  </div>
</template>

<script>
import DatepickerGropup from '@/components/atoms/Datepicker/DatepickerGropup.vue';
import CountrySelector from '@/components/atoms/Select/CountrySelector.vue';

export default {
  props: {
    filters: {
      type: Object,
      default: () => ({
        filters: {
          dob: null,
          origin: null,
        },
      }),
    },
  },
  data() {
    return {
      dob: this.filters.dob,
      origin: this.filters.origin,
    };
  },
  methods: {
    handleChangeBirthdate(value) {
      this.dob = value;
      this.$emit('handleChangeBirthdate', value);
    },

    handleChangeOrigin(value) {
      const originID = value.id;
      this.origin = originID;
      this.$emit('handleChangeOrigin', originID);
    },
  },
  computed: {
    getBirthdate() {
      return this.filters.dob;
    },

    getOrigin() {
      return this.filters.origin;
    },
  },
  components: {
    DatepickerGropup,
    CountrySelector,
  },
};
</script>
