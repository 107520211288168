export default class PatientModel {
  constructor(patientData) {
    this.id = patientData.id;
    this.createdAt = patientData.createdAt;
    this.name = patientData.name;
    this.lastname = patientData.lastname;
    this.gender = patientData.gender;
    this.origin = patientData.origin;
    this.dni = patientData.dni;
    this.dob = patientData.dob;
    this.tutor = patientData.tutor;
    this.hasInsurance = patientData.hasInsurance;
    this.insurance = patientData.insurance;
    this.hospital = patientData.hospital;
    this.email = patientData.email;
    this.phone = patientData.phone;
    this.requests = patientData.requests;
    this.contactWhatsApp = patientData.contactWhatsApp;
    this.documents = patientData.documents;
    this.totalRequests = patientData.totalRequests;
    this.requestList = patientData.requestList;
  }
}
