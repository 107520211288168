import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/plugins/axios';
import '@/plugins/bootstrap-vue';
import '@/plugins/vue-inline-svg';
import i18n from '@/plugins/vue-i18n';
import '@/style/custom.sass';
import '@/helpers/Filters';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
