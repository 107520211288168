<template>
  <div>
    <p v-if="!hasNotifications">{{ $i18n.t('lb_notifications_empty') }}</p>
    <template
      v-for="item in notifications"
    >
      <NotificationCard
        :key="`notification-${item.id}`"
        :notification="item"
        @handleActionNotification="handleActionNotification"
        @handleDelete="handleDeleteNotification"
      />
      <div class="sp__v" :key="`notificationSeparator-${item.id}`"/>
    </template>
  </div>
</template>

<script>
import NotificationCard from '@/components/atoms/Cards/NotificationCard/NotificationCard.vue';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    deleteNotification(id) {
      for (let i = 0; this.notifications.length; i += 1) {
        if (this.notifications[i].id === id) {
          this.notifications.splice(i, 1);
          break;
        }
      }
    },

    handleDeleteNotification(id) {
      this.$store.dispatch('notification/deleteNotification', id)
        .then(() => {
          this.deleteNotification(id);
        })
        .catch(() => {
        });
    },

    handleActionNotification(id) {
      this.$emit('handleShowNotification', id);
    },

    handleCancel() {
      this.$emit('handleCancel');
    },
  },
  computed: {
    hasNotifications() {
      return this.notifications.length > 0;
    },
  },
  components: {
    NotificationCard,
  },
};
</script>
