import i18n from '@/plugins/vue-i18n';

export default [
  {
    key: 'name',
    label: i18n.t('lb_name'),
    sortable: true,
  },
  {
    key: 'market',
    label: i18n.t('lb_market'),
    sortable: true,
  },
  {
    key: 'requests',
    label: i18n.t('lb_request'),
    sortable: true,
  },
  {
    key: 'patients',
    label: i18n.t('lb_patients'),
    sortable: true,
  },
  {
    key: 'actions',
    label: '',
  },
];
