import RequestApi from '../../domain/request/RequestApi';

export function getRequest({ commit }, data) {
  const requestApi = new RequestApi();
  const getRequestRequest = requestApi.getRequest(data);

  return getRequestRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getRequestDocuments({ commit }, requestId) {
  const requestApi = new RequestApi();
  const getRequestDocumentsRequest = requestApi.getRequestDocuments(requestId);

  return getRequestDocumentsRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function getAllRequest({ commit }, data) {
  const requestApi = new RequestApi();
  const getAllRequestRequest = requestApi.getAllRequest(data);

  return getAllRequestRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function filterRequest({ commit }, data) {
  const requestApi = new RequestApi();
  const filterRequestRequest = requestApi.filterRequest(data);

  return filterRequestRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function searchRequest({ commit }, data) {
  const requestApi = new RequestApi();
  const searchRequestRequest = requestApi.searchRequest(data);

  return searchRequestRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createRequest({ commit }, data) {
  const requestApi = new RequestApi();
  const createRequestRequest = requestApi.createRequest(data);

  return createRequestRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updateRequest({ commit }, data) {
  const requestApi = new RequestApi();
  const updateRequestRequest = requestApi.updateRequest(data);

  return updateRequestRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteRequest({ commit }, id) {
  const requestApi = new RequestApi();
  const deleteRequestRequest = requestApi.deleteRequest(id);

  return deleteRequestRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function changeRequestStatus({ commit }, data) {
  const requestApi = new RequestApi();
  const changeRequestStatusRequest = requestApi.changeRequestStatus(data);

  return changeRequestStatusRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function downloadRequestDocument({ commit }, data) {
  const requestApi = new RequestApi();
  const downloadDocument = requestApi.downloadRequestDocument(data);

  return downloadDocument
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
