export default class DoctorModel {
  constructor(data) {
    this.id = data.id;
    this.avatar = data.avatar;
    this.name = data.name;
    this.lastname = data.lastname;
    this.specialty = data.specialty;
    this.bio = data.bio;
    this.experience = data.experience;
    this.details = data.details;
    this.email = data.email;
    this.phone = data.phone;
  }
}
