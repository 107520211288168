<template>
  <SelectGroup
    :id="id"
    :label="label"
    :placeholder="placeholder"
    @handlerSelect="handleChangeClinic"
    :isValid="isValid"
    :error="error"
    :options="getClinics"
    :selectedOption="value"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import { getSelectDefault } from '@/helpers/ComponentHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: 'No puede estar vacio',
    },
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    if (!this.hasClinics) {
      this.loadClinicList();
    }
  },
  methods: {
    async loadClinicList(page = 1) {
      this.isLoading = true;
      await this.$store.dispatch('clinic/getAllClinic', page)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    handleChangeClinic(value) {
      this.$emit('handleChangeClinic', value);
    },
  },
  computed: {
    ...mapGetters('clinic', {
      clinic: 'getClinics',
      hasClinics: 'hasClinics',
    }),

    getSelectDefaultText() {
      return getSelectDefault('clinic');
    },

    getClinics() {
      const newClinics = this.clinic.map((clinic) => {
        const newClinic = {
          value: clinic.id,
          text: clinic.name,
        };

        return newClinic;
      });

      newClinics.unshift(this.getSelectDefaultText);
      return newClinics;
    },
  },
  components: {
    SelectGroup,
  },
};
</script>
