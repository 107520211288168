<template>
  <SelectGroup
    :id="id"
    :label="label"
    :placeholder="placeholder"
    @handlerSelect="handleChangeStatus"
    :isValid="isValid"
    :error="error"
    :options="getStatus"
    :selectedOption="value"
  />
</template>

<script>
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import { STATUS_OPTIONS, getSelectDefault } from '@/helpers/ComponentHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: String,
    error: {
      type: String,
      default: 'No puede estar vacio',
    },
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    handleChangeStatus(value) {
      this.$emit('handleChangeStatus', value);
    },
  },
  computed: {
    getStatus() {
      const statusOptions = STATUS_OPTIONS;
      statusOptions.unshift(this.getSelectDefaultText);

      return statusOptions;
    },
    getSelectDefaultText() {
      return getSelectDefault('status');
    },
  },
  components: {
    SelectGroup,
  },
};
</script>
