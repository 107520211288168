<template>
  <div class="Badge">
    <b-badge
      pill
      :variant="variant"
    >
      <slot></slot>
    </b-badge>
  </div>
</template>

<script>

export default {
  props: {
    variant: {
      type: String,
      required: true,
    },
  },
};
</script>
