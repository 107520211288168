<template>
  <div>
    <b-form>
      <b-form-group
        class="text__psb"
        :id="`${id}-group`"
        :label="label"
        :label-for="`${id}-input`"
      >
        <Input
          :id="`${id}-input`"
          :value="value"
          :type="type"
          :placeholder="placeholder"
          @handlerInputChange="handleInputGroupChange"
          @handleLogin="handleLogin"
          :isValid="isValid"
          :autocomplete="autocomplete"
        />
        <b-form-invalid-feedback
          v-if="isValid === false"
          class="text__sr"
        >
          {{ error }}
        </b-form-invalid-feedback>
        <b-form-text
          v-if="showHelp"
          class="text__sr"
        >
          {{ help }}
        </b-form-text>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import Input from '@/components/atoms/Input/Input.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: [String, Number],
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: null,
    },
    help: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'autocomplete',
    },
  },
  methods: {
    handleInputGroupChange(text) {
      this.$emit('handlerInputChange', text);
    },
    handleLogin() {
      this.$emit('handleLogin');
    },
  },
  computed: {
    showHelp() {
      return this.help.length > 0;
    },
  },
  components: {
    Input,
  },
};
</script>
