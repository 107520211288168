var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:this.isImage ?
    'FileUploader FileUploader--isImage' :
    'FileUploader'},[_c('div',{class:this.isImage ?
      'FileUploader__zone FileUploader__zone--isImage' :
      'FileUploader__zone'},[_c('b-form-file',{attrs:{"state":Boolean(_vm.file),"placeholder":"","drop-placeholder":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),(!this.isFileLoaded)?_c('div',{staticClass:"FileUploader__info"},[_c('img',{attrs:{"src":require("@/assets/components/upload.svg")}}),(!this.isImage)?_c('div',{staticClass:"sp__v__1s5x"}):_vm._e(),_c('p',{class:this.isImage ?
          'FileUploader__text--isImage text__psb' :
          'FileUploader__text text__psb'},[_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$i18n.t('cp_upload_a_file'))+" ")]),_vm._v(" "+_vm._s(_vm.$i18n.t('cp_or_drag'))+" "+_vm._s(this.file)+" ")])]):_vm._e(),(true)?_c('div',{staticClass:"FileUploader__preview",style:(("background-image: url(" + _vm.getImage + ");"))}):_vm._e()],1),_c('div',{staticClass:"sp__v"}),_c('b-button',{class:this.isImage ?
      'FileUploader__button--isImage' :
      'FileUploader__button',attrs:{"disabled":this.file === null,"variant":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.uploadFile.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$i18n.t('btn_save'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }