<template>
  <DoctorForm
    id="create-doctor"
    :title="$i18n.t('lb_new_doctor')"
    @handleOk="create"
    @handleCancel="handleCancel"
    :isLoading="isLoading"
  />
</template>

<script>
import DoctorForm from '@/components/organisms/Doctor/DoctorForm.vue';
import { getAvatarBase64 } from '@/helpers/UserHelper';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.$bvModal.show('create-doctor');
  },
  methods: {
    create(data) {
      const newData = {
        ...(this.getAvatar(data.avatar)),
        name: data.name,
        lastname: data.lastname,
        specialty: data.specialty?.id,
        bio: data.bio,
        experience: data.experience,
        details: data.details,
        email: data.email,
        phone: data.phone,
      };

      this.isLoading = true;

      this.$store.dispatch('doctor/createDoctor', newData)
        .then((res) => {
          const resData = data;
          resData.id = res.id;
          this.$emit('addDoctor', resData);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getAvatar(avatar) {
      return getAvatarBase64(avatar);
    },

    handleCancel() {
      this.$emit('handleCancel');
    },
  },
  components: {
    DoctorForm,
  },
};
</script>
