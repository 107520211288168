export default {
  btn_new: 'Nuevo',
  btn_new_f: 'Nueva',
  btn_save: 'Guardar',
  btn_save_request: 'Guardar solicitud',
  btn_filters: 'Filtros',
  btn_export: 'Exportar',
  btn_edit: 'Editar',
  btn_delete: 'Borrar',
  btn_cancel: 'Cancelar',
  btn_confirm: 'Confirmar',
  btn_close: 'Cerrar',
  btn_close_session: 'Cerrar sesión',
  btn_update: 'Actualizar',
  btn_watch: 'Ver',
  btn_accept: 'Aceptar',
  btn_reject: 'Rechazar',
  btn_change: 'Cambiar',
  btn_login: 'Iniciar sesión',
  btn_apply: 'Aplicar',
  btn_add: 'Añadir',
  btn_verify: 'Validar',
  btn_import: 'Importar',
  btn_forgot_password: 'Recuperar contraseña',
  btn_reset_password: 'Iniciar sesión',
};
