import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import MarketFactory from './MarketFactory';

const MARKETS = '/api/markets';

class marketAPI {
  constructor() {
    this.market = getAxiosWithAuthHeader();
  }

  getAllMarkets() {
    return this.market
      .get(MARKETS)
      .then((res) => MarketFactory.getMarket(res.data.collection))
      .catch((error) => Promise.reject(error));
  }
}

export default marketAPI;
