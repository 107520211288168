<template>
  <div>
    <p class="text__psbb">{{ $i18n.t('lb_contact_info') }}</p>
    <div class="sp__v" />
    <div v-if="!isAccepted">
      <p class="text__pr">
        {{ $i18n.t('lb_contact_not_accepted') }}
      </p>
      <div class="sp__v" />
    </div>
    <div
      v-if="isAccepted"
      class="ContactBlock"
    >
      <Contact
        :image="require('@/assets/contact/phone.svg')"
        :label= this.getUser.phone
        :action="getPhoneAction"
      />
      <div class="sp__3x" />
      <Contact
        :image="require('@/assets/contact/email.svg')"
        :label= this.getUser.email
        :action="getEmailAction"
      />
      <div class="sp__3x" />
      <Contact
        v-if="getUser.contactWhatsApp"
        :image="require('@/assets/contact/whatsapp.svg')"
        :label= this.getUser.phone
        :action="getWhatsappAction"
      />
    </div>
  </div>
</template>

<script>
import Contact from '@/components/atoms/Contact/Contact.vue';

export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    isAccepted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getPhoneAction() {
      return `tel:${this.getUser.phone}`;
    },

    getEmailAction() {
      return `mailto:${this.getUser.email}`;
    },

    getWhatsappAction() {
      return `https://api.whatsapp.com/send?phone=${this.getUser.phone}`;
    },

    getUser() {
      return this.user;
    },
  },
  components: {
    Contact,
  },
};
</script>

<style lang="sass">
@import './ContactBlock.sass'
</style>
