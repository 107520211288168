import Vue from 'vue';
import Vuex from 'vuex';
import { ROLE } from '@/helpers/UserHelper';

Vue.use(Vuex);

export function getCurrentUser(state) {
  return state;
}

export function isVidasprime(state) {
  return state.role === ROLE.VIDASPRIME;
}

export function isHealthManager(state) {
  return state.role === ROLE.HEALTH_MANAGER;
}

export function isClinic(state) {
  return state.role === ROLE.CLINIC;
}

export function isDoctor(state) {
  return state.role === ROLE.DOCTOR;
}
