import CountryModel from './CountryModel';

class CountryFactory {
  constructor() {
    this.country = {};
  }

  getCountry(country) {
    this.country = {
      id: country.id,
      name: country.name,
    };
    return new CountryModel(this.country);
  }
}

export default new CountryFactory();
