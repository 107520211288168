import getAxiosWithAuthHeader from '../../helpers/AuthHelper';

const REQUESTS = '/api/requests';
const NOTES = '/notes';

class noteAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  createNote(requestData) {
    const url = `${REQUESTS}/${requestData.requestId}${NOTES}`;

    return this.client
      .post(url, requestData.note)
      .then()
      .catch((error) => Promise.reject(error));
  }

  updateNote(requestData) {
    const url = `${REQUESTS}/${requestData.requestId}${NOTES}/${requestData.noteId}`;

    return this.client
      .put(url, { content: requestData.content })
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteNote(requestData) {
    const url = `${REQUESTS}/${requestData.requestId}${NOTES}/${requestData.noteId}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default noteAPI;
