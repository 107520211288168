<template>
  <div class="HeadingBar">
    <Heading
      :title="title"
      :text="text"
      :isData="isData"
      :isSmall="isSmall"
    />
    <slot></slot>
  </div>
</template>

<script>
import Heading from '@/components/atoms/Headings/Heading.vue';

export default {
  props: {
    title: String,
    text: String,
    isData: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Heading,
  },
};
</script>

<style lang="sass" scoped>
  @import './HeadingBar.sass'
</style>
