<template>
  <SelectGroup
    :id="id"
    :label="label"
    :placeholder="placeholder"
    @handlerSelect="handleChangeCountry"
    :isValid="isValid"
    :error="error"
    :options="getCountries"
    :selectedOption="getValue"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import { getSelectDefault } from '@/helpers/ComponentHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: 'No puede estar vacio',
    },
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  created() {
    if (!this.hasCountries) {
      this.getCountryList();
    }
  },
  methods: {
    async getCountryList() {
      await this.$store.dispatch('countries/getAllCountries')
        .then(() => {
        })
        .catch();
    },

    handleChangeCountry(value) {
      const selectedCountry = this.countries.find((el) => el.id === value);

      this.$emit('handleChangeCountry', selectedCountry);
    },
  },
  computed: {
    ...mapGetters('countries', {
      countries: 'getCountries',
      hasCountries: 'hasCountries',
    }),

    getSelectDefaultText() {
      return getSelectDefault('origin');
    },

    getCountries() {
      const newCountries = this.countries.map((country) => {
        const newCountry = {
          value: country.id,
          text: country.name,
        };

        return newCountry;
      });

      newCountries.unshift(this.getSelectDefaultText);
      return newCountries;
    },

    getValue() {
      return !(this.value && this.value.id) ? this.value : this.value.id;
    },
  },
  components: {
    SelectGroup,
  },
};
</script>
