import KpiAPI from '../../domain/kpi/KpiApi';

export default function getAllKpi({ commit }, data) {
  const kpiApi = new KpiAPI();
  const getAllKpis = kpiApi.getAllKpi(data);

  return getAllKpis
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
