<template>
  <div>
    <Breadcrumb
      @handleClickAction="hideDetail"
      :items="[
        {
          name: 'Health Managers',
          hasAction: true,
        },
        {
          name: `${healthManager.name} ${healthManager.lastname}`,
        },
      ]"
    />
    <div class="detailCard">
      <Avatar
        :image="getAvatar"
        size="80px"
      />
      <div class="detailCard__actionBlock">
        <b-button
          @click.prevent="handleDelete"
          variant="secondary" size="sm"
        >
          {{ $i18n.t('btn_delete') }}
        </b-button>
        <span class="sp__1s5x"/>
        <b-button
          @click.prevent="handleEdit"
          variant="secondary"
          size="sm"
        >
          {{ $i18n.t('btn_edit') }}
        </b-button>
      </div>
    </div>
    <div class="sp__v__1s5x"/>
    <h2>{{ `${healthManager.name} ${healthManager.lastname}` }}</h2>
    <LabelGroup
      :label="$i18n.t('lb_market')"
      :text="healthManager.market.name"
      isClear
    />
    <div class="sp__v__2x"/>
    <LabelGroup
      :label="`${$i18n.t('lb_email')}:`"
      :text="healthManager.email"
    />
    <div class="sp__v"/>
    <LabelGroup
      :label="`${$i18n.t('lb_phone')}:`"
      :text="healthManager.phone"
    />
  </div>
</template>

<script>
import LabelGroup from '@/components/atoms/Label/LabelGroup.vue';
import Breadcrumb from '@/components/atoms/Breadcrumb/Breadcrumb.vue';
import Avatar from '@/components/atoms/Avatar/Avatar.vue';
import { getAvatarFile } from '@/helpers/UserHelper';

export default {
  props: {
    healthManager: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleEdit() {
      this.$emit('showUpdateForm', this.healthManager);
    },

    handleDelete() {
      this.$emit('showDeleteConfirm', this.healthManager);
    },

    hideDetail() {
      this.$emit('hideDetail');
    },
  },
  computed: {
    getAvatar() {
      return getAvatarFile(this.healthManager?.avatar);
    },
  },
  components: {
    LabelGroup,
    Breadcrumb,
    Avatar,
  },
};
</script>
