import { setUser, setUserAuth } from './userMutations';
import {
  getCurrentUser,
  isVidasprime,
  isHealthManager,
  isClinic,
  isDoctor,
} from './userGetters';
import { resetPassword, getUser, updateUser } from './userActions';

const user = {
  namespaced: true,
  state: () => ({
    name: null,
    avatar: null,
    city: null,
    market: null,
    specialty: null,
    email: null,
    phone: null,
  }),
  mutations: {
    setUser,
    setUserAuth,
  },
  actions: {
    resetPassword,
    getUser,
    updateUser,
  },
  getters: {
    getCurrentUser,
    isVidasprime,
    isHealthManager,
    isClinic,
    isDoctor,
  },
};

export default user;
