<template>
  <div class="LoginView">
    <div class="LoginView__loginFormContainer">
      <LoginForm />
    </div>
    <div class="LoginView__imageContainer" />
    <!-- <img src="@/assets/images/doctors.png" /> -->
  </div>
</template>

<script>
import LoginForm from '@/components/organisms/Login/LoginForm.vue';
import StorageManager from '@/helpers/StorageManager';

export default {
  name: 'LoginView',
  components: {
    LoginForm,
  },
  created() {
    StorageManager.removeLocalData();
  },
};
</script>

<style lang="sass" scoped>
@import './LoginView.sass'
</style>
