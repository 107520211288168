import {
  getAllPatient,
  filterPatient,
  searchPatient,
  createPatient,
  updatePatient,
  deletePatient,
  getPatientReport,
  importPatients,
  exportPatients,
} from './patientActions';

const patient = {
  namespaced: true,
  actions: {
    getAllPatient,
    filterPatient,
    searchPatient,
    createPatient,
    updatePatient,
    deletePatient,
    getPatientReport,
    importPatients,
    exportPatients,
  },
};

export default patient;
