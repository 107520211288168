<template>
  <inline-svg
    :src="require('@/assets/menu/' + icon)"
    :class="
      isSelected ?
      'MenuRowIcon MenuRowIcon--selected' :
      'MenuRowIcon MenuRowIcon--deselected'"
  />
</template>

<script>
export default {
  props: {
    icon: String,
    isSelected: Boolean,
  },
};
</script>

<style lang="sass">
  @import './MenuRowIcon.sass'
</style>
