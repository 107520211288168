import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export function getErrors(state) {
  return state;
}

export function hasErrors(state) {
  return state.showError;
}
