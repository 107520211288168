<template>
  <div class="TextGroup w-card">
    <p class="text__psb">
      {{ label }}
    </p>
    <div class="sp__v__1s5x"></div>
    <p class="text__pr">
      {{ text }}
    </p>
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './TextGroup.sass'
</style>
