import {
  getAllClinic,
  filterClinic,
  searchClinic,
  createClinic,
  updateClinic,
  deleteClinic,
} from './clinicActions';
import setClinics from './clinicMutations';
import { getClinics, hasClinics } from './clinicGetters';

const clinic = {
  namespaced: true,
  state: () => ([]),
  actions: {
    getAllClinic,
    filterClinic,
    searchClinic,
    createClinic,
    updateClinic,
    deleteClinic,
  },
  mutations: {
    setClinics,
  },
  getters: {
    getClinics,
    hasClinics,
  },
};

export default clinic;
