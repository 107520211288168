import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import NotificationFactory from './NotificationFactory';

const NOTIFICATIONS = '/api/notifications';

class notificationAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllNotification() {
    return this.client
      .get(NOTIFICATIONS)
      .then((res) => NotificationFactory.getNotificationList(res))
      .catch((error) => Promise.reject(error));
  }

  deleteNotification(id) {
    const url = `${NOTIFICATIONS}/${id}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default notificationAPI;
