<template>
  <ModalAction
    :id="id"
    :okLabel="`${$i18n.t('btn_save')} Health Manager`"
    :cancelLabel="$i18n.t('btn_cancel')"
    :isLoading="isLoading"
    @handleOk="handleOk"
    @handleCancel="handleCancel"
    isForm
  >
  <b-form>
    <b-row>
      <b-col>
        <p class="text__formTitle">{{ title }}</p>
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col>
        <FileUploader
          @handleSetFile="handleChangeImage"
          isImage
          :image="this.getAvatar"
        />
        <div class="sp__v__1s5x" />
        <p class="text__sr text-secondary">{{ $i18n.t('cp_file_uploader_help') }}</p>
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <InputGroup
          id="hmFormName"
          :label="$i18n.t('lb_name')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeName"
          :isValid="this.validationState.name"
          error="No puede estar vacio"
          :value="this.healthManagerData.name"
        />
      </b-col>
      <b-col class="col-6">
        <InputGroup
          id="hmFormLastname"
          :label="$i18n.t('lb_lastname')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeLastname"
          :isValid="this.validationState.lastname"
          error="No puede estar vacio"
          :value="this.healthManagerData.lastname"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col class="col-6">
        <MarketSelector
          id="hmFormMarket"
          :label="$i18n.t('lb_market')"
          :placeholder="$i18n.t('lb_type_something')"
          :value="getMarket"
          @handlerSelect="handleChangeMarket"
          :isValid="this.validationState.market"
          error="No puede estar vacio"
        />
      </b-col>
    </b-row>

    <div class="sp__v__1d5x" />

    <b-row>
      <b-col>
        <p class="text__formTitle">{{ $i18n.t('lb_contact_data')}}</p>
      </b-col>
    </b-row>
    <div class="sp__v__1d5x" />
    <b-row>
      <b-col class="col-6">
        <InputGroup
          id="hmFormEmail"
          :label="$i18n.t('lb_email')"
          :placeholder="$i18n.t('lb_type_email')"
          @handlerInputChange="handleChangeEmail"
          :isValid="this.validationState.email"
          :error="$i18n.t('val_email')"
          :value="this.healthManagerData.email"
        />
      </b-col>
      <b-col class="col-6">
        <InputGroup
          id="hmFormPhone"
          :label="$i18n.t('lb_phone')"
          :placeholder="$i18n.t('lb_type_phone')"
          @handlerInputChange="handleChangePhone"
          :isValid="this.validationState.phone"
          :error="$i18n.t('val_phone')"
          :value="this.healthManagerData.phone"
          type="tel"
        />
      </b-col>
    </b-row>
    <div class="sp__v" />
  </b-form>
  </ModalAction>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import MarketSelector from '@/components/atoms/Select/MarketSelector.vue';
import {
  getValidationState,
  getEmailValidationState,
  getPhoneValidationState,
  isValidForm,
  sanitizeEmail,
} from '@/helpers/ValidationHelper';
import FileUploader from '@/components/atoms/User/FileUploader/FileUploader.vue';
import { getAvatarFile } from '@/helpers/UserHelper';
import MarketModel from '@/domain/market/MarketModel';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    healthManager: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      healthManagerData: {
        id: this.healthManager?.id,
        name: this.healthManager?.name,
        lastname: this.healthManager?.lastname,
        market: this.healthManager?.market,
        email: this.healthManager?.email,
        phone: this.healthManager?.phone,
        avatar: this.healthManager?.avatar,
      },
      validationState: {
        name: null,
        lastname: null,
        market: null,
        email: null,
        phone: null,
      },
    };
  },
  methods: {
    sendOk() {
      this.$emit('handleOk', this.healthManagerData);
    },

    handleOk() {
      if (this.healthManagerData.email) {
        this.setSanitizedEmail();
      }

      const nameValidationState = getValidationState(
        this.healthManagerData.name,
      );

      const lastnameValidationState = getValidationState(
        this.healthManagerData.lastname,
      );

      const marketValidationState = getValidationState(
        this.healthManagerData.market?.id,
      );

      const emailValidationState = getEmailValidationState(
        this.healthManagerData.email,
      );

      const phoneValidationState = getPhoneValidationState(
        this.healthManagerData.phone,
      );

      if (isValidForm([
        nameValidationState,
        lastnameValidationState,
        marketValidationState,
        emailValidationState,
        phoneValidationState,
      ])) {
        this.sendOk();
      } else {
        this.validationState.name = nameValidationState;
        this.validationState.lastname = lastnameValidationState;
        this.validationState.market = marketValidationState;
        this.validationState.email = emailValidationState;
        this.validationState.phone = phoneValidationState;
      }
    },

    handleCancel() {
      this.resetValidations();

      this.$emit('handleCancel');
    },

    handleChangeImage(file) {
      this.healthManagerData.avatar = file;
    },

    handleChangeName(text) {
      this.healthManagerData.name = text;
    },

    handleChangeLastname(text) {
      this.healthManagerData.lastname = text;
    },

    handleChangeMarket(value) {
      const market = this.markets.find((element) => element.value === value);

      const newMarket = new MarketModel({
        id: value,
        name: market.text,
      });

      this.healthManagerData.market = newMarket;
    },

    handleChangeEmail(text) {
      this.healthManagerData.email = text;
    },

    handleChangePhone(text) {
      this.healthManagerData.phone = text;
    },

    resetValidations() {
      this.validationState.name = null;
      this.validationState.lastname = null;
      this.validationState.market = null;
      this.validationState.email = null;
      this.validationState.phone = null;
    },

    setSanitizedEmail() {
      const sanitizedEmail = sanitizeEmail(this.healthManagerData.email);
      this.healthManagerData.email = sanitizedEmail;
    },
  },
  computed: {
    ...mapGetters('markets', {
      markets: 'getMarkets',
    }),

    getAvatar() {
      return getAvatarFile(this.healthManager?.avatar);
    },

    getMarket() {
      return this.healthManagerData?.market?.id;
    },
  },
  watch: {
    healthManager(newValue) {
      this.healthManagerData.id = newValue.id;
      this.healthManagerData.name = newValue.name;
      this.healthManagerData.lastname = newValue.lastname;
      this.healthManagerData.market = newValue.market;
      this.healthManagerData.email = newValue.email;
      this.healthManagerData.phone = newValue.phone;
      this.healthManagerData.avatar = newValue.avatar;
    },
  },
  components: {
    ModalAction,
    InputGroup,
    FileUploader,
    MarketSelector,
  },
};
</script>
