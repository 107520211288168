<template>
  <b-dropdown
    class="Dropdown"
    id="dropdown"
    :variant="getIsValid ? '' : 'outline-error'"
  >
    <template #button-content class="test">
      <img
        v-if="icon"
        class="Dropdown__image"
        :src="icon"
      />
      <div class="sp__1s5x"></div>
      <p>
        {{ text }}
        </p>
    </template>
    <slot></slot>
  </b-dropdown>
</template>

<script>

export default {
  props: {
    text: String,
    icon: String,
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    getIsValid() {
      return this.isValid !== false;
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './Dropdown.sass'
</style>
