import i18n from '@/plugins/vue-i18n';

export default [
  {
    key: 'createdAt',
    label: i18n.t('lb_created'),
    sortable: true,
  },
  {
    key: 'name',
    label: i18n.t('lb_name'),
    sortable: true,
  },
  {
    key: 'origin',
    label: i18n.t('lb_origin'),
    sortable: true,
  },
  {
    key: 'dob',
    label: i18n.t('lb_dob'),
    sortable: true,
  },
  {
    key: 'email',
    label: i18n.t('lb_email'),
    sortable: true,
  },
  {
    key: 'phone',
    label: i18n.t('lb_phone'),
    sortable: true,
  },
  {
    key: 'totalRequests',
    label: i18n.t('lb_request'),
    sortable: true,
  },
  {
    key: 'actions',
    label: '',
  },
];
