<template>
  <private-template>
    <template v-slot:private-template>
      <div class="sp__v__2x"/>
      <HealthManagerDetail
        v-if="showDetail"
        :healthManager="healthManager"
        @showUpdateForm="showDetailUpdateForm"
        @showDeleteConfirm="showDetailDeleteModal"
        @hideDetail="hideDetailView"
      />
      <div v-if="!showDetail">
        <HeadingBar
          title="Health Managers"
          :text="$i18n.t('txt_healthmanagers')"
        />
        <div class="buttonContainer">
          <b-button
            @click.prevent="showCreateModal"
            variant="primary"
            size="sm"
          >
             {{ `+ ${$i18n.t('btn_new')}` }}
          </b-button>
        </div>
        <div class="sp__v__2x"/>
        <TableGroup
          id="healthManager"
          :items="healthManagerList"
          :isLoading="isTableLoading"
          :pagination="pagination"
          :searchText="searchText"
          :filterCount="getFilterCount"
          @handleSearch="handleSearchByInput"
          @handleSearchByFilter="handleSearchByFilter"
          @handlePaginate="handleFilterSearch"
          @handleCleanSearch="handleCleanSearch"
        >
          <template v-slot:content>
            <HealthManagerFilters
              @handlerSelectMarket="handlerSelectMarket"
              :filters="filters"
            />
          </template>

          <HealthManagerTable
            :healthManagerItems="healthManagerList"
            @showUpdateForm="showUpdateForm"
            @showDeleteModal="showDeleteModal"
            @handleSelectRow="handleSelectRow"
            @handleSortAsc="handleSortAsc"
          />
        </TableGroup>
      </div>
        <CreateHealthManager
          v-if="showCreateHealthManager"
          @addHealthManager="addHealthManager"
          @handleCancel="closeCreateModal"
        />
        <UpdateHealthManager
          @updateHealthManager="updateHealthManager"
          :healthManager="healthManager"
        />
        <DeleteHealthManager
          @deleteHealthManager="deleteHealthManager"
          ref="deleteHealthManager"
        />
    </template>
  </private-template>
</template>

<script>
import PrivateTemplate from '@/components/organisms/PrivateTemplate.vue';
import HeadingBar from '@/components/molecules/HeadingBar/HeadingBar.vue';
import CreateHealthManager from '@/components/organisms/HealthManager/CreateHealthManager.vue';
import UpdateHealthManager from '@/components/organisms/HealthManager/UpdateHealthManager.vue';
import TableGroup from '@/components/molecules/Tables/TableGroup/TableGroup.vue';
import HealthManagerTable from '@/components/molecules/Tables/HealthManagerTable/HealthManagerTable.vue';
import DeleteHealthManager from '@/components/organisms/HealthManager/DeleteHealthManager.vue';
import HealthManagerDetail from '@/components/organisms/HealthManager/HealthManagerDetail.vue';
import HealthManagerFilters from '@/components/organisms/HealthManager/HealthManagerFilters.vue';
import { getSortType, SORT_TYPE, getFiltersCount } from '@/helpers/ComponentHelper';
import { getOrdenation, getFiltersGroup } from '@/helpers/ApiHelper';
import { getAvatarByProps } from '@/helpers/UserHelper';

export default {
  data() {
    return {
      healthManagerList: [],
      healthManager: {
        id: null,
        name: null,
        lastname: null,
        market: null,
        email: null,
        phone: null,
        requests: null,
        patients: null,
        avatar: null,
      },
      showCreateHealthManager: false,
      showDetail: false,
      filters: {
        market: null,
      },
      isTableLoading: false,
      searchText: null,
      sort: {
        name: null,
        type: null,
      },
      pagination: {},
    };
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleSelectRow(item) {
      this.setHealthManager(item);

      this.showDetailView();
    },

    showUpdateForm(data) {
      this.setHealthManager(data);

      this.$bvModal.show('update-health-manager');
    },

    showDetailUpdateForm(data) {
      this.setHealthManager(data);

      this.$bvModal.show('update-health-manager');
    },

    showDeleteModal(data) {
      this.$refs.deleteHealthManager.setId(data.id);
      this.$bvModal.show('delete-health-manager');
    },

    showDetailDeleteModal() {
      this.$refs.deleteHealthManager.setId(this.healthManager.id);
      this.$bvModal.show('delete-health-manager');
    },

    showCreateModal() {
      this.showCreateHealthManager = true;
    },

    closeCreateModal() {
      this.showCreateHealthManager = false;
    },

    showDetailView() {
      this.showDetail = true;
    },

    hideDetailView() {
      this.showDetail = false;
    },

    addHealthManager(data) {
      const newHealthManager = {
        id: data.id,
        name: data.name,
        lastname: data.lastname,
        market: data.market,
        avatar: data.avatar ? URL.createObjectURL(data.avatar.file) : null,
        phone: data.phone,
        email: data.email,
      };
      this.healthManagerList.unshift(newHealthManager);
      this.incrementPagination();

      this.closeCreateModal();
    },

    incrementPagination() {
      this.pagination.totalItems += 1;
      this.pagination.itemsPerPage += 1;
    },

    updateHealthManager(data) {
      for (let i = 0; this.healthManagerList.length; i += 1) {
        if (this.healthManagerList[i].id === data.id) {
          const newHealthManager = {
            id: data.id,
            name: data.name,
            lastname: data.lastname,
            market: data.market,
            avatar: this.getAvatar(data.avatar),
            requests: this.healthManagerList[i].requests,
            patients: this.healthManagerList[i].patients,
            phone: data.phone,
            email: data.email,
          };

          this.healthManagerList.splice(i, 1);
          this.healthManagerList.splice(i, 0, newHealthManager);
          break;
        }
      }

      this.$bvModal.hide('update-health-manager');

      this.setHealthManager(data);
    },

    deleteHealthManager(id) {
      this.$bvModal.hide('delete-health-manager');

      if (this.showDetail) {
        this.hideDetailView();
      }

      for (let i = 0; this.healthManagerList.length; i += 1) {
        if (this.healthManagerList[i].id === id) {
          this.healthManagerList.splice(i, 1);
          this.handleSearch(this.pagination.currentPage);
          break;
        }
      }
    },

    handleSearch(page = 1) {
      this.isTableLoading = true;
      this.$store.dispatch('healthManager/getAllHealthManager', page)
        .then((res) => {
          this.healthManagerList = res.healthManagerList;
          this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },

    handleSearchByInput(text) {
      if (text !== null) {
        this.searchText = text;
        this.handleFilterSearch();
      } else {
        this.searchText = null;
      }
    },

    getFilters() {
      const filtersGroup = [];

      if (this.searchText !== null) {
        filtersGroup.push(
          `name:${this.searchText}`,
          `lastname:${this.searchText}`,
        );
      }

      return getFiltersGroup(filtersGroup, this.filters);
    },

    getRequestData(page = 1) {
      return {
        page,
        filters: this.getFilters(),
        ordenation: getOrdenation(this.sort),
      };
    },

    handleFilterSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('healthManager/filterHealthManager', this.getRequestData(page))
        .then((res) => {
          this.healthManagerList = res.healthManagerList;
          this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },

    handleSearchByFilter() {
      this.handleFilterSearch();
      this.$root.$emit('bv::toggle::collapse', 'healthManager-sidebar');
    },

    handleSortAsc(param) {
      this.sort.type = this.sort.name === param ? getSortType(this.sort.type) : SORT_TYPE.ASC;
      this.sort.name = param;

      this.handleFilterSearch();
    },

    handlerSelectMarket(value) {
      this.filters.market = value;
    },

    setHealthManager(data) {
      this.healthManager = {
        id: data.id,
        name: data.name,
        lastname: data.lastname,
        market: data.market,
        phone: data.phone,
        avatar: data.avatar,
        email: data.email,
        patients: data.patients,
        requests: data.requests,
      };
    },

    handleCleanSearch() {
      this.filters.market = null;
      this.searchText = null;
      this.sort.name = null;
      this.sort.type = null;

      this.handleSearch();
    },
    getAvatar(avatar) {
      return getAvatarByProps(avatar);
    },
  },
  computed: {
    getFilterCount() {
      return getFiltersCount(this.filters);
    },
  },
  components: {
    PrivateTemplate,
    HeadingBar,
    CreateHealthManager,
    UpdateHealthManager,
    TableGroup,
    HealthManagerTable,
    DeleteHealthManager,
    HealthManagerDetail,
    HealthManagerFilters,
  },
};
</script>
