<template>
  <div>
    <RequestSummaryInfo
      :request="getRequest"
    />
    <Specialty
      :id="id"
      class="w-50"
      :label="`${$i18n.t('lb_specialty')}:`"
      :value="getSpecialty"
      @handleChangeSpecialty="handleChangeSpecialty"
      :isValid="this.validationState.specialty"
    />
    <div class="sp__v__2x"/>
    <InputGroup
        :id="`${id}-requestSumaryPathology`"
        class="w-50"
        :label="`${$i18n.t('lb_pathology')}:`"
        :placeholder="$i18n.t('lb_type_something')"
        @handlerInputChange="handleChangePathology"
        :value="getRequest.pathology"
        :isValid="this.validationState.pathology"
      />
    <div class="sp__v__2x"/>
    <InputGroup
        :id="`${id}-requestSumaryMedicalProcedure`"
        class="w-50"
        :label="`${$i18n.t('lb_medical_procedure')}:`"
        :placeholder="$i18n.t('lb_type_something')"
        @handlerInputChange="handleChangeMedicalProcedure"
        :value="getRequest.medicalProcedure"
        :isValid="this.validationState.medicalProcedure"
      />
    <div class="sp__v"/>
  </div>
</template>

<script>
import Specialty from '@/components/atoms/Select/Specialty.vue';
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import RequestSummaryInfo from './RequestSummaryInfo.vue';
import { getGender } from '@/helpers/UserHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    request: {
      type: Object,
      default: () => {},
    },
    validationState: {
      type: Object,
      default: () => ({
        specialty: null,
        pathology: null,
        medicalProcedure: null,
      }),
    },
  },
  methods: {
    getGender(value) {
      if (value) {
        return getGender(value);
      }

      return null;
    },

    handleChangeSpecialty(value) {
      this.$emit('handleChangeSpecialty', value);
    },

    handleChangePathology(text) {
      this.$emit('handleChangePathology', text);
    },

    handleChangeMedicalProcedure(text) {
      this.$emit('handleChangeMedicalProcedure', text);
    },
  },
  computed: {
    getSpecialty() {
      return this.request.specialty;
    },

    getRequest() {
      return this.request;
    },
  },
  components: {
    Specialty,
    InputGroup,
    RequestSummaryInfo,
  },
};
</script>
