<template>
  <div>
    <Spinner :isLoading="isLoading" />
    <div v-if="!isLoading" class="ReportCard w-card">
      <p class="text__xs">{{ label }}</p>
      <div class="ReportCard__info">
        <p class="text__h__b">{{ count }}</p>
        <Badge
          v-if="isPositivePercentage"
          variant="accepted"
        >
          <span class="ReportCard__badgeData">
            {{ `+${this.percentage}%` }}
            <img
              class="ReportCard__arrowImage"
              src="@/assets/components/badge_positive.svg"
            />
          </span>
        </Badge>
        <Badge
          v-if="!isPositivePercentage"
          variant="pending"
        >
          <span class="ReportCard__badgeData">
            {{ `${this.percentage}%` }}
            <img
              class="ReportCard__arrowImage"
              src="@/assets/components/badge_negative.svg"
              />
          </span>
        </Badge>
      </div>
    </div>
  </div>
</template>

<script>
import Badge from '@/components/atoms/Badge/Badge.vue';
import Spinner from '@/components/atoms/Spinner/Spinner.vue';

export default {
  props: {
    label: String,
    count: Number,
    percentage: Number,
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isPositivePercentage() {
      return this.percentage >= 0;
    },
  },
  components: {
    Badge,
    Spinner,
  },
};
</script>
<style lang="sass" scoped>
@import './ReportCard.sass'
</style>
