<template>
    <private-template>
    <template v-slot:private-template>
      <div class="sp__v__2x"/>
      <HeadingBar
          :title="$i18n.t('lb_calendar')"
          text="Buenos días, Miguel. Explora tu calendario."
        />
    </template>
  </private-template>
</template>

<script>
import PrivateTemplate from '@/components/organisms/PrivateTemplate.vue';
import HeadingBar from '@/components/molecules/HeadingBar/HeadingBar.vue';

export default {
  components: {
    PrivateTemplate,
    HeadingBar,
  },
};
</script>
