import {
  getAllDoctor,
  filterDoctor,
  searchDoctor,
  createDoctor,
  updateDoctor,
  deleteDoctor,
} from './doctorActions';

const doctor = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    getAllDoctor,
    filterDoctor,
    searchDoctor,
    createDoctor,
    updateDoctor,
    deleteDoctor,
  },
  getters: {
  },
};

export default doctor;
