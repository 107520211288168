import {
  getAllNotification,
  deleteNotification,
} from './notificationActions';

const clinic = {
  namespaced: true,
  actions: {
    getAllNotification,
    deleteNotification,
  },
};

export default clinic;
