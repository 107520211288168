<template>
  <div class="UserSmallInfo">
    <b-avatar
      variant="empty"
      class="UserSmallInfo__image"
      size="36px"
      :src="icon"
    />
    <div class="sp" />
    <div>
      <p class="text__psb">{{ label }}</p>
      <p class="text__sr text-secondary">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    label: String,
    text: String,
  },
};
</script>

<style lang="sass">
  @import './UserSmallInfo.sass'
</style>
