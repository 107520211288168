<template>
  <p
    class="text__sr text-secondary"
    v-html="$i18n.t('cp_pagination_count',
      { from: getFromItems, to: getToItems, total: pagination.totalItems}
    )"
  />
</template>

<script>
import { ITEMS_PER_PAGE } from '@/helpers/ApiHelper';

export default {
  props: {
    pagination: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getFromItems() {
      const lastPage = this.pagination.currentPage - 1;

      return lastPage !== 0 ? (lastPage * ITEMS_PER_PAGE) + 1 : 1;
    },

    getToItems() {
      const lastPage = this.pagination.currentPage - 1;

      return (lastPage * ITEMS_PER_PAGE) + this.pagination.itemsPerPage;
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './Pagination.sass'
</style>
