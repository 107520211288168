<template>
  <b-form-select
    class="Select"
    :id="`${id}-select`"
    v-model="selected"
    :options="options"
    @change.native="changeSelection"
    :state="isValid"
    :disabled="isDisabled"
  />
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    selectedOption: {
      type: [String, Boolean],
      default: null,
    },
    placeholder: String,
    isValid: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    help: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.selectedOption,
    };
  },
  methods: {
    changeSelection(e) {
      this.selected = e.target.value !== '' ? e.target.value : null;
      this.$emit('handlerSelect', this.selected);
    },
  },
  watch: {
    selectedOption(newValue) {
      this.selected = newValue;
    },
  },
};
</script>
