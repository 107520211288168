<template>
  <HealthManagerForm
    id="create-health-manager"
    :title="$i18n.t('lb_new_health_manager')"
    @handleOk="create"
    @handleCancel="handleCancel"
    :isLoading="isLoading"
  />
</template>

<script>
import HealthManagerForm from './HealthManagerForm.vue';
import { getAvatarBase64 } from '@/helpers/UserHelper';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.$bvModal.show('create-health-manager');
  },
  methods: {
    create(data) {
      const newData = {
        ...(this.getAvatar(data.avatar)),
        email: data.email,
        lastname: data.lastname,
        market: data.market.id,
        name: data.name,
        phone: data.phone,
      };

      this.isLoading = true;

      this.$store.dispatch('healthManager/createHealthManager', newData)
        .then((res) => {
          const resData = data;
          resData.id = res.id;
          this.$emit('addHealthManager', resData);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getAvatar(avatar) {
      return getAvatarBase64(avatar);
    },

    handleCancel() {
      this.$emit('handleCancel');
    },
  },
  components: {
    HealthManagerForm,
  },
};
</script>
