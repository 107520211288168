import { setError, setLoginError, cleanError } from './errorMutations';
import { getErrors, hasErrors } from './errorGetters';

const user = {
  namespaced: true,
  state: () => ({
    list: [],
    title: null,
    showError: false,
  }),
  mutations: {
    setError,
    setLoginError,
    cleanError,
  },
  actions: {},
  getters: {
    getErrors,
    hasErrors,
  },
};

export default user;
