<template>
  <div class="LabelMiniCard w-card">
    <p class="LabelMiniCard__label text__xs">{{ label }}</p>
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './LabelMiniCard.sass'
</style>
