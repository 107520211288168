<template>
  <div class="PrivateTemplate">
    <SideMenu />
    <div class="viewContainer">
      <Header />
      <div class="viewContainer__content">
        <slot name="private-template"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '@/components/molecules/SideMenu/SideMenu.vue';
import Header from '@/components/molecules/Header/Header.vue';

export default {
  components: {
    SideMenu,
    Header,
  },
};
</script>

<style lang="sass" scoped>
@import './PrivateTemplate.sass'
</style>
