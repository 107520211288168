<template>
  <div>
    <Breadcrumb
      @handleClickAction="hideDetail"
      :items="[
        {
          name: $i18n.t('lb_patients'),
          hasAction: true,
        },
        {
          name: getFullName,
        },
      ]"
    />
    <div class="sp__v"/>
    <HeadingBar
      :title="getFullName"
      :text="$i18n.t('cp_created_at', { date: getFormattedDate(patient.createdAt) })"

      :isData="true"
    >
      <div class="detailCard__actionBlock">
        <b-button
          v-if="!isDoctor"
          @click.prevent="handleDelete"
          variant="secondary" size="sm"
        >
          {{ $i18n.t('btn_delete') }}
        </b-button>
        <span class="sp__1s5x"/>
        <b-button
          @click.prevent="handleEdit"
          variant="secondary"
          size="sm"
        >
          {{ $i18n.t('btn_edit') }}
        </b-button>
      </div>
    </HeadingBar>
    <div class="sp__v__3x"/>
    <b-tabs>
      <b-tab :title="$i18n.t('lb_info')">
        <PatientInfo
          :patient="patient"
        />
      </b-tab>
      <b-tab :title="$i18n.t('lb_contact')">
        <PatientContact
          :patient="patient"
        />
      </b-tab>
      <b-tab :title="getDocumentsTitle">
        <div class="sp__v__3x"/>
        <DocumentTable
          v-if="hasDocuments"
          :items="patient.documents"
          canDownload
        />
        <p
          v-if="!hasDocuments"
          class="text__pr"
        >
          {{ $i18n.t('cp_not_result') }}
        </p>
      </b-tab>
      <b-tab :title="$i18n.t('lb_request_count', { count: patient.totalRequests })">
        <PatientRequest
          :showPagination="false"
          :items="patient.requestList"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import moment from 'moment';
import Breadcrumb from '@/components/atoms/Breadcrumb/Breadcrumb.vue';
import HeadingBar from '@/components/molecules/HeadingBar/HeadingBar.vue';
import PatientInfo from '@/components/molecules/Patient/PatientInfo.vue';
import PatientContact from '@/components/molecules/Patient/PatientContact.vue';
import PatientRequest from '@/components/molecules/Patient/PatientRequest.vue';
import DocumentTable from '@/components/molecules/Tables/DocumentTable/DocumentTable.vue';

export default {
  props: {
    patient: {
      type: Object,
      default: () => {},
    },
    isDoctor: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleEdit() {
      this.$emit('showUpdateForm', this.patient);
    },

    handleDelete() {
      this.$emit('showDeleteConfirm', this.patient);
    },

    hideDetail() {
      this.$emit('hideDetail');
    },

    getFormattedDate(date) {
      return moment(String(date)).format('DD/MM/YYYY');
    },
  },
  computed: {
    getFullName() {
      return `${this.patient.name} ${this.patient.lastname}`;
    },

    hasDocuments() {
      return this.patient.documents !== undefined && this.patient?.documents.length > 0;
    },

    getDocumentsTitle() {
      return this.hasDocuments ? this.$i18n.t('lb_documents_count', { count: this.patient.documents.length }) : this.$i18n.t('lb_documents');
    },
  },
  components: {
    Breadcrumb,
    HeadingBar,
    PatientInfo,
    PatientContact,
    PatientRequest,
    DocumentTable,
  },
};
</script>
