import i18n from '@/plugins/vue-i18n';

export default [
  {
    key: 'caseNumber',
    label: i18n.t('lb_instance'),
    sortable: true,
  },
  {
    key: 'createdAt',
    label: i18n.t('lb_created'),
    sortable: true,
  },
  {
    key: 'name',
    label: i18n.t('lb_name'),
    sortable: true,
  },
  {
    key: 'origin',
    label: i18n.t('lb_origin'),
    sortable: true,
  },
  {
    key: 'specialty',
    label: i18n.t('lb_specialty'),
    sortable: true,
  },
  {
    key: 'medicalProcedure',
    label: i18n.t('lb_procedure'),
    sortable: true,
  },
  {
    key: 'clinic',
    label: i18n.t('lb_refer_to_f'),
    sortable: true,
  },
  {
    key: 'doctor',
    label: i18n.t('lb_refer_to'),
    sortable: true,
  },
  {
    key: 'requestStage',
    label: i18n.t('lb_stage'),
    sortable: true,
  },
  {
    key: 'status',
    label: i18n.t('lb_status'),
    sortable: true,
  },
  {
    key: 'actions',
    label: '',
  },
];
