import getAllCities from './citiesActions';

const cities = {
  namespaced: true,
  state: () => ([]),
  actions: {
    getAllCities,
  },
};

export default cities;
