import axios from '../services/index';
import InsuranceFactory from './InsuranceFactory';

const INSURANCE = '/api/insurances';

class InsuranceAPI {
  constructor() {
    this.insurance = axios;
  }

  getAllInsurance(data) {
    return this.insurance
      .get(INSURANCE, data)
      .then((res) => res.data.collection.map(
        (insurance) => InsuranceFactory.getInsurance(insurance),
      ))
      .catch((error) => Promise.reject(error));
  }
}

export default InsuranceAPI;
