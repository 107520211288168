<template>
  <div class="filters">
    <p class="text__psb">Filtrar</p>
    <div class="sp__v__2x" />
    <InputGroupAutocomplete
      id="cityClinicFilter"
      :label="$i18n.t('lb_city')"
      :placeholder="$i18n.t('lb_type_something')"
      @handlerInputChange="handlerChangeCity"
      @handlerSelectSuggestion="handlerSelectSuggestion"
      :value="getCity"
      :suggestions="cities"
      :showList="showList"
      :isLoading="isLoading"
    />

  </div>
</template>

<script>
import InputGroupAutocomplete from '@/components/atoms/Input/InputGroupAutocomplete.vue';
import { MIN_SEARCH__LENGTH } from '@/helpers/ComponentHelper';

export default {
  props: {
    filters: {
      type: Object,
      default: () => ({
        filters: {
          city: null,
        },
      }),
    },
  },
  data() {
    return {
      city: this.filters.city,
      searchText: null,
      cities: [],
      showList: false,
      isLoading: false,
    };
  },
  methods: {
    loadCities() {
      this.isLoading = true;

      this.$store.dispatch('cities/getAllCities', this.searchText)
        .then((res) => {
          this.cities = res;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });

      this.showList = this.cities.length >= 1;
    },

    getTextLowercased(text) {
      return text.toLowerCase();
    },

    handlerChangeCity(value) {
      if (value?.length >= MIN_SEARCH__LENGTH) {
        this.searchText = value;
        this.loadCities();
      }
      if (!value) {
        this.cities = [];
      }
    },

    handlerSelectSuggestion(value) {
      this.$emit('handlerChangeCityFilter', value);
      this.cities = [];
    },
  },
  computed: {
    getCity() {
      return this.filters.city;
    },
  },
  components: {
    InputGroupAutocomplete,
  },
};
</script>
