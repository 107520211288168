import getAllSpecialties from './specialtiesActions';
import setSpecialties from './specialtiesMutations';
import { getSpecialties, hasSpecialties } from './specialtiesGetters';

const specialties = {
  namespaced: true,
  state: () => ([]),
  actions: {
    getAllSpecialties,
  },
  mutations: {
    setSpecialties,
  },
  getters: {
    getSpecialties,
    hasSpecialties,
  },
};

export default specialties;
