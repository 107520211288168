<template>
  <div class="ResetPasswordForm">
    <img
      src="@/assets/logos/logo_inline.svg"
      class="w-75"
    />
    <div class="sp__v" />
    <p class="text__pr" v-html="getText" />
    <div class="sp__v__2x" />
    <InputGroup
      id="resetPassword"
      placeholder="contraseña"
      @handlerInputChange="handleChangePassword"
      @handleLogin="handleResetPassword"
      :isValid="this.validationState.password"
      :error="this.$i18n.t('val_user_verification')"
      type="password"
    />
    <div class="sp__v__1d5x" />
    <InputGroup
      id="resetRepeatPassword"
      placeholder="confirma tu contraseña"
      @handlerInputChange="handleChangeRepeatPassword"
      @handleLogin="handleResetPassword"
      :isValid="this.validationState.repeat"
      :error="this.$i18n.t('val_user_verification')"
      type="password"
    />
    <div class="sp__v__1d5x" />
    <FormButton
      @handleClick="handleResetPassword"
      :isLoading="isLoading"
    >
      {{ getButtonResetPasswordText }}
    </FormButton>
  </div>
</template>

<script>
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import FormButton from '@/components/atoms/Buttons/FormButton/FormButton.vue';
import {
  getPasswordValidationState, isSameValue,
  isValidDataText,
  isValidForm,
} from '@/helpers/ValidationHelper';

export default {
  props: {
    resetToken: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loginState: {
      password: null,
      repeat: null,
    },
    validationState: {
      password: null,
      repeat: null,
    },
    userToken: null,
    isLoading: false,
  }),

  methods: {
    handleChangePassword(text) {
      this.loginState.password = text;
    },

    handleChangeRepeatPassword(text) {
      this.loginState.repeat = text;
    },

    async resetPassword() {
      this.isLoading = true;
      const updateData = { token: this.currentUserToken, password: this.loginState.password };
      await this.$store.dispatch('auth/userPasswordUpdate', updateData)
        .then((res) => {
          this.isLoading = false;
          this.userToken = res.token;
          this.$router.push({ name: 'Request' });
        })
        .catch(() => {
          this.isLoading = false;
          this.resetState();
        });
    },

    handleResetPassword() {
      this.isLoading = true;
      const passwordValidationState = getPasswordValidationState(this.loginState.password);
      const repeatValidationState = getPasswordValidationState(this.loginState.repeat);
      const tokenValidationState = isValidDataText(this.currentUserToken);
      const samePasswordsState = isValidDataText(this.loginState.password)
        && isValidDataText(this.loginState.repeat)
        && isSameValue(this.loginState.password, this.loginState.repeat);

      if (isValidForm([tokenValidationState, passwordValidationState, samePasswordsState])) {
        this.resetValidationState();
        this.resetPassword();
      } else {
        this.validationState.password = passwordValidationState;
        this.validationState.repeat = repeatValidationState;
        this.isLoading = false;
        this.resetState();
      }
    },

    resetState() {
      this.loginState.password = null;
      this.loginState.repeat = null;
    },

    resetValidationState() {
      this.validationState.password = null;
      this.validationState.repeat = null;
    },

  },

  computed: {
    currentUserToken() {
      return this.resetToken ? this.resetToken : this.userToken;
    },

    getText() {
      return this.$i18n.t('txt_reset_password');
    },

    getButtonResetPasswordText() {
      return this.$i18n.t('btn_reset_password');
    },
  },

  components: {
    InputGroup,
    FormButton,
  },
};
</script>
