<template>
  <div class="Heading">
    <h2 v-if="!isSmall">{{ title }}</h2>
    <p v-if="isSmall" class="text__psbb">{{ title }}</p>
    <div class="d-flex">
      <div
        v-if="isData"
      />
      <p class="text__pr Heading__text">{{ text }}</p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: String,
    text: String,
    isData: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>
@import './Heading.sass'
</style>
