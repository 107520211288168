<template>
  <div class="MenuRow">
    <button
    class="MenuRow__link MenuRow__link--deselected"
    @click.prevent="showModal"
    >
        <MenuRowIcon
          :icon="icon"
          :isSelected="isCurrentPath"
        />
        <p class="MenuRow__label">{{ text }}</p>
    </button>
  </div>
</template>

<script>
import MenuRowIcon from './MenuRowIcon.vue';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    isCurrentPath() {
      return this.$route.path === this.path;
    },
  },
  methods: {
    showModal() {
      this.$emit('show-modal', this.text);
    },
  },
  components: {
    MenuRowIcon,
  },
};
</script>

<style lang="sass">
@import './MenuRow.sass'
</style>
