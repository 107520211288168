<template>
  <div>
    <button
      @click.prevent="handleShowNotificationList"
      class="Notification">
      <img src="@/assets/components/notification.svg" />
      <div
        v-if="hasNotifications"
        class="Notification__alert"
      />
    </button>
    <NotificationSideBar
      id="notificationList"
      :show="showNotificationList"
      :notifications="notificationList"
      :isLoading="isLoading"
    />
  </div>
</template>

<script>
import NotificationSideBar from '@/components/molecules/Notification/NotificationSideBar.vue';

export default {
  data: () => ({
    showNotificationList: false,
    notificationList: [],
    isLoading: false,
  }),
  created() {
    this.getNotificationList();
  },
  methods: {
    getNotificationList() {
      this.isLoading = true;

      this.$store.dispatch('notification/getAllNotification')
        .then((res) => {
          this.notificationList = res;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    handleShowNotificationList() {
      this.$root.$emit('bv::toggle::collapse', 'notificationList-sidebar');
      this.showNotificationList = true;
    },
  },
  computed: {
    hasNotifications() {
      return this.notificationList.length > 0;
    },
  },
  components: {
    NotificationSideBar,
  },
};
</script>

<style lang="sass" scoped>
  @import './Notification.sass'
</style>
