import getAllMarkets from './marketsActions';
import setMarkets from './marketsMutations';
import { getMarkets, hasMarkets } from './marketsGetters';

const markets = {
  namespaced: true,
  state: () => ([]),
  actions: {
    getAllMarkets,
  },
  mutations: {
    setMarkets,
  },
  getters: {
    getMarkets,
    hasMarkets,
  },
};

export default markets;
