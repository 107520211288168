import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth/auth';
import user from './user/user';
import request from './request/request';
import patient from './patient/patient';
import healthManager from './healthManager/healthManager';
import clinic from './clinic/clinic';
import doctor from './doctor/doctor';
import notification from './notification/notification';
import error from './error/error';
import specialties from './specialties/specialties';
import insurance from './insurance/insurance';
import countries from './countries/countries';
import markets from './markets/markets';
import cities from './cities/cities';
import note from './note/note';
import document from './document/document';
import kpi from './kpi/kpi';
import state from './state';
import mutations from './mutations';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    request,
    patient,
    healthManager,
    clinic,
    error,
    specialties,
    doctor,
    notification,
    insurance,
    countries,
    markets,
    cities,
    kpi,
    note,
    document,
  },
  state,
  mutations,
  actions: {},
});
