export default {
  // User
  lb_configuration: 'Configuración',
  // Patient
  lb_patient: 'Paciente',
  lb_patients: 'Pacientes',
  lb_total_patients: 'TOTAL PACIENTES',
  lb_new_patient: 'Nuevo Paciente',
  lb_update_patient: 'Editar Paciente',
  lb_select_patient: 'Seleccionar Paciente',
  lb_import_patient: 'Importar Paciente',
  // Doctors
  lb_doctors: 'Doctores',
  lb_new_doctor: 'Nuevo Doctor',
  lb_update_doctor: 'Editar Doctor',
  lb_add_doctor: 'Asignar Doctor',
  // Health Manager
  lb_health_manager: 'Health Manager',
  lb_new_health_manager: 'Nuevo Health Manager',
  lb_update_health_manager: 'Editar Health Manager',
  lb_add_health_manager: 'Asignar Health Manager',
  // Calendar
  lb_calendar: 'Calendario',
  // Reviews
  lb_reviews: 'Reviews',
  // Reports
  lb_reports: 'Informes',
  // Clinics
  lb_clinic: 'Clínica',
  lb_clinics: 'Clínicas',
  lb_new_clinic: 'Nueva Clínica',
  lb_update_clinic: 'Editar Clínica',
  lb_clinic_logo: 'Logo de la clínica',
  lb_clinic_name: 'Nombre de la clínica',
  lb_add_clinic: 'Asignar Clínica',
  // Applications
  lb_applications: 'Aplicaciones',
  // Treatments
  lb_treatments: 'Tratamientos',
  // Skyadoctor
  lb_skyadoctor: 'SkyaDoctor',
  // Requests
  lb_total_requests: 'TOTAL SOLICITUDES',
  lb_requests_pending: 'SOLICITUDES PENDIENTES',
  lb_requests_accepted: 'TOTAL ACEPTADAS',
  lb_requests_rejected: 'TOTAL RECHAZADAS',
  lb_new_request: 'Nueva Solicitud',
  lb_update_request: 'Editar Solicitud',
  lb_copy_request: 'Copiar Solicitud',
  lb_request_count: 'Solicitudes ({count})',
  // Documents
  lb_documents: 'Documentos',
  lb_add_document: 'Añadir documento',
  lb_documents_count: 'Documentos ({count})',
  // Notes
  lb_note: 'Nota',
  lb_notes: 'Notas',
  lb_add_note: 'Añadir nota',
  lb_notes_count: 'Notas ({count})',
  // Notifications
  lb_notifications: 'Notificationes',
  lb_notifications_empty: 'No tienes nuevas notificationes',
  // Contact
  lb_contact_info: 'Información de contacto',
  lb_contact_not_accepted: 'Acepta la solicitud para ver la información de contacto.',
  lb_call: 'Llamada',
  // Password
  lb_change_password: 'Cambiar contraseña',
  lb_actual_password: 'Contraseña actual',
  lb_new_password: 'Nueva contraseña',
  // Filters
  lb_select_origin: 'Selecciona origen',
  lb_select_specialty: 'Selecciona especialidad',
  lb_select_clinic: 'Selecciona clínica',
  lb_select_insurance: 'Selecciona seguro',
  lb_select_stage: 'Selecciona etapa',
  lb_select_status: 'Selecciona estado',
  lb_select_date: 'Selecciona una fecha',
  lb_select_market: 'Selecciona mercado',
  // Type
  lb_type_something: 'Introduce texto',
  lb_type_data: 'Introduce dato',
  lb_type_year: 'Introduce año',
  lb_type_email: 'Introduce email',
  lb_type_phone: 'Introduce teléfono',
  // Intervals
  lb_interval_all: 'Todo el tiempo',
  lb_interval_week: 'Última semana',
  lb_interval_month: 'Último mes',
  lb_interval_year: 'Último año',
  // Common
  lb_created: 'Creado',
  lb_name: 'Nombre',
  lb_lastname: 'Apellido',
  lb_origin: 'Origen',
  lb_date: 'Fecha',
  lb_dob: 'Fecha de nacimiento',
  lb_email: 'Email',
  lb_phone: 'Teléfono',
  lb_request: 'Solicitudes',
  lb_gender: 'Género',
  lb_tutor: 'Tutor',
  lb_insurance: 'Seguro',
  lb_hospital: 'Hospital',
  lb_market: 'Mercado',
  lb_info: 'Información',
  lb_contact: 'Contacto',
  lb_contact_person: 'Nombre persona de contacto',
  lb_city: 'Ciudad',
  lb_foundation_year: 'Año de fundación',
  lb_description: 'Descripción',
  lb_acreditations: 'Acreditaciones',
  lb_google_mybusiness_account: 'Cuenta de Google My Business',
  lb_contact_data: 'Datos de contacto',
  lb_vp_request: 'Solicitudes Vidasprime',
  lb_total_request: 'Solicitudes totales',
  lb_instance: 'Caso',
  lb_instance_number: 'Nº de caso',
  lb_specialty: 'Especialidad',
  lb_pathology: 'Patología',
  lb_doctor: 'Doctor',
  lb_stage: 'Etapa',
  lb_status: 'Estado',
  lb_emergency_degree: 'GRADO DE INTERÉS',
  lb_private_insurance: 'SEGURO MÉDICO PRIVADO',
  lb_recent_reports: 'INFORMES RECIENTES',
  lb_summary: 'Resumen',
  lb_medical_procedure: 'Procedimiento médico',
  lb_confirm_title: '¿Estás seguro?',
  lb_confirm_text: 'Esta operación no se podrá revertir.',
  lb_bio: 'Bio',
  lb_professional_experience: 'Experiencia profesional',
  lb_others: 'Otros',
  lb_logo: 'Logo',
  lb_new_activity: 'Nueva actividad',
  lb_can_not_undo: 'Si confirmas el usuario se borrará.',
  lb_creation_date: 'Fecha de creación',
  lb_private_insurance_min: 'Seguro privado',
  lb_can_not_undo_health_manager: 'Si confirmas el Health Manager se borrará.',
  lb_can_not_undo_clinic: 'Si confirmas la Clínica se borrará.',
  lb_procedure: 'Procedimiento',
  lb_refer_to: 'Derivado a',
  lb_refer_to_f: 'Derivada a',
  lb_dni_passport: 'DNI o Pasaporte',
  lb_dni_passport_s: 'DNI / Pasaporte',
  lb_clients: 'Clientes',
  lb_online_presence: 'Presencia online',
  lb_videoconsultation: 'Videoconsulta',
  lb_patient_experience: 'Experiencia paciente',
  // forgot password
  lb_forgot_password: '¿Has olvidado tu contraseña?',
};
