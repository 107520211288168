<template>
  <b-row>
    <b-col class="col-6">
      <SelectGroup
        id="clinicHasInsurance"
        :label="$i18n.t('lb_private_insurance_min')"
        :selectedOption="this.hasSelectedInsurances"
        :options="booleanOptions"
        @handlerSelect="handleChangeHasInsurance"
      />
    </b-col>
    <b-col class="col-6">
      <div class="InsuranceComboselector" v-if="this.showInsurances">
        <div class="InsuranceComboselector__selectGroup">
          <SelectGroup
            id="clinicInsurances"
            :options="getInsuranceOptions"
            :label="$i18n.t('lb_select_insurance')"
            :selectedOption="this.insuranceSelected"
            @handlerSelect="handleChangeInsurances"
            :isLoading="isLoading"
          />
          <FormButton
            @handleClick="handleAddInsurance"
          >
            {{ $i18n.t('btn_add') }}
          </FormButton>
        </div>
        <div class="InsuranceComboselector__list">
          <MultiBadge
            v-for="(insurance, index) in insuranceBadgeList"
            :key="index"
            :item="insurance"
            @handleDelete="handleDeleteInsurance(index)"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import { BOOLEAN_SELECTION_OPTIONS } from '@/helpers/ComponentHelper';
import MultiBadge from '@/components/atoms/Badge/MultiBadge.vue';
import FormButton from '@/components/atoms/Buttons/FormButton/FormButton.vue';

export default {
  props: {
    insurances: {
      type: Array,
      default: () => [],
    },
    hasSelectedInsurances: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      booleanOptions: BOOLEAN_SELECTION_OPTIONS,
      insuranceSelected: null,
      insuranceSelectedInfo: null,
      insuranceBadgeList: null,
      showInsurances: this.hasSelectedInsurances,
      hasCurrentInsurances: false,
      isLoading: false,
    };
  },
  created() {
    if (!this.hasInsurances) {
      this.loadInsuranceList();
    }
  },
  mounted() {
    this.loadInformation();
  },
  methods: {
    async loadInsuranceList() {
      this.isLoading = true;
      await this.$store.dispatch('insurance/getAllInsurance')
        .then(() => {
          this.loadBadgeList();
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    loadInformation() {
      this.hasCurrentInsurances = this.hasSelectedInsurances;
      this.showInsurances = this.hasSelectedInsurances;
      this.loadBadgeList();
    },

    loadBadgeList() {
      if (this.showInsurances) {
        this.insuranceBadgeList = this.insurance.filter(
          (insurance) => this.insurances.some(
            (el) => insurance.id === el.id,
          ),
        );
      }
    },

    handleChangeHasInsurance(value) {
      const boolValue = value === 'true';
      this.hasCurrentInsurances = value;
      this.showInsurances = boolValue;
      this.resetInsuranceValues();

      this.$emit('handleChangeHasInsurance', this.hasCurrentInsurances);
    },

    handleChangeInsurances(value) {
      this.insuranceSelectedInfo = this.getInsuranceInfo(value);
      this.insuranceSelected = value;
    },

    handleAddInsurance() {
      this.insuranceBadgeList.push(this.insuranceSelectedInfo);
      const updatedBadgeList = this.removeDuplicates(this.insuranceBadgeList);
      this.insuranceBadgeList = updatedBadgeList;

      this.$emit('handleChangeInsurances', this.insuranceBadgeList);
    },

    handleDeleteInsurance(index) {
      this.insuranceBadgeList.splice(index, 1);

      this.$emit('handleChangeInsurances', this.insuranceBadgeList);
    },

    resetInsuranceValues() {
      this.insuranceBadgeList = [];
    },

    removeDuplicates(list) {
      return [...new Set(list)];
    },

    getInsuranceInfo(id) {
      return this.insurance.find((el) => el.id === id);
    },
  },
  computed: {
    ...mapGetters('insurance', {
      insurance: 'getInsurances',
      hasInsurances: 'hasInsurances',
    }),

    getInsuranceOptions() {
      const newInsurances = this.insurance.map((insurance) => {
        const newInsurance = {
          text: insurance.name,
          value: insurance.id,
        };

        return newInsurance;
      });

      return newInsurances;
    },
  },
  components: {
    SelectGroup,
    FormButton,
    MultiBadge,
  },
};
</script>

<style lang="sass" scoped>
@import './InsuranceComboSelector.sass'
</style>
