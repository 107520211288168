<template>
  <ModalAction
    :id="id"
    :okLabel="$i18n.t('btn_confirm')"
    :cancelLabel="$i18n.t('btn_cancel')"
    @handleOk="handleDelete"
    isCentered
    :hideHeader="true"
  >
    <div class="text-center">
      <p class="text__title">
        {{ title }}
      </p>
      <div class="sp__v__1s5x"/>
      <p class="text__pr">
        {{ text }}
      </p>
    </div>
  </ModalAction>
</template>

<script>
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      uuid: {
        type: String,
        default: null,
      },
    };
  },
  methods: {
    setUuid(uuid) {
      this.uuid = uuid;
    },

    handleDelete() {
      this.$emit('handleDelete', this.uuid);
    },
  },
  components: {
    ModalAction,
  },
};
</script>
