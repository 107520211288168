import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import SpecialtyFactory from './SpecialtyFactory';

const SPECIALTIES = '/api/specialties';

class specialtyAPI {
  constructor() {
    this.specialty = getAxiosWithAuthHeader();
  }

  getAllSpecialties() {
    return this.specialty
      .get(SPECIALTIES)
      .then((res) => SpecialtyFactory.getAllSpecialty(res.data))
      .catch((error) => Promise.reject(error));
  }
}

export default specialtyAPI;
