import {
  createDocument,
  deleteDocument,
  deleteRequestDocument,
} from './documentActions';

const document = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    createDocument,
    deleteDocument,
    deleteRequestDocument,
  },
  getters: {
  },
};

export default document;
