<template>
  <div>
    <UserFormVidasprime
      id="vidasprime-user-form"
      :title="$i18n.t('lb_configuration')"
      :user="user"
      @handleOk="update"
      @handleClose="closeUpdateModal"
    />
    <UserFormClinic
      id="clinic-user-form"
      :title="$i18n.t('lb_configuration')"
      :clinic="user"
      @handleOk="update"
      @handleClose="closeUpdateModal"
    />
    <UserFormHealthManager
      id="healthmanager-user-form"
      :title="$i18n.t('lb_configuration')"
      :healthManager="user"
      @handleOk="update"
      @handleClose="closeUpdateModal"
    />
    <UserFormDoctor
      id="doctor-user-form"
      :title="$i18n.t('lb_configuration')"
      :doctor="user"
      @handleOk="update"
      @handleClose="closeUpdateModal"
    />
  </div>
</template>

<script>
import UserFormVidasprime from '@/components/organisms/User/UserFormVidasprime.vue';
import UserFormClinic from '@/components/organisms/User/UserFormClinic.vue';
import UserFormHealthManager from '@/components/organisms/User/UserFormHealthManager.vue';
import UserFormDoctor from '@/components/organisms/User/UserFormDoctor.vue';

export default {
  props: {
    user: {
      type: Object,
    },
    isVidasprime: {
      type: Boolean,
    },
    isHealthManager: {
      type: Boolean,
    },
    isClinic: {
      type: Boolean,
    },
    isDoctor: {
      type: Boolean,
    },
  },
  mounted() {
    if (this.isVidasprime) {
      this.$bvModal.show('vidasprime-user-form');
    } else if (this.isClinic) {
      this.$bvModal.show('clinic-user-form');
    } else if (this.isHealthManager) {
      this.$bvModal.show('healthmanager-user-form');
    } else {
      this.$bvModal.show('doctor-user-form');
    }
  },
  methods: {
    closeUpdateModal() {
      this.$emit('closeUpdateModal');
    },

    update(data) {
      this.updateUser(data);
      this.closeUpdateModal();
    },

    updateUser(data) {
      this.$store.commit('user/setUser', data);
    },
  },
  components: {
    UserFormVidasprime,
    UserFormClinic,
    UserFormHealthManager,
    UserFormDoctor,
  },
};
</script>
