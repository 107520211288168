import { render, staticRenderFns } from "./ModalFooter.vue?vue&type=template&id=d0c44c38&scoped=true&"
import script from "./ModalFooter.vue?vue&type=script&lang=js&"
export * from "./ModalFooter.vue?vue&type=script&lang=js&"
import style0 from "./ModalFooter.vue?vue&type=style&index=0&id=d0c44c38&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0c44c38",
  null
  
)

export default component.exports