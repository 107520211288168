<template>
  <div>
    <HeadingBar
      :title="title"
      :text="text"
    >
      <div class="d-flex">
        <Select
          id="patientIntervalSelector"
          :options="intervalOptions"
          :selectedOption="selectedInterval"
          @handlerSelect="handlerSelectInterval"
        />
      </div>
    </HeadingBar>
    <div class="sp__v__2x" />
    <div class="HeadingCards__list">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import HeadingBar from '@/components/molecules/HeadingBar/HeadingBar.vue';
import Select from '@/components/atoms/Select/Select.vue';
import { INTERVAL, INTERVAL_OPTIONS } from '@/helpers/ComponentHelper';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedInterval: INTERVAL.WEEK.key,
      intervalOptions: INTERVAL_OPTIONS,
    };
  },
  methods: {
    handlerSelectInterval(interval) {
      this.$emit('handlerSelectInterval', interval);
    },
  },
  components: {
    HeadingBar,
    Select,
  },
};
</script>

<style lang="sass" scoped>
@import './HeadingCards.sass'
</style>
