<template>
  <div
    :class="
      isRounded ?
      'ImagePlaceholder ImagePlaceholder__rounded' :
      'ImagePlaceholder'"
    >
      <img src="@/assets/components/placeholder.svg" />
  </div>
</template>

<script>

export default {
  props: {
    isRounded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './ImagePlaceholder.sass'
</style>
