import NoteApi from '../../domain/note/NoteApi';

export function createNote({ commit }, data) {
  const noteApi = new NoteApi();
  const createRequest = noteApi.createNote(data);

  return createRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updateNote({ commit }, data) {
  const noteApi = new NoteApi();
  const updateRequest = noteApi.updateNote(data);

  return updateRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteNote({ commit }, id) {
  const noteApi = new NoteApi();
  const deleteRequest = noteApi.deleteNote(id);

  return deleteRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
