import esComponents from './es-components';
import eslabels from './es-labels';
import esButtons from './es-buttons';
import esValidations from './es-validations';
import esTexts from './es-texts';

const messages = {
  ...esComponents,
  ...eslabels,
  ...esButtons,
  ...esValidations,
  ...esTexts,
};

export default messages;
