import PaginationModel from './PaginationModel';

class PaginationFactory {
  constructor() {
    this.pagination = {};
  }

  getPagination(pagination) {
    this.pagination = new PaginationModel({
      currentPage: pagination.actualPage,
      itemsPerPage: pagination.items,
      lastPage: pagination.lastPage,
      nextPage: pagination.nextPage,
      previousPage: pagination.previousPage,
      totalItems: pagination.totalItems,
    });

    return this.pagination;
  }
}

export default new PaginationFactory();
