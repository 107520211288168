<template>
  <div class="filters">
    <p class="text__psb">Filtrar</p>
    <div class="sp__v__2x" />
    <Specialty
      id="specialtyDoctorFilter"
      :label="$i18n.t('lb_specialty')"
      :value="getSpecialty"
      @handleChangeSpecialty="handlerSelectSpecialty"
    />
  </div>
</template>

<script>
import Specialty from '@/components/atoms/Select/Specialty.vue';

export default {
  props: {
    filters: {
      type: Object,
      default: () => ({
        filters: {
          specialty: null,
        },
      }),
    },
  },
  data() {
    return {
      specialty: this.filters.specialty,
    };
  },
  methods: {
    handlerSelectSpecialty(value) {
      this.specialty = value.id;
      this.$emit('handlerSelectSpecialty', value.id);
    },
  },
  computed: {
    getSpecialty() {
      return this.specialty;
    },
  },
  components: {
    Specialty,
  },
};
</script>
