import DocumentApi from '../../domain/document/DocumentApi';

export function createDocument({ commit }, data) {
  const documentApi = new DocumentApi();
  const createRequest = documentApi.createDocument(data);

  return createRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteDocument({ commit }, id) {
  const documentApi = new DocumentApi();
  const deleteRequest = documentApi.deleteDocument(id);

  return deleteRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteRequestDocument({ commit }, id) {
  const documentApi = new DocumentApi();
  const deleteRequest = documentApi.deleteRequestDocument(id);

  return deleteRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
