<template>
  <div class="ResetPasswordView">
    <div class="ResetPasswordView__resetPasswordFormContainer">
      <ResetPasswordForm
        :resetToken="resetToken"
      />
    </div>
    <div class="ResetPasswordView__imageContainer" />
  </div>
</template>

<script>
import ResetPasswordForm from '@/components/organisms/ResetPassword/ResetPasswordForm.vue';

export default {
  name: 'ResetPasswordView',
  components: {
    ResetPasswordForm,
  },
  data: () => ({
    resetToken: null,
  }),
  created() {
    this.getResetToken();
  },
  methods: {
    getResetToken() {
      const routeParam = this.$route.params.token;
      this.resetToken = !this.isEmpty(routeParam) ? routeParam : null;
    },
    isEmpty(data) {
      return Object.keys(data).length === 0;
    },
  },
};
</script>

<style lang="sass" scoped>
@import './ResetPasswordView.sass'
</style>
