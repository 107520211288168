<template>
  <HeadingCards
    :title="title"
    :text="text"
    @handlerSelectInterval="handleSelectInterval"
  >
    <ReportCard
      v-for="(kpi, index) in kpis"
      :key="index"
      :label="kpi.label"
      :count="kpi.amount"
      :percentage="kpi.percentage"
      :isLoading="isLoading"
    />
  </HeadingCards>
</template>

<script>
import HeadingCards from '@/components/molecules/HeadingCards/HeadingCards.vue';
import ReportCard from '@/components/atoms/Cards/ReportCard/ReportCard.vue';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    kpis: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSelectInterval(interval) {
      this.$emit('handleSelectInterval', interval);
    },
  },
  components: {
    HeadingCards,
    ReportCard,
  },
};
</script>
