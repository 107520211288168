import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import UserFactory from './UserFactory';

const USERS = '/api/profile';
const USER__RESET__PASSWORD = `${USERS}/password`;

class userAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  resetPassword(resetData) {
    return this.client
      .put(USER__RESET__PASSWORD, resetData)
      .then()
      .catch((error) => Promise.reject(error));
  }

  getUser(data) {
    return this.client
      .get(USERS, data)
      .then((res) => UserFactory.getUser(res.data))
      .catch((error) => Promise.reject(error));
  }

  updateUser(data) {
    return this.client
      .put(USERS, data)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default userAPI;
