<template>
  <div class="SideMenu">
    <img
      src="@/assets/logos/logo_inline.svg"
      class="SideMenu__logo"
    />
    <div class="sp__v__3x" />
    <MenuRow
      url="Request"
      icon="inbox.svg"
      path="/request"
      text="Inbox"
    />
    <MenuRow
      url="Patient"
      icon="patient.svg"
      path="/patient"
      :text="$i18n.t('lb_patients')"
    />
    <MenuRow
      v-if="isClinic"
      url="Doctor"
      icon="doctor.svg"
      path="/doctor"
      :text="$i18n.t('lb_doctors')"
    />
    <MenuRow
      v-if="isVidasprime"
      url="HealthManager"
      icon="health-managers.svg"
      path="/health-manager"
      text="Health Managers"
    />
    <!-- <MenuRow
      url="Calendar"
      icon="calendar.svg"
      path="/calendar"
      :text="$i18n.t('lb_calendar')"
    /> -->
    <MenuRowAlert
      v-if="isVidasprimeOrHM"
      url="Calendar"
      icon="calendar.svg"
      path="/calendar"
      :text="$i18n.t('lb_calendar')"
      @show-modal="openModal"
    />
    <!-- <MenuRow
      url="Review"
      icon="reviews.svg"
      path="/review"
      :text="$i18n.t('lb_reviews')"
    /> -->
    <MenuRowAlert
      v-if="isVidasprimeOrHM"
      url="Review"
      icon="reviews.svg"
      path="/review"
      :text="$i18n.t('lb_reviews')"
      @show-modal="openModal"
    />
    <MenuRow
      v-if="isVidasprimeOrHM"
      url="Client"
      icon="clinic.svg"
      path="/client"
      :text="$i18n.t('lb_clients')"
    />
    <!-- <MenuRow
      url="Application"
      icon="application.svg"
      path="/application"
      :text="$i18n.t('lb_applications')"
    /> -->
    <MenuRowAlert
      v-if="isVidasprimeOrHM"
      url="OnlinePresence"
      icon="application.svg"
      path="/online-presence"
      :text="$i18n.t('lb_online_presence')"
      @show-modal="openModal"
    />
    <!-- <MenuRow
      url="Report"
      icon="report.svg"
      path="/report"
      :text="$i18n.t('lb_reports')"
    /> -->
    <!-- <MenuRow
      url="Treatment"
      icon="treatment.svg"
      path="/treatment"
      :text="$i18n.t('lb_treatments')"
    /> -->
    <MenuRowAlert
      v-if="isVidasprimeOrHM"
      url="PatientExperience"
      icon="treatment.svg"
      path="/patient-experience"
      :text="$i18n.t('lb_patient_experience')"
      @show-modal="openModal"
    />
    <!-- <MenuRow
      url="Skyadoctor"
      icon="skyadoctor.svg"
      path="/skyadoctor"
      :text="$i18n.t('lb_skyadoctor')"
    /> -->
    <MenuRowAlert
      v-if="isVidasprimeOrHM"
      url="Videoconsultation"
      icon="skyadoctor.svg"
      path="/video-consultation"
      :text="$i18n.t('lb_videoconsultation')"
      @show-modal="openModal"
    />
    <!-- <MenuRow
      url="UIKitComponents"
      icon="patient.svg"
      path="/components"
      text="UI Kit"
    /> -->
    <ComingSoon
      id="coming-soon"
      v-if="showModal"
      @handleClose="closeModal"
    />
  </div>
</template>

<script>
import ComingSoon from '@/components/atoms/ComingSoon/ComingSoon.vue';
import MenuRow from '@/components/atoms/Menu/MenuRow.vue';
import MenuRowAlert from '@/components/atoms/Menu/MenuRowAlert.vue';

export default {
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    isVidasprime() {
      return this.$store.getters['user/isVidasprime'];
    },

    isVidasprimeOrHM() {
      return this.$store.getters['user/isHealthManager'] || this.isVidasprime;
    },

    isClinic() {
      return this.$store.getters['user/isClinic'];
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },
  },
  components: {
    MenuRow,
    MenuRowAlert,
    ComingSoon,
  },
};
</script>

<style lang="sass" scoped>
@import './SideMenu.sass'
</style>
