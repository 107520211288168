<template>
  <div class="RequestHeader" v-if="getRequest">
    <div class="RequestHeader__userBlock">
      <p :class="
        getRequest.accepted
          ? 'RequestHeader__title text-primary'
          : 'RequestHeader__title'"
        >
        {{ `${getRequest.patient.name} ${getRequest.patient.lastname}` }}
      </p>
      <div class="sp__2x" />
      <Badge
        v-if="isClinic && getRequest.owner"
        variant="secondary"
      >
        {{ getRequest.owner }}
      </Badge>
    </div>
    <p class="RequestHeader__subtitle text__sr">
      {{ `${$i18n.t('lb_instance_number')} ${getRequest.caseNumber}` }}
    </p>
    <button
      v-if="showClose"
      @click.prevent="handleCloseNotification"
      class="RequestHeader__closeButton">
      <img src="@/assets/actions/close.svg" />
    </button>
  </div>
</template>

<script>
import Badge from '@/components/atoms/Badge/Badge.vue';
import defaultRequest from '@/views/Request/defaultRequest';

export default {
  props: {
    showClose: {
      type: Boolean,
      default: false,
    },
    request: {
      type: Object,
      default: () => defaultRequest,
    },
  },
  methods: {
    handleCloseNotification() {
      this.$emit('handleCloseNotification');
    },
  },
  computed: {
    isClinic() {
      return this.$store.getters['user/isClinic'];
    },

    getRequest() {
      return this.request;
    },
  },
  components: {
    Badge,
  },
};
</script>

<style lang="sass" scoped>
  @import './RequestHeader.sass'
</style>
