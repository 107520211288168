<template>
  <b-form-textarea
    class="Textarea"
    :id="`${id}-textarea`"
    v-model="text"
    :placeholder="placeholder"
    @change.native="changeSelection"
    :state="isValid"
    :rows="rows"
    :max-rows="maxRows"
    no-resize
    :disabled="isDisabled"
  />
</template>

<script>
export default {
  props: {
    id: String,
    value: {
      type: String,
      default: null,
    },
    placeholder: String,
    isValid: {
      type: Boolean,
      default: null,
    },
    rows: {
      type: String,
      default: '4',
    },
    maxRows: {
      type: String,
      default: '4',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: this.value,
    };
  },
  methods: {
    changeSelection(e) {
      this.text = e.target.value !== '' ? e.target.value : null;
      this.$emit('handlerChange', this.text);
    },
  },
  watch: {
    value(newValue) {
      this.text = newValue;
    },
  },
};
</script>
