<template>
  <div>
    <b-form>
      <b-form-group
        class="text__psb"
        :id="`${id}-group`"
        :label="label"
        :label-for="`${id}-textarea`"
      >
        <Textarea
          :id="`${id}-textarea`"
          :value="value"
          :placeholder="placeholder"
          :label="label"
          :error="error"
          :isValid="isValid"
          :help="help"
          :rows="rows"
          :max-rows="maxRows"
          @handlerChange="handlerChange"
        />
        <b-form-invalid-feedback
          v-if="isValid === false"
          class="text__sr"
        >
          {{ error }}
        </b-form-invalid-feedback>
        <b-form-text
          v-if="showHelp"
          class="text__sr"
        >
          {{ help }}
        </b-form-text>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import Textarea from '@/components/atoms/Textarea/Textarea.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    isValid: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    help: {
      type: String,
      default: '',
    },
    rows: {
      type: String,
      default: '4',
    },
    maxRows: {
      type: String,
      default: '4',
    },
  },
  methods: {
    handlerChange(text) {
      this.$emit('handlerTextareaChange', text);
    },
  },
  computed: {
    showHelp() {
      return this.help.length > 0;
    },
  },
  components: {
    Textarea,
  },
};
</script>
