<template>
  <div class="ForgotPasswordView">
    <div class="ForgotPasswordView__forgotPasswordFormContainer">
      <ForgotPasswordForm />
    </div>
    <div class="ForgotPasswordView__imageContainer" />
  </div>
</template>

<script>
import ForgotPasswordForm from '@/components/organisms/ForgotPassword/ForgotPasswordForm.vue';

export default {
  name: 'ForgotPasswordView',
  components: {
    ForgotPasswordForm,
  },
};
</script>

<style lang="sass" scoped>
@import './ForgotPasswordView.sass'
</style>
