<template>
  <private-template>
    <template v-slot:private-template>
      <div class="sp__v__2x"/>
      <div>
        <RequestHeadingCards
          :title="$i18n.t('cp_request_inbox_title')"
          :text="$i18n.t('cp_request_inbox_text',
          { name: $store.getters['user/getCurrentUser'].name })"
          :kpis="kpis"
          :isLoading="isLoading"
          @handleSelectInterval="loadReports"
        />
        <div class="sp__v__3x"/>
        <div
          v-if="!isDoctor"
          class="buttonContainer"
        >
          <b-button
            @click.prevent="showCreateView"
            variant="primary"
            size="sm"
          >
             {{ `+ ${$i18n.t('btn_new_f')}` }}
          </b-button>
        </div>
        <div class="sp__v__2x"/>
        <TableGroup
          id="request"
          :items="requestList"
          :isLoading="isTableLoading"
          :pagination="pagination"
          :searchText="searchText"
          @handleSearch="handleSearchByInput"
          @handleSearchByFilter="handleSearchByFilter"
          @handlePaginate="handleFilterSearch"
          @handleCleanSearch="handleCleanSearch"
        >
          <template v-slot:content>
            <RequestFilters
              @handleChangeCreatedAt="handleChangeCreatedAt"
              @handleChangeOrigin="handleChangeOrigin"
              @handleChangeSpecialty="handleChangeSpecialty"
              @handleChangeClinic="handleChangeClinic"
              @handleChangeStage="handleChangeStage"
              @handleChangeStatus="handleChangeStatus"
              :filters="filters"
            />
          </template>

          <RequestTable
            ref="requestTable"
            :requestItems="requestList"
            :canDelete="!isDoctor"
            canEdit
            :canCopy="!isDoctorOrClinic"
            :isFormShowed="showDetail"
            :showActions="!isDoctor"
            @showUpdateForm="showUpdateForm"
            @showCopyForm="showCopyForm"
            @showDeleteModal="showDeleteModal"
            @handleSelectRow="handleSelectRow"
            @handleSortAsc="handleSortAsc"
            @handleSortDesc="handleSortDesc"
          />
        </TableGroup>
      </div>
        <RequestDetail
          :show="showDetail"
          id="request-detail"
          :request="request"
          :isDoctorOrClinic="isDoctorOrClinic"
          @handleChangeRequest="handleChangeRequest"
          @handleCancel="closeDetail"
        />
        <CreateRequest
          :show="showCreateRequest"
          id="create-request"
          @addRequest="addRequest"
          @handleCancel="closeCreateModal"
        />
        <UpdateRequest
          id="update-request"
          @updateRequest="updateRequest"
          :request="request"
        />
        <CopyRequest
          id="copy-request"
          @copyRequest="copyRequest"
          @handleCancel="handleRevertEditedRowRequest"
          :request="request"
        />
        <DeleteRequest
          @deleteRequest="deleteRequest"
          ref="deleteRequest"
        />
    </template>
  </private-template>
</template>

<script>
import PrivateTemplate from '@/components/organisms/PrivateTemplate.vue';
import RequestHeadingCards from '@/components/molecules/Request/RequestHeadingCards.vue';
import CreateRequest from '@/components/organisms/Request/CreateRequest.vue';
import UpdateRequest from '@/components/organisms/Request/UpdateRequest.vue';
import CopyRequest from '@/components/organisms/Request/CopyRequest.vue';
import TableGroup from '@/components/molecules/Tables/TableGroup/TableGroup.vue';
import RequestTable from '@/components/molecules/Tables/RequestTable/RequestTable.vue';
import DeleteRequest from '@/components/organisms/Request/DeleteRequest.vue';
import RequestFilters from '@/components/organisms/Request/RequestFilters.vue';
import RequestDetail from '@/components/organisms/Request/RequestDetail.vue';
import {
  getSortType,
  SORT_TYPE,
  STATUS,
  KPIS,
} from '@/helpers/ComponentHelper';
import defaultRequest from './defaultRequest';
import { getOrdenation, getFiltersGroup } from '@/helpers/ApiHelper';

export default {
  data() {
    return {
      requestList: [],
      request: defaultRequest,
      showDetail: false,
      filters: {
        createdAt: null,
        origin: null,
        specialty: null,
        clinic: null,
        stage: null,
        status: null,
      },
      isTableLoading: false,
      searchText: null,
      sort: {
        name: 'createdAt',
        type: 'desc',
      },
      pagination: {},
      showCreateRequest: false,
      oldEditedRow: {
        id: null,
        notes: [],
        documents: [],
      },
      kpis: [],
      isLoading: false,
    };
  },
  created() {
    this.handleSearch();
    this.loadReports();
  },
  methods: {
    loadReports(interval) {
      this.isLoading = true;

      this.$store.dispatch('kpi/getAllKpi', interval)
        .then((res) => {
          this.getPatientKpi(res.kpiList);

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getPatientKpi(list) {
      const newList = list.filter((kpi) => kpi.name.toLowerCase() !== 'total pacientes');

      const updatedList = newList.map((el) => {
        const kpi = {
          ...el,
          label: this.translateNames(el.name),
        };

        return kpi;
      });

      this.kpis = updatedList;
    },

    translateNames(name) {
      const newName = KPIS.filter((el) => el.value === name.toLowerCase());

      return newName[0].text;
    },

    handleRevertEditedRowRequest() {
      for (let i = 0; i < this.requestList.length; i += 1) {
        if (this.requestList[i].id === this.oldEditedRow.id) {
          this.requestList[i].notes = this.oldEditedRow.notes;
          this.requestList[i].documents = this.oldEditedRow.documents;

          break;
        }
      }

      this.oldEditedRow.id = null;
      this.oldEditedRow.notes = [];
      this.oldEditedRow.documents = [];
    },

    handleSelectRow(item) {
      this.setRequest(item);

      if (!this.showDetail) {
        this.$root.$emit('bv::toggle::collapse', 'request-detail-sidebar');
      }

      this.showDetailView();
    },

    showUpdateForm(data) {
      this.setRequest(data);

      this.$root.$emit('bv::toggle::collapse', 'update-request-sidebar');
    },

    showCopyForm(data) {
      this.oldEditedRow.id = data.id;

      for (let i = 0; i < data.documents.length; i += 1) {
        const newOldDocuments = data.documents[i];
        this.oldEditedRow.documents.push(newOldDocuments);
      }

      for (let i = 0; i < data.notes.length; i += 1) {
        this.oldEditedRow.notes.push(data.notes[i]);
      }

      this.setRequest(data, false);

      this.$root.$emit('bv::toggle::collapse', 'copy-request-sidebar');
    },

    showDetailUpdateForm(data) {
      this.setRequest(data);

      this.$bvModal.show('update-request');
    },

    showDeleteModal(data) {
      this.$refs.deleteRequest.setId(data.id);
      this.$bvModal.show('delete-request');
    },

    showDetailDeleteModal() {
      this.$refs.deleteRequest.setId(this.request.id);
      this.$bvModal.show('delete-request');
    },

    showCreateView() {
      this.showCreateRequest = true;
      this.$root.$emit('bv::toggle::collapse', 'create-request-sidebar');
    },

    closeDetail() {
      this.hideDetailView();
      this.$refs.requestTable.clearSelected();
    },

    closeCreateModal() {
      this.showCreateRequest = false;
    },

    closeCreateModalAfterAction() {
      this.closeCreateModal();
      this.$root.$emit('bv::toggle::collapse', 'create-request-sidebar');
    },

    closeUpdateModalAfterAction() {
      this.$root.$emit('bv::toggle::collapse', 'update-request-sidebar');
    },

    showDetailView() {
      this.showDetail = true;
    },

    hideDetailView() {
      this.showDetail = false;
    },

    addData(data) {
      this.requestList.unshift(data);
      const updatedRequestList = this.removeDuplicates(this.requestList[0].documents);
      this.requestList.documents = updatedRequestList;
      this.handleRevertEditedRowRequest();
      this.incrementPagination();
    },

    removeDuplicates(list) {
      return [...new Set(list)];
    },

    clearDocumentsAndNotes() {
      defaultRequest.documents = [];
      defaultRequest.notes = [];
    },

    incrementPagination() {
      this.pagination.totalItems += 1;
      this.pagination.itemsPerPage += 1;
    },

    addRequest(data) {
      this.addData(data);
      this.loadReports();
      this.clearDocumentsAndNotes();
      this.closeCreateModalAfterAction();
    },

    copyRequest(data) {
      this.addData(data);
      this.showCopyForm(data);
    },

    updateRequest(data) {
      for (let i = 0; i < this.requestList.length; i += 1) {
        if (this.requestList[i].id === data.id) {
          const newRequest = {
            id: data.id,
            caseNumber: data.caseNumber,
            clinic: data.clinic,
            createdAt: data.createdAt,
            documents: data.documents,
            level: data.level,
            healthManager: data.healthManager,
            instance: data.instance,
            hasInsurance: data.hasInsurance,
            medicalProcedure: data.medicalProcedure,
            notes: data.notes,
            pathology: data.pathology,
            patient: data.patient,
            hasRecentReports: data.hasRecentReports,
            specialty: data.specialty,
            requestStage: data.requestStage,
            status: data.status,
            owner: data.owner,
            accepted: data.accepted,
            doctor: data.doctor,
          };

          this.requestList.splice(i, 1);
          this.requestList.splice(i, 0, newRequest);
          break;
        }
      }

      this.$root.$emit('bv::toggle::collapse', 'update-request-sidebar');
    },

    deleteRequest(id) {
      this.$bvModal.hide('delete-request');

      if (this.showDetail) {
        this.hideDetailView();
      }

      for (let i = 0; i < this.requestList.length; i += 1) {
        if (this.requestList[i].id === id) {
          this.requestList.splice(i, 1);
          this.handleSearch(this.pagination.currentPage);
          break;
        }
      }
    },

    handleSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('request/getAllRequest', page)
        .then((res) => {
          this.requestList = res.requestList;
          this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },

    getFilters() {
      const filtersGroup = [];

      if (this.searchText !== null) {
        if (!this.checkText()) {
          filtersGroup.push(
            `name:${this.searchText}`,
          );
        } else {
          filtersGroup.push(
            `caseNumber:${this.searchText}`,
          );
        }
      }

      return getFiltersGroup(filtersGroup, this.filters);
    },

    checkText() {
      return /\d/.test(this.searchText);
    },

    getRequestData(page = 1) {
      return {
        page,
        filters: this.getFilters(),
        ordenation: getOrdenation(this.sort),
      };
    },

    handleFilterSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('request/filterRequest', this.getRequestData(page))
        .then((res) => {
          this.requestList = res.requestList;
          this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },

    handleSearchByInput(text) {
      if (text !== null) {
        this.searchText = text;
        this.handleFilterSearch();
      } else {
        this.searchText = null;
      }
    },

    handleSearchByFilter() {
      this.handleFilterSearch();
      this.$root.$emit('bv::toggle::collapse', 'request-sidebar');
    },

    handleSortAsc(param) {
      this.sort.type = this.sort.name === param ? getSortType(this.sort.type) : SORT_TYPE.ASC;
      this.sort.name = param;

      this.handleFilterSearch();
    },

    handleSortDesc(param) {
      this.sortDesc = param;
      this.handleSearch();
    },

    handleChangeCreatedAt(value) {
      this.filters.createdAt = value;
    },

    handleChangeOrigin(value) {
      this.filters.origin = value;
    },

    handleChangeSpecialty(value) {
      this.filters.specialty = value;
    },

    handleChangeClinic(value) {
      this.filters.clinic = value;
    },

    handleChangeStage(value) {
      this.filters.stage = value;
    },

    handleChangeStatus(value) {
      this.filters.status = value;
    },

    getNewRequest(data, hasClinic = true) {
      const newRequest = {
        id: data.id,
        caseNumber: data.caseNumber,
        instance: data.instance,
        level: data.level,
        hasInsurance: data.hasInsurance,
        hasRecentReports: data.hasRecentReports,
        patient: data.patient,
        createdAt: data.createdAt,
        specialty: data.specialty,
        pathology: data.pathology,
        medicalProcedure: data.medicalProcedure,
        clinic: hasClinic ? data.clinic : null,
        doctor: data.doctor,
        requestStage: data.requestStage,
        status: data.status,
        healthManager: data.healthManager,
        documents: [...data.documents],
        notes: [...data.notes],
        owner: data.owner,
        accepted: data.accepted,
      };

      return newRequest;
    },

    setRequest(data, hasClinic = true) {
      this.request = this.getNewRequest(data, hasClinic);
    },

    handleChangeRequest(id, status) {
      if (status === STATUS.ACCEPTED.key) {
        this.changeRequestStatus(id, status, true);
      } else {
        this.changeRequestStatus(id, status, false);
      }

      this.hideDetailView();
      this.$root.$emit('bv::toggle::collapse', 'request-detail-sidebar');
    },

    changeRequestStatus(id, status, isAccepted) {
      for (let i = 0; i < this.requestList.length; i += 1) {
        if (this.requestList[i].id === id) {
          const newRequest = {
            id: this.requestList[i].id,
            clinic: this.requestList[i].clinic,
            createdAt: this.requestList[i].createdAt,
            documents: this.requestList[i].documents,
            level: this.requestList[i].level,
            healthManager: this.requestList[i].healthManager,
            caseNumber: this.requestList[i].caseNumber,
            hasInsurance: this.requestList[i].hasInsurance,
            medicalProcedure: this.requestList[i].medicalProcedure,
            notes: this.requestList[i].notes,
            pathology: this.requestList[i].pathology,
            patient: this.requestList[i].patient,
            hasRecentReports: this.requestList[i].hasRecentReports,
            specialty: this.requestList[i].specialty,
            requestStage: this.requestList[i].requestStage,
            status,
            owner: this.requestList[i].owner,
            accepted: isAccepted,
          };

          this.requestList.splice(i, 1);
          this.requestList.splice(i, 0, newRequest);
          break;
        }
      }
    },

    handleCleanSearch() {
      this.filters.createdAt = null;
      this.filters.origin = null;
      this.filters.specialty = null;
      this.filters.clinic = null;
      this.filters.stage = null;
      this.filters.status = null;
      this.searchText = null;
      this.sort.name = null;
      this.sort.type = null;

      this.handleSearch();
    },
  },
  computed: {
    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },

    isClinic() {
      return this.$store.getters['user/isClinic'];
    },

    isDoctorOrClinic() {
      return this.isDoctor || this.isClinic;
    },
  },
  components: {
    PrivateTemplate,
    RequestHeadingCards,
    CreateRequest,
    UpdateRequest,
    CopyRequest,
    TableGroup,
    RequestTable,
    DeleteRequest,
    RequestFilters,
    RequestDetail,
  },
};
</script>
