import HealthManagerApi from '../../domain/healthManager/HealthManagerApi';

export function getAllHealthManager({ commit }, currentPage) {
  const healthManagerApi = new HealthManagerApi();
  const getAllRequest = healthManagerApi.getAllHealthManager(currentPage);

  return getAllRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function filterHealthManager({ commit }, data) {
  const healthManagerApi = new HealthManagerApi();
  const filterRequest = healthManagerApi.filterHealthManager(data);

  return filterRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function searchHealthManager({ commit }, data) {
  const healthManagerApi = new HealthManagerApi();
  const searchRequest = healthManagerApi.searchHealthManager(data);

  return searchRequest
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function createHealthManager({ commit }, data) {
  const healthManagerApi = new HealthManagerApi();
  const createRequest = healthManagerApi.createHealthManager(data);

  return createRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function updateHealthManager({ commit }, data) {
  const healthManagerApi = new HealthManagerApi();
  const updateRequest = healthManagerApi.updateHealthManager(data);

  return updateRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function deleteHealthManager({ commit }, id) {
  const healthManagerApi = new HealthManagerApi();
  const deleteRequest = healthManagerApi.deleteHealthManager(id);

  return deleteRequest
    .then()
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
