<template>
  <div class="LoginView">
    <div class="LoginView__loginFormContainer">
      <LoginForm
        :isUserVerification="userVerification"
        :verificationToken="verificationToken"
        :isLoginForm="false"
      />
    </div>
    <div class="LoginView__imageContainer" />
  </div>
</template>

<script>
import LoginForm from '@/components/organisms/Login/LoginForm.vue';

export default {
  name: 'UserVerification',
  components: {
    LoginForm,
  },
  data: () => ({
    userVerification: true,
    verificationToken: null,
  }),
  created() {
    this.getVerificationToken();
  },
  methods: {
    getVerificationToken() {
      const routeParam = this.$route.params.token;
      this.verificationToken = !this.isEmpty(routeParam) ? routeParam : null;
    },
    isEmpty(data) {
      return Object.keys(data).length === 0;
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/views/LoginView/LoginView.sass'
</style>
