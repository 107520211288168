export default class ClinicModel {
  constructor(clinicData) {
    this.id = clinicData.id;
    this.city = clinicData.city;
    this.contactName = clinicData.contactName;
    this.credentials = clinicData.credentials;
    this.description = clinicData.description;
    this.googleMyBusinessAccount = clinicData.googleMyBusinessAccount;
    this.isEnabled = clinicData.isEnabled;
    this.name = clinicData.name;
    this.patients = clinicData.patients;
    this.phone = clinicData.phone;
    this.requests = clinicData.requests;
    this.totalRequests = clinicData.totalRequests;
    this.email = clinicData.email;
    this.avatar = clinicData.avatar;
    this.yearFoundation = clinicData.yearFoundation;
    this.insurances = clinicData.insurances;
    this.hasInsurances = clinicData.hasInsurances;
  }
}
