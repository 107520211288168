<template>
  <div class="Table">
    <b-table
      :items="this.requestItems"
      :fields="getFields"
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
      ref="requestTableComponent"
    >
      <template #cell(createdAt)="data">
        <span class="text__sr text-secondary">{{ data.item.createdAt | formatDate }}</span>
      </template>
      <template #cell(name)="data">
        <span class="text__sr text-secondary">{{
          data.item.patient ? data.item.patient.name : null
        }}</span>
      </template>
      <template #cell(origin)="data">
        <span v-if="!isPatientRequestView" class="text__sr text-secondary">{{
          getOrigin(data.item.patient ? data.item.patient.origin : null)
        }}</span>
      </template>
      <template #cell(specialty)="data">
        <span class="text__sr text-secondary">{{
          data.item.specialty ? data.item.specialty.name : null
        }}</span>
      </template>
      <template #cell(medicalProcedure)="data">
        <span class="text__sr text-secondary">{{ data.item.medicalProcedure }}</span>
      </template>
      <template #cell(clinic)="data">
        <IconGroup
          v-if="data.item.clinic"
          :icon="getClinicAvatar(data.item.clinic)"
          :label="data.item.clinic ? data.item.clinic.name : null"
        />
      </template>
      <template #cell(doctor)="data">
        <IconGroup
          v-if="data.item.doctor"
          :icon="getDoctorAvatar(data.item.doctor)"
          :label="data.item.doctor && data.item.doctor.name
            ? data.item.doctor.name
            : data.item.doctor"
        />
      </template>
      <template #cell(requestStage)="data">
        <span class="text__sr text-secondary">
          {{ getRequestStage(data.item.requestStage) }}
        </span>
      </template>
      <template #cell(status)="data">
        <Badge
          v-if="data.item.status"
          :variant="data.item.status"
        >
          {{ data.item.status | getVariantByStatus }}
        </Badge>
      </template>
      <template #cell(actions)="data">
        <IconActionsGroup
          v-if="!(isClinic && data.item.status !== status.ACCEPTED.key)"
          @edit="showUpdateForm"
          @copy="showCopyForm"
          @delete="showDeleteModal"
          :objectData="data.item"
          :canDelete="canDelete"
          :canEdit="canEdit"
          :canCopy="canCopy"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import IconActionsGroup from '@/components/atoms/Icon/IconActionsGroup/IconActionsGroup.vue';
import IconGroup from '@/components/atoms/Icon/IconGroup/IconGroup.vue';
import Badge from '@/components/atoms/Badge/Badge.vue';
import fields from './RequestTableFields';
import { getImageFormatted, STAGE_OPTIONS, STATUS } from '@/helpers/ComponentHelper';
import { getAvatarByPathRequest } from '@/helpers/UserHelper';

export default {
  props: {
    requestItems: {
      type: Array,
      default: () => [],
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canCopy: {
      type: Boolean,
      default: true,
    },
    isPatientRequestView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortBy: 'case',
      sortDesc: false,
      fields,
      status: STATUS,
    };
  },
  methods: {
    onRowSelected(items) {
      if (items.length > 0) {
        this.$emit('handleSelectRow', items[0]);
      }
    },

    showUpdateForm(data) {
      this.$emit('showUpdateForm', data);
    },

    showCopyForm(data) {
      this.$emit('showCopyForm', data);
    },

    showDeleteModal(data) {
      this.$emit('showDeleteModal', data);
    },

    getImage(image) {
      return getImageFormatted(image);
    },

    clearSelected() {
      this.$refs.requestTableComponent.clearSelected();
    },

    getOrigin(origin) {
      return origin !== null ? origin.name : null;
    },

    getClinicAvatar(clinic) {
      return getAvatarByPathRequest(clinic);
    },

    getDoctorAvatar(doctor) {
      return getAvatarByPathRequest(doctor);
    },

    getRequestStage(data) {
      if (!data) return null;

      let stageName;

      if (typeof data === 'string') {
        const stageOption = STAGE_OPTIONS.filter((stage) => stage.value === data);
        stageName = stageOption[0].text;

        return stageName;
      }

      const stageOption = STAGE_OPTIONS.filter((stage) => stage.value === data.id);
      stageName = stageOption[0].text;

      return stageName;
    },
  },
  computed: {
    getFields() {
      let newFields;

      if (this.isDoctor || this.isClinic) {
        newFields = this.fields.filter((el) => el.key !== 'clinic');

        return newFields;
      }

      if (!(this.isDoctor || this.isClinic)) {
        newFields = this.fields.filter((el) => el.key !== 'doctor');

        return newFields;
      }

      if (this.showActions) {
        newFields.push({ key: 'actions', label: '' });
      }

      return newFields;
    },

    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },

    isClinic() {
      return this.$store.getters['user/isClinic'];
    },
  },
  watch: {
    sortBy(newValue) {
      this.$emit('handleSortAsc', newValue);
    },

    sortDesc(newValue) {
      if (newValue === true) {
        this.$emit('handleSortDesc', this.sortBy);
      } else {
        this.$emit('handleSortAsc', this.sortBy);
      }
    },
  },
  components: {
    IconActionsGroup,
    IconGroup,
    Badge,
  },
};
</script>

<style lang="sass" scoped>
@import '../Table.sass'
</style>
