import HealthManagerModel from './HealthManagerModel';
import PaginationFactory from '../pagination/PaginationFactory';
import MarketModel from '../market/MarketModel';
import UserModel from '../user/UserModel';

class HealthManagerFactory {
  constructor() {
    this.healthManager = {};
  }

  getAllHealthManager(data) {
    const healthManagerResponse = {
      healthManagerList: this.getCollection(data?.collection),
      pagination: PaginationFactory.getPagination(data?.pagination),
    };

    return healthManagerResponse;
  }

  getHealthManager(healthManager) {
    const market = new MarketModel({
      id: healthManager.market.id,
      name: healthManager.market.name,
    });

    const user = new UserModel({
      id: healthManager.user.id,
      name: healthManager.user.name,
      email: healthManager.user.email,
      roles: healthManager.user.roles,
      avatar: healthManager.user.avatar,
    });

    this.healthManager = new HealthManagerModel({
      id: healthManager.id,
      lastname: healthManager.lastname,
      market,
      name: healthManager.name,
      patients: healthManager.patients,
      phone: healthManager.phone,
      requests: healthManager.requests,
      email: user?.email,
      avatar: user?.avatar,
    });

    return this.healthManager;
  }

  getCollection(healthManagerCollection) {
    const healthManagerList = healthManagerCollection.map(
      (healthManager) => this.getHealthManager(healthManager),
    );

    return healthManagerList;
  }
}

export default new HealthManagerFactory();
