<template>
  <ModalAction
    :id="id"
    :okLabel="$i18n.t('btn_save')"
    :cancelLabel="$i18n.t('btn_cancel')"
    :isLoading="isLoading"
    @handleOk="handleOk"
    @handleCancel="handleClose"
    isForm
  >
    <b-form>
      <b-row>
        <b-col>
          <p class="text__formTitle">{{ title }}</p>
        </b-col>
      </b-row>

      <div class="sp__v__1d5x" />

      <b-row>
        <b-col>
          <p class="text__psb">{{ $i18n.t('lb_logo')}}</p>
          <div class="sp__v" />
          <FileUploader
            @handleSetFile="handleChangeImage"
            isImage
            :image="this.getAvatar"
          />
          <div class="sp__v__1s5x" />
          <p class="text__sr text-secondary">{{ $i18n.t('cp_file_uploader_help') }}</p>
        </b-col>
      </b-row>

      <div class="sp__v__1d5x" />

      <b-row>
        <b-col class="col-6">
          <InputGroup
            id="userFormName"
            :label="$i18n.t('lb_name')"
            :placeholder="$i18n.t('lb_type_something')"
            @handlerInputChange="handleChangeName"
            :isValid="this.validationState.name"
            error="No puede estar vacio"
            :value="this.userData.name"
          />
        </b-col>
      </b-row>

      <div class="sp__v__1d5x" />

      <b-row>
        <b-col>
          <p class="text__formTitle">{{ $i18n.t('lb_contact_data')}}</p>
        </b-col>
      </b-row>
      <div class="sp__v__1d5x" />
      <b-row>
        <b-col class="col-6">
          <InputGroup
            id="userFormEmail"
            :label="$i18n.t('lb_email')"
            :placeholder="$i18n.t('lb_type_email')"
            @handlerInputChange="handleChangeEmail"
            :isValid="this.validationState.email"
            error="No puede estar vacio"
            :value="this.userData.email"
          />
        </b-col>
        <b-col class="col-6">
          <InputGroup
            id="userFormPhone"
            :label="$i18n.t('lb_phone')"
            :placeholder="$i18n.t('lb_type_phone')"
            @handlerInputChange="handleChangePhone"
            :error="$i18n.t('val_phone')"
            :value="this.userData.phone"
            type="tel"
          />
        </b-col>
      </b-row>
      <div class="sp__v" />
      <hr />
      <div class="sp__v" />
      <b-row>
        <b-col class="col-6">
          <PasswordChange />
        </b-col>
      </b-row>
    </b-form>
  </ModalAction>
</template>

<script>
import {
  getValidationState,
  isValidForm,
  sanitizeEmail,
} from '@/helpers/ValidationHelper';
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import FileUploader from '@/components/atoms/User/FileUploader/FileUploader.vue';
import PasswordChange from '@/components/molecules/PasswordChange/PasswordChange.vue';
import {
  getAvatarFile,
  getAvatarBase64,
  logout,
} from '@/helpers/UserHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
    },
  },
  data() {
    return {
      userData: {
        id: this.user?.uuid,
        name: this.user?.name,
        role: this.user?.role,
        email: this.user?.email,
        phone: this.user?.phone,
        avatar: this.user?.avatar,
      },
      validationState: {
        name: null,
        email: null,
      },
      userEmail: null,
      isLoading: false,
    };
  },
  mounted() {
    this.userEmail = this.user?.email;
  },
  methods: {
    updateUser() {
      this.isLoading = true;

      const requestData = {
        ...(this.getAvatarBase(this.userData.avatar)),
        name: this.userData.name,
        email: this.userData.email,
        phone: this.userData.phone,
      };

      this.$store.dispatch('user/updateUser', requestData)
        .then(() => {
          this.isLoading = false;
          this.$emit('handleOk', this.userData);
          this.logoutUser();
        });
    },

    handleOk() {
      this.setSanitizedEmail();

      const nameValidationState = getValidationState(
        this.userData.name,
      );

      const emailValidationState = getValidationState(
        this.userData.email,
      );

      if (isValidForm([
        nameValidationState,
        emailValidationState,
      ])) {
        this.updateUser();
      } else {
        this.validationState.name = nameValidationState;
        this.validationState.email = emailValidationState;
      }
    },

    handleClose() {
      this.$emit('handleClose');
    },

    handleChangeImage(file) {
      this.userData.avatar = file;
    },

    handleChangeName(text) {
      this.userData.name = text;
    },

    handleChangeEmail(text) {
      this.userData.email = text;
    },

    handleChangePhone(text) {
      this.userData.phone = text;
    },

    getAvatarBase(avatar) {
      return getAvatarBase64(avatar);
    },

    logoutUser() {
      if (this.userEmail !== this.userData.email) {
        logout();
      }
    },

    setSanitizedEmail() {
      const sanitizedEmail = sanitizeEmail(this.userData.email);
      this.userData.email = sanitizedEmail;
    },
  },
  computed: {
    getAvatar() {
      return getAvatarFile(this.userData?.avatar);
    },
  },
  watch: {
    user(newValue) {
      this.userData.id = newValue.id;
      this.userData.avatar = newValue.avatar;
      this.userData.name = newValue.name;
      this.userData.email = newValue.email;
      this.userData.phone = newValue.phone;
    },
  },
  components: {
    ModalAction,
    InputGroup,
    FileUploader,
    PasswordChange,
  },
};
</script>
