import AuthAPI from '../../domain/auth/AuthApi';

export function login({ commit }, credentials) {
  const authApi = new AuthAPI();
  const jwtAuthApi = authApi.getJWTToken(credentials);

  return jwtAuthApi
    .then((res) => {
      commit('logUser', res);
      return res;
    })
    .catch((error) => {
      commit('error/setLoginError', error, { root: true });
      return Promise.reject(error);
    });
}

export function verifyUser({ commit }, data) {
  const authApi = new AuthAPI();
  const userVerificationApi = authApi.verifyUser(data);

  return userVerificationApi
    .then((res) => {
      commit('logUser', res);
      return res;
    })
    .catch((error) => {
      commit('error/setLoginError', error, { root: true });
      return Promise.reject(error);
    });
}

export function userPasswordReset({ commit }, data) {
  const authApi = new AuthAPI();
  const userPasswordResetApi = authApi.passwordReset(data);

  return userPasswordResetApi
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}

export function userPasswordUpdate({ commit }, data) {
  const authApi = new AuthAPI();
  const userPasswordUpdateApi = authApi.passwordUpdate(data);

  return userPasswordUpdateApi
    .then((res) => {
      commit('logUser', res);
      return res;
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
