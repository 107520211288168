<template>
  <div
    :class="
      this.isImage ?
      'FileUploader FileUploader--isImage' :
      'FileUploader'"
  >
    <div
      :class="
        this.isImage ?
        'FileUploader__zone FileUploader__zone--isImage' :
        'FileUploader__zone'"
      >
      <b-form-file
        v-model="file"
        :state="Boolean(file)"
        placeholder=""
        drop-placeholder=""
      >
      </b-form-file>
      <div
        v-if="!this.isFileLoaded"
        class="FileUploader__info"
      >
        <img src="@/assets/components/upload.svg" />
        <div
          v-if="!this.isImage"
          class="sp__v__1s5x"
        />
        <p
          :class="
            this.isImage ?
            'FileUploader__text--isImage text__psb' :
            'FileUploader__text text__psb'"
        >
          <span class="text-primary">
            {{ $i18n.t('cp_upload_a_file') }}
          </span> {{ $i18n.t('cp_or_drag') }} {{ this.file }}
        </p>
      </div>
      <div
        :style="`background-image: url(${getImage});`"
        class="FileUploader__preview"
        v-if="true"
      />
    </div>
    <div class="sp__v"/>
    <b-button
      :class="
        this.isImage ?
        'FileUploader__button--isImage' :
        'FileUploader__button'"
      @click.prevent="uploadFile"
      :disabled="this.file === null"
      variant="secondary"
    >
      {{ $i18n.t('btn_save') }}
    </b-button>
  </div>
</template>

<script>
import { getImageFormatted } from '@/helpers/ComponentHelper';
import { getBase64Content, getExtension } from '@/helpers/UserHelper';
/* eslint-disable vue/no-async-in-computed-properties */

export default {
  props: {
    isImage: {
      type: Boolean,
      default: false,
    },
    image: {
      type: [String, File],
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    uploadFile() {
      const newFile = {
        file: this.file,
        base64: null,
      };

      this.getBase64().then(
        (data) => {
          const base64 = {
            encodedFile: getBase64Content(data),
            extension: getExtension(this.file.name),
          };

          newFile.base64 = base64;
          this.$emit('handleUploadFile', newFile);
        },
      );
    },

    getBase64() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
  computed: {
    isFileLoaded() {
      return this.file !== null;
    },

    getImage() {
      if (this.file) {
        return URL.createObjectURL(this.file);
      }

      return getImageFormatted(this.image);
    },
  },
  watch: {
    file() {
      const newFile = {
        file: this.file,
        base64: null,
      };

      if (!this.isImage) {
        this.$emit('handleSetFile', newFile);
      } else {
        this.getBase64().then(
          (data) => {
            const avatar = {
              encodedImage: getBase64Content(data),
              extension: getExtension(this.file.name),
            };

            newFile.base64 = avatar;
            this.$emit('handleSetFile', newFile);
          },
        );
      }
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './FileUploader.sass'
</style>
