import InsuranceAPI from '../../domain/insurance/InsuranceApi';

export default function getAllInsurance({ commit }, data) {
  const insuranceApi = new InsuranceAPI();
  const getAllRequest = insuranceApi.getAllInsurance(data);

  return getAllRequest
    .then((res) => {
      commit('insurance/setInsurances', res, { root: true });
      return res;
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
