<template>
  <div id="salesforceChat"></div>
</template>

<script>
export default {
  name: 'SalesforceChat',
  methods: {
    initESW(gslbBaseURL) {
      window.embedded_svc.settings.displayHelpButton = true; // Or false
      window.embedded_svc.settings.language = 'es-ES'; // For example, enter 'en' or 'en-US'

      window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      window.embedded_svc.settings.entryFeature = 'LiveAgent';

      window.embedded_svc.init(
        'https://vidasprime123--uat.my.salesforce.com',
        'https://uat-chatvidasprime.cs102.force.com/liveAgentSetupFlow',
        gslbBaseURL,
        '00D1j0000008dnT',
        'Chat_Vidas_Prime',
        {
          baseLiveAgentContentURL: 'https://c.la1-c1cs-fra.salesforceliveagent.com/content',
          deploymentId: '5721j000000CbL5',
          buttonId: '5731j000000CbL0',
          baseLiveAgentURL: 'https://d.la1-c1cs-fra.salesforceliveagent.com/chat',
          eswLiveAgentDevName: 'Chat_Vidas_Prime',
          isOfflineSupportEnabled: false,
        },
      );
    },
  },
  mounted() {
    const self = this;
    const chatScript = document.createElement('script');
    chatScript.setAttribute('src', 'https://service.force.com/embeddedservice/5.0/esw.min.js');
    chatScript.async = true;
    chatScript.defer = true;
    document.head.appendChild(chatScript);
    // eslint-disable-next-line func-names
    chatScript.onload = function () {
      if (!window.embedded_svc) {
        const s = document.createElement('script');
        s.setAttribute('src', 'https://vidasprime123--uat.my.salesforce.com/embeddedservice/5.0/esw.min.js');
        // eslint-disable-next-line func-names
        s.onload = function () {
          self.initESW(null);
        };
        document.body.appendChild(s);
      } else {
        self.initESW('https://service.force.com');
      }
    };
  },
};
</script>

<style scoped>
  .embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #005290;
    font-family: "Arial", sans-serif;
  }
  .embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: 1px solid #005290;
  }
</style>
