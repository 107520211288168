<template>
  <div>
    <div class="sp__v__3x"/>
      <HeadingBar
        :title="$i18n.t('lb_request')"
        :text="$i18n.t('cp_patient_request_text')"
        :isSmall="true"
      />

      <hr />
      <div class="sp__v"/>

      <TableGroup
        id="request"
        :items="getItems"
        :isLoading="isTableLoading"
        :showPagination="showPagination"
        :isHidden="true"
      >
        <RequestTable
          isPatientRequestView
          :requestItems="getItems"
          :showActions="false"
          :canEdit="false"
          :canCopy="false"
          :canDelete="false"
        />
      </TableGroup>
  </div>
</template>

<script>
import TableGroup from '@/components/molecules/Tables/TableGroup/TableGroup.vue';
import HeadingBar from '@/components/molecules/HeadingBar/HeadingBar.vue';
import RequestTable from '@/components/molecules/Tables/RequestTable/RequestTable.vue';

export default {
  props: {
    showPagination: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      requestList: [],
      isTableLoading: false,
    };
  },
  computed: {
    getItems() {
      return this.items;
    },
  },
  components: {
    TableGroup,
    HeadingBar,
    RequestTable,
  },
};
</script>
