<template>
  <div class="MenuRow">
    <router-link
      :to="{ name: url }"
      :class="
        isCurrentPath ?
        'MenuRow__link MenuRow__link--selected' :
        'MenuRow__link MenuRow__link--deselected'"
    >
      <MenuRowIcon
        :icon="icon"
        :isSelected="isCurrentPath"
      />
      <p class="MenuRow__label">{{ text }}</p>
    </router-link>
  </div>
</template>

<script>
import MenuRowIcon from './MenuRowIcon.vue';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    isCurrentPath() {
      if (this.$route.path === this.path) {
        return true;
      }

      const splitRoute = this.$route.path.split('/');

      if (splitRoute.length >= 1) {
        if (`/${splitRoute[1]}` === this.path) {
          return true;
        }
      }

      return false;
    },
  },
  components: {
    MenuRowIcon,
  },
};
</script>

<style lang="sass">
  @import './MenuRow.sass'
</style>
