<template>
  <div v-if="hasRequest">
    <LabelGroup
      :label="`${$i18n.t('lb_origin')}:`"
      :text="getOrigin"
    />
    <div class="sp__v__2x"/>
    <LabelGroup
      label="DNI:"
      :text="getRequest.patient.dni"
    />
    <div class="sp__v__2x"/>
    <LabelGroup
      :label="`${$i18n.t('lb_gender')}:`"
      :text="getGender(getRequest.patient.gender)"
    />
    <div class="sp__v__2x"/>
    <LabelGroup
      :label="`${$i18n.t('lb_tutor')}:`"
      :text="getRequest.patient.tutor"
    />
    <div class="sp__v__2x"/>

    <div v-if="getRequest.patient.insurance">
      <LabelGroup
        :label="`${$i18n.t('lb_insurance')}:`"
        :text="getRequest.patient.insurance ? getRequest.patient.insurance.name : null"
      />
      <div class="sp__v__2x"/>
    </div>

    <LabelGroup
      :label="`${$i18n.t('lb_hospital')}:`"
      :text="getRequest.patient.hospital"
    />
    <div class="sp__v__2x"/>
    <LabelGroup
      :label="`${$i18n.t('lb_dob')}:`"
      :text="getRequest.patient.dob | formatDate"
    />
    <div class="sp__v__2x"/>
    <div v-if="isDetail">
      <LabelGroup
        :label="`${$i18n.t('lb_specialty')}:`"
        :text="getRequest.specialty.name"
      />
      <div class="sp__v__2x"/>
      <LabelGroup
        :label="`${$i18n.t('lb_pathology')}:`"
        :text="getRequest.pathology"
      />
      <div class="sp__v__2x"/>
      <LabelGroup
        :label="`${$i18n.t('lb_medical_procedure')}:`"
        :text="getRequest.medicalProcedure"
      />
      <div class="sp__v"/>
    </div>
  </div>
</template>

<script>
import LabelGroup from '@/components/atoms/Label/LabelGroup.vue';
import { getGender } from '@/helpers/UserHelper';

export default {
  props: {
    request: {
      type: Object,
      default: () => {},
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getGender(value) {
      if (value) {
        return getGender(value);
      }

      return null;
    },
  },
  computed: {
    hasRequest() {
      return this.request.id !== undefined;
    },

    getRequest() {
      return this.request;
    },

    getOrigin() {
      if (this.request.patient?.origin !== undefined) {
        return this.request.patient.origin.name;
      }

      return null;
    },
  },
  components: {
    LabelGroup,
  },
};
</script>
