<template>
  <div>
    <Breadcrumb
      @handleClickAction="hideDetail"
      :items="[
        {
          name: $i18n.t('lb_clinics'),
          hasAction: true,
        },
        {
          name: clinic.name,
        },
      ]"
    />
    <div class="sp__v"/>
    <div class="detailCard">
      <Avatar
        :image="getAvatar"
        size="80px"
      />
      <div class="detailCard__actionBlock">
        <b-button
          @click.prevent="handleDelete"
          variant="secondary" size="sm"
        >
          {{ $i18n.t('btn_delete') }}
        </b-button>
        <span class="sp__1s5x"/>
        <b-button
          @click.prevent="handleEdit"
          variant="secondary"
          size="sm"
        >
          {{ $i18n.t('btn_edit') }}
        </b-button>
      </div>
    </div>
    <div class="sp__v__1s5x"/>
    <h2>{{ clinic.name }}</h2>
    <p class="text__sr text-secondary">{{ clinic.city }}</p>
    <div class="sp__v__2x"/>
    <LabelGroup
      :label="`${$i18n.t('lb_email')}:`"
      :text="clinic.email"
    />
    <div class="sp__v"/>
    <LabelGroup
      :label="`${$i18n.t('lb_phone')}:`"
      :text="clinic.phone"
    />
    <div class="sp__v"/>
    <LabelGroup
      :label="`${$i18n.t('lb_contact_person')}:`"
      :text="clinic.contactName"
    />
    <div v-if="showYearFoundation" class="sp__v"/>
    <LabelGroup
      v-if="showYearFoundation"
      :label="`${$i18n.t('lb_foundation_year')}:`"
      :text="getYearFundation"
    />
    <div class="sp__v" v-if="clinic.hasInsurances"/>
    <LabelGroup
      v-if="clinic.hasInsurances"
      :label="`${$i18n.t('lb_private_insurance_min')}:`"
      :text="this.insuranceNames"
    />
    <div class="sp__v"/>
    <LabelGroup
      v-if="clinic.googleMyBusinessAccount"
      :label="`${$i18n.t('lb_google_mybusiness_account')}:`"
      :text="clinic.googleMyBusinessAccount"
    />
    <div class="sp__v__2x"/>
    <TextGroup
      :label="`${$i18n.t('lb_description')}:`"
      :text="clinic.description"
    />
    <div class="sp__v__2x"/>
    <TextGroup
      :label="`${$i18n.t('lb_acreditations')}:`"
      :text="clinic.credentials"
    />
  </div>
</template>

<script>
import Breadcrumb from '@/components/atoms/Breadcrumb/Breadcrumb.vue';
import Avatar from '@/components/atoms/Avatar/Avatar.vue';
import LabelGroup from '@/components/atoms/Label/LabelGroup.vue';
import TextGroup from '@/components/atoms/TextGroup/TextGroup.vue';
import { getAvatarFile } from '@/helpers/UserHelper';

export default {
  props: {
    clinic: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      insuranceNames: [],
    };
  },
  mounted() {
    if (this.clinic.insurances) {
      this.getInsuranceNames();
    }
  },
  methods: {
    handleEdit() {
      this.$emit('showUpdateForm', this.clinic);
    },

    handleDelete() {
      this.$emit('showDeleteConfirm', this.clinic);
    },

    hideDetail() {
      this.$emit('hideDetail');
    },

    getInsuranceNames() {
      const insurances = this.clinic.insurances.map((insurance) => insurance.name);
      this.insuranceNames = insurances.toString();
    },
  },
  computed: {
    getAvatar() {
      return getAvatarFile(this.clinic?.avatar);
    },

    getYearFundation() {
      return typeof this.clinic.yearFoundation === 'number' ? this.clinic.yearFoundation.toString() : this.clinic.yearFoundation;
    },

    showYearFoundation() {
      return this.clinic.yearFoundation > 0;
    },
  },
  components: {
    Breadcrumb,
    Avatar,
    LabelGroup,
    TextGroup,
  },
};
</script>
