<template>
<div>
  <b-button
    v-b-toggle.password-change-collapse
    :pressed.sync="isOpen"
    :variant="
      isOpen ?
      'simple-primary text-dark' :
      'simple-primary'"
  >
    {{ $i18n.t('lb_change_password') }}
  </b-button>
  <div class="sp__v" />
  <b-collapse id="password-change-collapse" class="mt-2">
    <div class="PasswordChange">
      <InputGroup
        id="passwordChangeActual"
        :placeholder="$i18n.t('lb_actual_password')"
        @handlerInputChange="handleChangeActualPassword"
        :isValid="this.validationState.actualPassword"
        :error="this.$i18n.t('val_user_verification')"
        type="password"
      />
      <div class="sp__v__1d5x" />
      <InputGroup
        id="passwordChangeNew"
        :placeholder="$i18n.t('lb_new_password')"
        @handlerInputChange="handleChangeNewPassword"
        :isValid="this.validationState.newPassword"
        :error="this.$i18n.t('val_user_verification')"
        type="password"
      />
      <div class="sp__v__1d5x" />
      <b-button
        @click.prevent="handleChangePassword"
        variant="primary"
      >
        {{ $i18n.t('btn_change') }}
      </b-button>
    </div>
  </b-collapse>
</div>
</template>

<script>
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import { getPasswordValidationState, isValidForm } from '@/helpers/ValidationHelper';
import { logout } from '@/helpers/UserHelper';

export default {
  data: () => ({
    isOpen: false,
    passwordChangeData: {
      actualPassword: null,
      newPassword: null,
    },
    validationState: {
      actualPassword: null,
      newPassword: null,
    },
  }),
  methods: {
    handleChangeActualPassword(text) {
      this.passwordChangeData.actualPassword = text;
    },

    handleChangeNewPassword(text) {
      this.passwordChangeData.newPassword = text;
    },

    changePassword() {
      const passwordChange = {
        old: this.passwordChangeData.actualPassword,
        new: this.passwordChangeData.newPassword,
      };

      this.$store.dispatch('user/resetPassword', passwordChange)
        .then(() => {
          this.$store.commit('initState');
          logout();
        })
        .catch(() => {
          console.log('error');
        });

      this.$store.commit('initState');
      logout();
    },

    handleChangePassword() {
      const actualPasswordlValidationState = getPasswordValidationState(
        this.passwordChangeData.actualPassword,
      );
      const newPasswordValidationState = getPasswordValidationState(
        this.passwordChangeData.newPassword,
      );

      if (isValidForm([
        actualPasswordlValidationState,
        newPasswordValidationState,
      ])) {
        this.resetValidationState();
        this.changePassword();
      } else {
        this.validationState.actualPassword = actualPasswordlValidationState;
        this.validationState.newPassword = newPasswordValidationState;
      }
    },

    resetValidationState() {
      this.validationState.actualPassword = null;
      this.validationState.newPassword = null;
    },
  },
  components: {
    InputGroup,
  },
};
</script>
