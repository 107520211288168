<template>
  <div
    v-if="showSpinner"
    :class="full ? 'Spinner__full' : 'Spinner'">
    <b-spinner
      :variant="variant"
      label=""
    />
  </div>
</template>

<script>

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'secondary',
    },
  },
  computed: {
    showSpinner() {
      return this.isLoading;
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './Spinner.sass'
</style>
