import MarketModel from './MarketModel';

class MarketFactory {
  constructor() {
    this.market = {};
  }

  getMarket(marketListData) {
    const marketList = marketListData.map((market) => {
      this.market = {
        id: market.id,
        name: market.name,
      };

      return new MarketModel(this.market);
    });

    return marketList;
  }
}

export default new MarketFactory();
