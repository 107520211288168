import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export function getMarkets(state) {
  return state;
}

export function hasMarkets(state) {
  return state && state.length > 0;
}
