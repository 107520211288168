<template>
  <b-breadcrumb
    :disabled="true"
  >
    <template
      v-for="item in items"
    >
      <b-breadcrumb-item
        :key="item.name"
        @click.prevent="handleClick(item)"
      >{{ item.name }}</b-breadcrumb-item>
    </template>
  </b-breadcrumb>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  methods: {
    handleClick(item) {
      if (typeof item.position !== 'undefined') {
        this.$router.go(item.position);
      } else if (typeof item.hasAction !== 'undefined') {
        this.$emit('handleClickAction');
      }
    },
  },
};
</script>
