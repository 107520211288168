<template>
  <div :id="id" class="ComingSoon">
    <div class="ComingSoon__block w-card">
      <div class="d-flex flex-column">
        <p class="text__psb">
          {{ label }}
        </p>
        <div class="sp__v__1s5x"></div>
        <p class="text__pr">
          {{ text }}
        </p>
      </div>
      <button @click.prevent="handleClose">
        <img src="@/assets/actions/close.svg" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      label: this.$i18n.t('cp_coming_soon'),
      text: this.$i18n.t('cp_coming_soon_text'),
    };
  },
  methods: {
    handleClose() {
      this.$emit('handleClose');
    },
  },
};
</script>

<style lang="sass">
@import './ComingSoon.sass'
</style>
