import moment from 'moment';
import i18n from '@/plugins/vue-i18n';

export const STATUS = {
  PENDING: {
    key: 'pending',
    value: i18n.t('cp_status_pending'),
  },
  ACCEPTED: {
    key: 'accepted',
    value: i18n.t('cp_status_accepted'),
  },
  REJECTED: {
    key: 'rejected',
    value: i18n.t('cp_status_rejected'),
  },
};

export const STATUS_OPTIONS = [
  {
    value: STATUS.PENDING.key,
    text: STATUS.PENDING.value,
  },
  {
    value: STATUS.ACCEPTED.key,
    text: STATUS.ACCEPTED.value,
  },
  {
    value: STATUS.REJECTED.key,
    text: STATUS.REJECTED.value,
  },
];

export function getVariantByStatus(status) {
  switch (status) {
    case STATUS.PENDING.key:
      return STATUS.PENDING.value;

    case STATUS.ACCEPTED.key:
      return STATUS.ACCEPTED.value;

    case STATUS.REJECTED.key:
      return STATUS.REJECTED.value;

    default:
      return '';
  }
}

export function getImageFormatted(image) {
  if (image && typeof image === 'object') {
    return URL.createObjectURL(image);
  }

  return image || null;
}

export function getCurrentDate() {
  return moment().format('YYYY-MM-DD');
}

export const INTERVAL = {
  ALL: {
    key: 'all',
    value: i18n.t('lb_interval_all'),
  },
  WEEK: {
    key: 'week',
    value: i18n.t('lb_interval_week'),
  },
  MONTH: {
    key: 'month',
    value: i18n.t('lb_interval_month'),
  },
  YEAR: {
    key: 'year',
    value: i18n.t('lb_interval_year'),
  },
};

export const INTERVAL_OPTIONS = [
  {
    value: INTERVAL.ALL.key,
    text: INTERVAL.ALL.value,
  },
  {
    value: INTERVAL.WEEK.key,
    text: INTERVAL.WEEK.value,
  },
  {
    value: INTERVAL.MONTH.key,
    text: INTERVAL.MONTH.value,
  },
  {
    value: INTERVAL.YEAR.key,
    text: INTERVAL.YEAR.value,
  },
];

export const EMERGENCY_DEGREE = {
  LOW: {
    key: 'low',
    value: i18n.t('cp_degree_low'),
  },
  MEDIUM: {
    key: 'medium',
    value: i18n.t('cp_degree_medium'),
  },
  HIGH: {
    key: 'high',
    value: i18n.t('cp_degree_high'),
  },
};

export const EMERGENCY_DEGREE_OPTIONS = [
  {
    value: null,
    text: 'Selecciona',
  },
  {
    value: EMERGENCY_DEGREE.LOW.key,
    text: EMERGENCY_DEGREE.LOW.value,
  },
  {
    value: EMERGENCY_DEGREE.MEDIUM.key,
    text: EMERGENCY_DEGREE.MEDIUM.value,
  },
  {
    value: EMERGENCY_DEGREE.HIGH.key,
    text: EMERGENCY_DEGREE.HIGH.value,
  },
];

export const BOOLEAN_SELECTION = {
  TRUE: {
    key: true,
    value: i18n.t('cp_boolean_true'),
  },
  FALSE: {
    key: false,
    value: i18n.t('cp_boolean_false'),
  },
};

export const BOOLEAN_SELECTION_OPTIONS = [
  {
    value: BOOLEAN_SELECTION.TRUE.key,
    text: BOOLEAN_SELECTION.TRUE.value,
  },
  {
    value: BOOLEAN_SELECTION.FALSE.key,
    text: BOOLEAN_SELECTION.FALSE.value,
  },
];

export const STAGE = {
  RECEIVED: {
    key: '71a95825-9fed-43a3-b3da-ae181881b720',
    value: i18n.t('cp_stage_received'),
  },
  DOCUMENTATION_REVISION: {
    key: '0554f9ee-063d-423d-ab1d-ceae0728573e',
    value: i18n.t('cp_stage_documentation_revision'),
  },
  SPECIALIST_EVALUATION: {
    key: 'd764cc84-7000-4854-98ab-e0ef7f9c9557',
    value: i18n.t('cp_stage_specialist_evaluation'),
  },
  TREATMENT_PLAN: {
    key: '1e50b506-ac10-4e5c-b2ff-2c6fe9715066',
    value: i18n.t('cp_stage_treatment_plan'),
  },
  IN_TREATMENT: {
    key: '920e5880-6249-4324-b440-16b516a8e56a',
    value: i18n.t('cp_stage_in_treatment'),
  },
  MEDICAL_DISCHARGE: {
    key: '7f86c0db-bcce-4fba-8ea1-be058dde177f',
    value: i18n.t('cp_stage_medical_discharge'),
  },
};

export const STAGE_OPTIONS = [
  {
    value: STAGE.RECEIVED.key,
    text: STAGE.RECEIVED.value,
  },
  {
    value: STAGE.DOCUMENTATION_REVISION.key,
    text: STAGE.DOCUMENTATION_REVISION.value,
  },
  {
    value: STAGE.SPECIALIST_EVALUATION.key,
    text: STAGE.SPECIALIST_EVALUATION.value,
  },
  {
    value: STAGE.TREATMENT_PLAN.key,
    text: STAGE.TREATMENT_PLAN.value,
  },
  {
    value: STAGE.IN_TREATMENT.key,
    text: STAGE.IN_TREATMENT.value,
  },
  {
    value: STAGE.MEDICAL_DISCHARGE.key,
    text: STAGE.MEDICAL_DISCHARGE.value,
  },
];

export const KPIS = [
  {
    value: 'total de solicitudes',
    text: i18n.t('lb_total_requests'),
  },
  {
    value: 'solicitudes aceptadas',
    text: i18n.t('lb_requests_accepted'),
  },
  {
    value: 'solicitudes pendientes',
    text: i18n.t('lb_requests_pending'),
  },
  {
    value: 'solicitudes rechazadas',
    text: i18n.t('lb_requests_rejected'),
  },
  {
    value: 'total pacientes',
    text: i18n.t('lb_total_patients'),
  },
];

export const MIN_SEARCH__LENGTH = 3;

export const PHONE__MIN__LENGTH = 9;

export const DNI__MIN__LENGTH = 8;

export function getSelectDefault(type) {
  const label = `lb_select_${type}`;
  return {
    value: null,
    text: i18n.t(label),
  };
}

export const SORT_TYPE = {
  ASC: 'asc',
  DESC: 'desc',
};

export function getSortType(type) {
  if (type === null) {
    return SORT_TYPE.ASC;
  }

  return type === SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
}

export function getFiltersCount(filters) {
  let totalActiveFilters = 0;

  Object.entries(filters).map((item) => {
    if (item[1] !== null) {
      totalActiveFilters += 1;
    }

    return null;
  });

  return totalActiveFilters;
}

export function getIdList(list) {
  if (list.length > 0) {
    const idList = [];

    list.forEach((item) => idList.push(item.id));

    return idList;
  }

  return null;
}

export function downloadCsv(data) {
  const fileName = 'patient_list.csv';
  const link = document.createElement('a');
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute('download', fileName);

  link.click();
}

export function uploadCsv(document) {
  const requestData = {
    fileName: document.file.name,
    encodedFile: document.base64.encodedFile,
    mimeType: document.file.type,
    extension: document.base64.extension,
  };

  return requestData;
}

export const removeAccents = (data) => data.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
