<template>
  <Modal
    :id="id"
    :title="title"
    :hideHeader="hideHeader"
    :hideFooter="hideFooter"
    :isForm="isForm"
    @onHidden="handleCancel"
    isCentered
  >
    <div class="ModalAction__content">
      <slot></slot>
    </div>
    <ModalFooter
      v-if="!hideFooter"
      :okLabel="okLabel"
      :cancelLabel="cancelLabel"
      @handleOk="handleOk"
      @handleCancel="handleCancel"
      :isCentered="isCentered"
      :onlyCancel="onlyCancel"
      :isLoading="isLoading"
      :fullSpinner="fullSpinner"
    />
  </Modal>
</template>

<script>
import Modal from '@/components/molecules/Modal/Modal.vue';
import ModalFooter from '@/components/molecules/Modal/ModalFooter.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    okLabel: String,
    cancelLabel: String,
    isCentered: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      default: '',
    },
    onlyCancel: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    isForm: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    fullSpinner: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCancel() {
      this.$emit('handleCancel');
      this.$root.$emit('bv::hide::modal', this.id, '#btnShow');
    },
    handleOk() {
      this.$emit('handleOk');
    },
  },
  components: {
    Modal,
    ModalFooter,
  },
};
</script>

<style lang="sass" scoped>
@import './ModalAction.sass'
</style>
