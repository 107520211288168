import {
  createNote,
  updateNote,
  deleteNote,
} from './noteActions';

const note = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    createNote,
    updateNote,
    deleteNote,
  },
  getters: {
  },
};

export default note;
