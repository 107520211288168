import SpecialtyApi from '../../domain/specialty/SpecialtyApi';

export default function getAllSpecialties({ commit }, data) {
  const specialtiesApi = new SpecialtyApi();
  const getAllSpecialty = specialtiesApi.getAllSpecialties(data);

  return getAllSpecialty
    .then((res) => {
      commit('specialties/setSpecialties', res, { root: true });
      return res;
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
