import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import KpiFactory from './KpiFactory';

const KPIS = '/api/kpis';

class kpiAPI {
  constructor() {
    this.kpi = getAxiosWithAuthHeader();
  }

  getAllKpi(data) {
    let parameterUrl;

    if (data === undefined) {
      parameterUrl = `${KPIS}/week`;
    } else {
      parameterUrl = `${KPIS}/${data}`;
    }

    return this.kpi
      .get(parameterUrl)
      .then((res) => KpiFactory.getAllKpi(res.data))
      .catch((error) => Promise.reject(error));
  }
}

export default kpiAPI;
