<template>
  <SelectGroup
    :id="id"
    :label="label"
    :placeholder="placeholder"
    @handlerSelect="handleChangeMarket"
    :isValid="isValid"
    :error="error"
    :options="markets"
    :selectedOption="getValue"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: 'No puede estar vacio',
    },
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  created() {
    if (!this.hasMarkets) {
      this.getMarketList();
    }
  },
  methods: {
    async getMarketList() {
      await this.$store.dispatch('markets/getAllMarkets')
        .then((res) => {
          const marketOptions = res.map((market) => {
            const newMarket = {
              value: market.id,
              text: market.name,
            };

            return newMarket;
          });

          const defaultValue = {
            value: null,
            text: this.$i18n.t('lb_select_market'),
          };
          marketOptions.unshift(defaultValue);
          this.$store.commit('markets/setMarkets', marketOptions);
        })
        .catch(() => {
        });
    },

    handleChangeMarket(value) {
      this.$emit('handlerSelect', value);
    },
  },
  computed: {
    ...mapGetters('markets', {
      markets: 'getMarkets',
      hasMarkets: 'hasMarkets',
    }),

    getValue() {
      return this.value;
    },
  },
  components: {
    SelectGroup,
  },
};
</script>
