<template>
  <div class="Header">
    <Notification />
    <div class="sp__1d5x"/>
    <UserMenu @handleOpenEditUser="handleOpenEditUser"/>
  </div>
</template>

<script>
import Notification from '@/components/atoms/Notification/Notification.vue';
import UserMenu from '@/components/atoms/User/UserMenu/UserMenu.vue';

export default {
  methods: {
    handleOpenEditUser() {
      this.$emit('handleOpenEditUser');
    },
  },
  components: {
    Notification,
    UserMenu,
  },
};
</script>

<style lang="sass" scoped>
@import './Header.sass'
</style>
