<template>
  <div class="Datepicker">
    <b-form-datepicker
      :id="`${id}-datepicker`"
      v-model="date"
      :placeholder="placeholder"
      @input="onSelectDate"
      :date-format-options="{
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }"
      :state="isValid"
      start-weekday="1"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    value: String,
    placeholder: String,
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      date: this.value,
    };
  },
  methods: {
    onSelectDate() {
      this.$emit('handleChangeDate', this.date);
    },
  },
  watch: {
    value(newValue) {
      this.date = newValue;
    },
  },
};
</script>

<style lang="sass">
  @import './Datepicker.sass'
</style>
