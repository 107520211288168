export default class PaginationModel {
  constructor(paginationData) {
    this.currentPage = paginationData.currentPage;
    this.itemsPerPage = paginationData.itemsPerPage;
    this.lastPage = paginationData.lastPage;
    this.nextPage = paginationData.nextPage;
    this.previousPage = paginationData.previousPage;
    this.totalItems = paginationData.totalItems;
  }
}
