<template>
  <div class="Table">
    <b-table
      :items="items"
      :fields="fields"
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
    >
      <template #cell(name)="data">
        {{ `${data.item.name} ${data.item.lastname}` }}
      </template>
      <template #cell(createdAt)="data">
        <span class="text__sr text-secondary">{{ data.item.createdAt | formatDate }}</span>
      </template>
      <template #cell(origin)="data">
        <span class="text__sr text-secondary">{{ getOrigin(data.item.origin) }}</span>
      </template>
      <template #cell(dob)="data">
        <span class="text__sr text-secondary">{{ data.item.dob | formatDate }}</span>
      </template>
      <template #cell(email)="data">
        <span class="text__sr text-secondary">
          {{ getValidEmailFormat(data.item.id, data.item.email) }}
        </span>
      </template>
      <template #cell(phone)="data">
        <span class="text__sr text-secondary">{{ data.item.phone }}</span>
      </template>
      <template #cell(totalRequests)="data">
        <span class="text__sr text-secondary">{{ data.item.totalRequests }}</span>
      </template>
      <template #cell(actions)="data">
        <IconActionsGroup
          @edit="showUpdateForm"
          @delete="showDeleteModal"
          :objectData="data.item"
          :canCopy="false"
          :canDelete="canDelete"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import IconActionsGroup from '@/components/atoms/Icon/IconActionsGroup/IconActionsGroup.vue';
import { getImageFormatted } from '@/helpers/ComponentHelper';
import fields from './PatientTableFields';
import { checkEmailFormat } from '@/helpers/UserHelper';

export default {
  props: {
    patientItems: {
      type: Array,
      default: () => [],
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sortBy: 'case',
      sortDesc: false,
      items: this.patientItems,
      fields,
    };
  },
  methods: {
    onRowSelected(items) {
      this.$emit('handleSelectRow', items[0]);
    },

    showUpdateForm(data) {
      this.$emit('showUpdateForm', data);
    },

    showDeleteModal(data) {
      this.$emit('showDeleteModal', data);
    },

    getImage(image) {
      return getImageFormatted(image);
    },

    getOrigin(origin) {
      return origin !== null ? origin.name : null;
    },

    getValidEmailFormat(id, email) {
      if (id && email) {
        return checkEmailFormat(id, email)
          ? email
          : null;
      }

      return null;
    },
  },
  watch: {
    sortBy(newValue) {
      this.$emit('handleSortAsc', newValue);
    },

    sortDesc(newValue) {
      if (newValue === true) {
        this.$emit('handleSortDesc', this.sortBy);
      } else {
        this.$emit('handleSortAsc', this.sortBy);
      }
    },
  },
  components: {
    IconActionsGroup,
  },
};
</script>

<style lang="sass" scoped>
@import '../Table.sass'
</style>
