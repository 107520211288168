import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import CountryFactory from './CountryFactory';

const COUNTRIES = '/api/countries';

class countryAPI {
  constructor() {
    this.country = getAxiosWithAuthHeader();
  }

  getAllCountries() {
    return this.country
      .get(COUNTRIES)
      .then((res) => res.data.collection.map((country) => CountryFactory.getCountry(country)))
      .catch((error) => Promise.reject(error));
  }
}

export default countryAPI;
