import MarketApi from '../../domain/market/MarketApi';

export default function getAllMarkets({ commit }, data) {
  const marketsApi = new MarketApi();
  const getAllMarket = marketsApi.getAllMarkets(data);

  return getAllMarket
    .then((res) => res)
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
