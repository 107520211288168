import CityModel from './CityModel';

class CityFactory {
  constructor() {
    this.city = {};
  }

  getCity(city) {
    this.city = {
      name: city.name,
    };
    return new CityModel(this.city);
  }
}

export default new CityFactory();
