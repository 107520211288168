<template>
  <private-template>
    <template v-slot:private-template>
      <div class="sp__v__2x"/>
      <DoctorDetail
        v-if="showDetail"
        :doctor="doctor"
        @showUpdateForm="showDetailUpdateForm"
        @showDeleteConfirm="showDetailDeleteModal"
        @hideDetail="hideDetailView"
      />
      <div v-if="!showDetail">
        <HeadingBar
          :title="$i18n.t('lb_doctors')"
          :text="$i18n.t('txt_doctors')"
        />
        <div class="buttonContainer">
          <b-button
            @click.prevent="showCreateModal"
            variant="primary"
            size="sm"
          >
             {{ `+ ${$i18n.t('btn_new')}` }}
          </b-button>
        </div>
        <div class="sp__v__2x"/>
        <TableGroup
          id="doctor"
          :items="doctorList"
          :isLoading="isTableLoading"
          :pagination="pagination"
          :searchText="searchText"
          :filterCount="getFilterCount"
          @handleSearch="handleSearchByInput"
          @handleSearchByFilter="handleSearchByFilter"
          @handlePaginate="handleFilterSearch"
          @handleCleanSearch="handleCleanSearch"
        >
          <template v-slot:content>
            <DoctorFilters
              @handlerSelectSpecialty="handlerSelectSpecialty"
              :filters="filters"
            />
          </template>

          <DoctorTable
            :doctorItems="doctorList"
            @showUpdateForm="showUpdateForm"
            @showDeleteModal="showDeleteModal"
            @handleSelectRow="handleSelectRow"
            @handleSortAsc="handleSortAsc"
          />
        </TableGroup>
      </div>
      <CreateDoctor
        v-if="showCreateDoctor"
        @addDoctor="addDoctor"
        @handleCancel="closeCreateModal"
      />
      <UpdateDoctor
        @updateDoctor="updateDoctor"
        :doctor="doctor"
      />
      <DeleteDoctor
        @deleteDoctor="deleteDoctor"
        ref="deleteDoctor"
      />
    </template>
  </private-template>
</template>

<script>
import PrivateTemplate from '@/components/organisms/PrivateTemplate.vue';
import HeadingBar from '@/components/molecules/HeadingBar/HeadingBar.vue';
import TableGroup from '@/components/molecules/Tables/TableGroup/TableGroup.vue';
import DoctorTable from '@/components/molecules/Tables/DoctorTable/DoctorTable.vue';
import DoctorFilters from '@/components/organisms/Doctor/DoctorFilters.vue';
import CreateDoctor from '@/components/organisms/Doctor/CreateDoctor.vue';
import UpdateDoctor from '@/components/organisms/Doctor/UpdateDoctor.vue';
import DeleteDoctor from '@/components/organisms/Doctor/DeleteDoctor.vue';
import DoctorDetail from '@/components/organisms/Doctor/DoctorDetail.vue';
import DoctorModel from '@/domain/doctor/DoctorModel';
import { getSortType, SORT_TYPE, getFiltersCount } from '@/helpers/ComponentHelper';
import { getOrdenation, getFiltersGroup } from '@/helpers/ApiHelper';
import { getAvatarByProps } from '@/helpers/UserHelper';

export default {
  data() {
    return {
      doctorList: [],
      doctor: {
        id: null,
        avatar: null,
        name: null,
        lastname: null,
        specialty: null,
        bio: null,
        experience: null,
        details: null,
        email: null,
        phone: null,
      },
      showCreateDoctor: false,
      showDetail: false,
      filters: {
        specialty: null,
      },
      isTableLoading: false,
      searchText: null,
      sort: {
        name: null,
        type: null,
      },
      pagination: {},
    };
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('doctor/getAllDoctor', page)
        .then((res) => {
          this.doctorList = res.doctorList;
          this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },

    getFilters() {
      const filtersGroup = [];

      if (this.searchText !== null) {
        filtersGroup.push(
          `name:${this.searchText}`,
        );
      }

      return getFiltersGroup(filtersGroup, this.filters);
    },

    getRequestData(page = 1) {
      return {
        page,
        filters: this.getFilters(),
        ordenation: getOrdenation(this.sort),
      };
    },

    handleFilterSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('doctor/filterDoctor', this.getRequestData(page))
        .then((res) => {
          this.doctorList = res.doctorList;
          this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },

    handleSelectRow(item) {
      this.setDoctor(item);

      this.showDetailView();
    },

    showUpdateForm(data) {
      this.setDoctor(data);

      this.$bvModal.show('update-doctor');
    },

    showDetailUpdateForm(data) {
      this.setDoctor(data);

      this.$bvModal.show('update-doctor');
    },

    showDeleteModal(data) {
      this.$refs.deleteDoctor.setId(data.id);
      this.$bvModal.show('delete-doctor');
    },

    showDetailDeleteModal() {
      this.$refs.deleteDoctor.setId(this.doctor.id);
      this.$bvModal.show('delete-doctor');
    },

    showCreateModal() {
      this.showCreateDoctor = true;
    },

    closeCreateModal() {
      this.showCreateDoctor = false;
    },

    showDetailView() {
      this.showDetail = true;
    },

    hideDetailView() {
      this.showDetail = false;
    },

    addDoctor(data) {
      const newDoctor = new DoctorModel({
        id: data.id,
        avatar: data.avatar ? URL.createObjectURL(data.avatar.file) : null,
        name: data.name,
        lastname: data.lastname,
        specialty: data.specialty,
        bio: data.bio,
        experience: data.experience,
        details: data.details,
        email: data.email,
        phone: data.phone,
      });

      this.doctorList.unshift(newDoctor);

      this.incrementPagination();

      this.closeCreateModal();
    },

    incrementPagination() {
      this.pagination.totalItems += 1;
      this.pagination.itemsPerPage += 1;
    },

    updateDoctor(data) {
      for (let i = 0; this.doctorList.length; i += 1) {
        if (this.doctorList[i].id === data.id) {
          const newDoctor = {
            id: data.id,
            avatar: this.getAvatar(data.avatar),
            name: data.name,
            lastname: data.lastname,
            specialty: data.specialty,
            totalRequest: this.doctorList[i].totalRequest,
            patientsCount: this.doctorList[i].patientsCount,
            bio: data.bio,
            experience: data.experience,
            details: data.details,
            email: data.email,
            phone: data.phone,
          };

          this.doctorList.splice(i, 1);
          this.doctorList.splice(i, 0, newDoctor);
          break;
        }
      }

      this.$bvModal.hide('update-doctor');

      this.setDoctor(data);
    },

    deleteDoctor(id) {
      this.$bvModal.hide('delete-doctor');

      if (this.showDetail) {
        this.hideDetailView();
      }

      for (let i = 0; this.doctorList.length; i += 1) {
        if (this.doctorList[i].id === id) {
          this.doctorList.splice(i, 1);
          this.handleSearch(this.pagination.currentPage);
          break;
        }
      }
    },

    handleSearchByInput(text) {
      if (text !== null) {
        this.searchText = text;
        this.handleFilterSearch();
      } else {
        this.searchText = null;
      }
    },

    handleSearchByFilter() {
      this.handleFilterSearch();
      this.$root.$emit('bv::toggle::collapse', 'doctor-sidebar');
    },

    handleSortAsc(param) {
      this.sort.type = this.sort.name === param ? getSortType(this.sort.type) : SORT_TYPE.ASC;
      this.sort.name = param;

      this.handleFilterSearch();
    },

    handlerSelectSpecialty(value) {
      this.filters.specialty = value;
    },

    resetFilters() {
      this.filters.specialty = null;
    },

    resetOrderFilters() {
      this.sortAsc = null;
      this.sortDesc = null;
    },

    onHiddenSidebar() {
      this.resetFilters();
    },

    setDoctor(data) {
      this.doctor = {
        id: data.id,
        avatar: data.avatar,
        name: data.name,
        lastname: data.lastname,
        specialty: data.specialty,
        bio: data.bio,
        experience: data.experience,
        details: data.details,
        email: data.email,
        phone: data.phone,
      };
    },

    handleCleanSearch() {
      this.filters.specialty = null;
      this.searchText = null;
      this.sort.name = null;
      this.sort.type = null;

      this.handleSearch();
    },

    getAvatar(avatar) {
      return getAvatarByProps(avatar);
    },
  },
  computed: {
    getFilterCount() {
      return getFiltersCount(this.filters);
    },
  },
  components: {
    PrivateTemplate,
    HeadingBar,
    TableGroup,
    DoctorTable,
    DoctorFilters,
    CreateDoctor,
    UpdateDoctor,
    DeleteDoctor,
    DoctorDetail,
  },
};
</script>
