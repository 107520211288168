import SpecialtyModel from './SpecialtyModel';

class SpecialtyFactory {
  constructor() {
    this.specialty = {};
  }

  getAllSpecialty(data) {
    const specialtyResponse = this.getCollection(data.collection);

    return specialtyResponse;
  }

  getSpecialty(specialtyData) {
    this.specialty = new SpecialtyModel({
      id: specialtyData.id,
      name: specialtyData.name,
    });

    return this.specialty;
  }

  getCollection(specialtyCollection) {
    const specialtyList = specialtyCollection.map(
      (specialty) => this.getSpecialty(specialty),
    );

    return specialtyList;
  }
}

export default new SpecialtyFactory();
