<template>
  <div class="IconGroup">
    <img
      class="IconGroup__icon"
      :src="icon ? icon : require('@/assets/user/empty-avatar-dark.svg')"
    />
    <span class="text__sr">{{ label }}</span>
  </div>
</template>

<script>

export default {
  props: {
    icon: String,
    label: String,
  },
};
</script>

<style lang="sass" scoped>
  @import './IconGroup.sass'
</style>
