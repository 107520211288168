<template>
  <div id="app">
    <router-view/>
    <ErrorModal
      id="errorModal"
      :showModal="this.hasErrors"
    />
    <SalesforceChat />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ErrorModal from '@/components/molecules/Modal/ErrorModal.vue';
import SalesforceChat from '@/components/organisms/Chat/SalesforceChat.vue';

export default {
  components: {
    SalesforceChat,
    ErrorModal,
  },
  computed: {
    ...mapGetters({
      hasErrors: 'error/hasErrors',
    }),
  },
  watch: {
    hasErrors() {
      if (this.hasErrors) {
        this.$bvModal.show('errorModal');
      }
    },
  },
};
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap');
</style>

<style lang="sass">
@import '@/style/utilities.sass'
@import '@/style/text.sass'
</style>
