<template>
  <div>
    <b-form>
      <b-form-group
        class="text__psb"
        :id="`${id}-group`"
        :label="label"
        :label-for="`${id}-select`"
      >
        <Spinner :isLoading="isLoading" />
        <Select
          v-if="!isLoading"
          :id="`${id}-select`"
          :placeholder="placeholder"
          :isValid="isValid"
          :options="options"
          :selectedOption="selectedOption"
          :isDisabled="isDisabled"
          @handlerSelect="handlerSelect"
        />
        <b-form-invalid-feedback
          v-if="isValid === false"
          class="text__sr"
        >
          {{ error }}
        </b-form-invalid-feedback>
        <b-form-text
          v-if="showHelp"
          class="text__sr"
        >
          {{ help }}
        </b-form-text>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import Select from '@/components/atoms/Select/Select.vue';
import Spinner from '@/components/atoms/Spinner/Spinner.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    selectedOption: {
      type: [String, Boolean],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: null,
    },
    help: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handlerSelect(value) {
      this.$emit('handlerSelect', value);
    },
  },
  computed: {
    showHelp() {
      return this.help.length > 0;
    },
  },
  components: {
    Select,
    Spinner,
  },
};
</script>
