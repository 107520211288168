<template>
  <b-row>
    <b-col class="col-6">
      <SelectGroup
        id="patient-hasInsurance"
        :label="$i18n.t('lb_private_insurance_min')"
        :selectedOption="this.hasInsurance"
        :options="booleanOptions"
        @handlerSelect="handleChangeHasInsurance"
      />
    </b-col>
    <b-col class="col-6">
      <div class="InsuranceComboselector" v-if="this.showInsurances">
        <SelectGroup
          id="patient-insurance"
          :label="$i18n.t('lb_select_insurance')"
          :selectedOption="this.selectedInsurance"
          :options="this.getInsuranceOptions()"
          @handlerSelect="handleChangeInsurance"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import { BOOLEAN_SELECTION_OPTIONS } from '@/helpers/ComponentHelper';

export default {
  props: {
    hasInsurance: {
      type: Boolean,
    },
    selectedInsurance: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      booleanOptions: BOOLEAN_SELECTION_OPTIONS,
      showInsurances: false,
    };
  },
  created() {
    this.loadInsuranceList();
    this.showInsurances = this.hasInsurance;
  },
  methods: {
    async loadInsuranceList() {
      if (!this.hasInsurances) {
        await this.$store.dispatch('insurance/getAllInsurance');
      }
    },

    handleChangeHasInsurance(value) {
      const boolValue = value === 'true';
      this.showInsurances = boolValue;

      this.$emit('handleChangeHasInsurance', boolValue);
    },

    handleChangeInsurance(value) {
      this.$emit('handleChangeInsurance', value);
    },

    getInsuranceOptions() {
      const newInsurances = this.insurance.map((insurance) => {
        const newInsurance = {
          text: insurance.name,
          value: insurance.id,
        };

        return newInsurance;
      });

      return newInsurances;
    },
  },
  computed: {
    ...mapGetters('insurance', {
      insurance: 'getInsurances',
      hasInsurances: 'hasInsurances',
    }),
  },
  components: {
    SelectGroup,
  },
};
</script>

<style lang="sass" scoped>
@import '../InsuranceComboSelector/InsuranceComboSelector.sass'
</style>
