<template>
  <div class="SideForm" v-if="hasRequest">
    <RequestHeader
      :request="requestData"
      :showClose="showClose"
      @handleCloseNotification="handleCloseNotification"
    />
    <div class="sp__v__1d5x" />
    <div class="SideForm__content">
      <div class="sp__v__1s5x" />
      <ContactBlock
        :user="requestData.patient"
        :isAccepted="isRequestAccepted"
      />
      <hr />
      <RequestMiniCardBlock
        :request="requestData"
      />
      <div class="sp__v__1d5x"/>
      <hr />
      <div class="sp__v"/>
      <b-tabs>
        <b-tab :title="$i18n.t('lb_summary')">
          <div class="sp__v__1d5x"/>
          <RequestSummaryInfo
            :request="this.requestData"
            isDetail
          />
        </b-tab>
        <b-tab :title="getDocumentsTitle">
          <div class="sp__v__1d5x"/>
          <DocumentTable
            v-if="hasDocuments"
            :items="this.requestData.documents"
            canDownload
          />
        </b-tab>
        <b-tab :title="getNotesTitle">
          <div class="sp__v__1d5x"/>
          <NotesTable
            v-if="hasNotes"
            :items="this.requestData.notes"
            @handleShowDetail="handleShowViewNote"
          />
        </b-tab>
      </b-tabs>
      <hr />
      <div v-if="showHealthManager">
        <div class="sp__v__1s5x" />
        <p class="text__psb">Health Manager:</p>
        <div class="sp__v" />
        <UserMiniCard
          :icon="getAvatarHM"
          :label="getHealthManager.name"
        />
        <div class="sp__v__2x" />
      </div>

      <div v-if="isDoctor">
        <div class="sp__v__1s5x" />
        <p class="text__psb">Doctor:</p>
        <div class="sp__v" />
        <UserMiniCard
          :icon="getIcon(getDoctor.avatar)"
          :label="getDoctor.name"
        />
        <div class="sp__v__2x" />
      </div>

      <div v-if="!isDoctorOrClinic">
        <div class="sp__v__1s5x" />
        <p class="text__psb">Clinic:</p>
        <div class="sp__v" />
        <UserMiniCard
          :icon="getAvatarClinic"
          :label="getClinicName"
        />
        <div class="sp__v__2x" />
      </div>
    </div>
    <RequestDetailActions
      :uuid="this.requestData.id"
      :isLoading="isLoading"
      v-if="showButton"
      @handleAccept="handleAcceptRequest"
      @handleReject="handleRejectRequest"
    />
    <Stage
      v-else
      :stage="this.requestData.requestStage ? this.requestData.requestStage.name : null"
    />
    <NoteModal
      id="requestDetailFormNoteModal"
      ref="requestDetailFormNoteModal"
    />
  </div>
</template>

<script>
import RequestHeader from '@/components/molecules/Request/RequestHeader/RequestHeader.vue';
import RequestMiniCardBlock from '@/components/molecules/Request/RequestMiniCardBlock/RequestMiniCardBlock.vue';
import RequestDetailActions from '@/components/molecules/Request/RequestDetailActions/RequestDetailActions.vue';
import RequestSummaryInfo from './RequestSummaryInfo.vue';
import DocumentTable from '@/components/molecules/Tables/DocumentTable/DocumentTable.vue';
import NotesTable from '@/components/molecules/Tables/NotesTable/NotesTable.vue';
import NoteModal from '@/components/molecules/Modal/NoteModal.vue';
import ContactBlock from '@/components/molecules/ContactBlock/ContactBlock.vue';
import UserMiniCard from '@/components/molecules/User/UserMiniCard/UserMiniCard.vue';
import Stage from '@/components/atoms/Stage/Stage.vue';
import defaultRequest from '@/views/Request/defaultRequest';
import {
  EMERGENCY_DEGREE_OPTIONS,
  BOOLEAN_SELECTION_OPTIONS,
  STATUS_OPTIONS,
  STAGE_OPTIONS,
  STATUS,
} from '@/helpers/ComponentHelper';
import { getAvatarUrl, getAvatarByPath } from '@/helpers/UserHelper';
import emptyAvatar from '@/assets/user/empty-avatar-dark.svg';

export default {
  props: {
    showClose: {
      type: Boolean,
      default: false,
    },
    request: {
      type: Object,
      default: () => defaultRequest,
    },
  },
  data() {
    return {
      requestData: {
        id: this.request?.id,
        createdAt: this.request?.createdAt,
        caseNumber: this.request?.caseNumber,
        level: this.request?.level,
        hasInsurance: this.request?.hasInsurance,
        hasRecentReports: this.request?.hasRecentReports,
        patient: {
          id: this.request?.id,
          createdAt: this.request?.createdAt,
          name: this.request?.name,
          lastname: this.request?.lastname,
          gender: this.request?.gender,
          origin: this.request?.origin,
          dni: this.request?.dni,
          dob: this.request?.dob,
          tutor: this.request?.tutor,
          insurance: this.request?.insurance,
          hospital: this.request?.hospital,
          email: this.request?.email,
          phone: this.request?.phone,
          requests: this.request?.requests,
          contactWhatsApp: this.request?.contactWhatsApp,
        },
        specialty: this.request?.specialty,
        pathology: this.request?.pathology,
        medicalProcedure: this.request?.medicalProcedure,
        status: this.request?.status,
        requestStage: this.request?.requestStage,
        healthManager: {
          id: this.request?.healthManager?.id,
          avatar: this.request?.healthManager?.avatar,
          name: this.request?.healthManager?.name,
          lastname: this.request?.healthManager?.lastname,
          market: this.request?.healthManager?.market,
          totalRequest: this.request?.healthManager?.totalRequest,
          patientsCount: this.request?.healthManager?.patientsCount,
          email: this.request?.healthManager?.email,
          phone: this.request?.healthManager?.phone,
        },
        clinic: {
          id: this.request?.clinic?.id,
          avatar: this.request?.clinic?.avatar,
          name: this.request?.clinic?.name,
          contactName: this.request?.clinic?.contactName,
          city: this.request?.clinic?.city,
          yearFoundation: this.request?.clinic?.yearFoundation,
          description: this.request?.clinic?.description,
          acreditation: this.request?.clinic?.acreditation,
          googleMyBusinessAccount: this.request?.clinic?.googleMyBusinessAccount,
          email: this.request?.clinic?.email,
          phone: this.request?.clinic?.phone,
          vpRequest: this.request?.clinic?.vpRequest,
          totalRequest: this.request?.clinic?.totalRequest,
          totalPatients: this.request?.clinic?.totalPatients,
        },
        documents: this.request?.documents,
        notes: this.request?.notes,
        owner: this.request?.owner,
        accepted: this.request?.accepted,
      },
      validationState: {
        requestData: null,
      },
      levelOptions: EMERGENCY_DEGREE_OPTIONS,
      booleanOptions: BOOLEAN_SELECTION_OPTIONS,
      statusOptions: STATUS_OPTIONS,
      stageOptions: STAGE_OPTIONS,
      patientItems: [],
      healthManagerItems: [],
      clinicItems: [],
      showCreatePatient: false,
      showCreateHealthManager: false,
      showCreateClinic: false,
      isLoading: false,
    };
  },
  methods: {
    handleShowViewNote(note) {
      this.$refs.requestDetailFormNoteModal.setNote(note);
      this.$bvModal.show('requestDetailFormNoteModal');
    },

    handleCloseNotification() {
      this.$emit('handleCloseNotification');
    },

    changeRequestStatus(id, status) {
      this.isLoading = true;
      const requestData = {
        id,
        body: {
          status,
        },
      };

      this.$store.dispatch('request/changeRequestStatus', requestData)
        .then(() => {
          this.requestData.accepted = status === STATUS.ACCEPTED.key;
          this.$emit('handleChangeRequest', id, status);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    handleAcceptRequest(id) {
      this.changeRequestStatus(id, STATUS.ACCEPTED.key);
    },

    handleRejectRequest(id) {
      this.changeRequestStatus(id, STATUS.REJECTED.key);
    },

    getIcon(avatar) {
      if (avatar !== null && typeof avatar !== 'undefined') {
        return getAvatarUrl(avatar);
      }

      return emptyAvatar;
    },
  },
  computed: {
    hasRequest() {
      return this.requestData?.id !== undefined;
    },

    hasDocuments() {
      return this.requestData.documents !== undefined && this.requestData?.documents.length > 0;
    },

    getDocumentsTitle() {
      return this.hasDocuments ? this.$i18n.t('lb_documents_count', { count: this.requestData.documents.length }) : this.$i18n.t('lb_documents');
    },

    hasNotes() {
      return this.requestData.notes !== undefined && this.requestData?.notes.length > 0;
    },

    getNotesTitle() {
      return this.hasNotes ? this.$i18n.t('lb_notes_count', { count: this.requestData.notes.length }) : this.$i18n.t('lb_notes');
    },

    isVidasprime() {
      return this.$store.getters['user/isVidasprime'];
    },

    isClinic() {
      return this.$store.getters['user/isClinic'];
    },

    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },

    isDoctorOrClinic() {
      return this.isDoctor || this.isClinic;
    },

    isPending() {
      return this.requestData.status === STATUS.PENDING.key;
    },

    getHealthManager() {
      return this.requestData.healthManager?.id !== undefined
        && this.requestData.healthManager?.id !== null
        ? this.requestData.healthManager : null;
    },

    getClinic() {
      return this.requestData.clinic?.id !== undefined
        && this.requestData.clinic?.id !== null
        ? this.requestData.clinic : null;
    },

    getClinicName() {
      return this.getClinic && this.getClinic.name
        ? this.getClinic.name
        : null;
    },

    getDoctor() {
      return this.$store.getters['user/getCurrentUser'];
    },

    showHealthManager() {
      return (this.getHealthManager && !this.isDoctorOrClinic);
    },

    getAvatarHM() {
      const data = this.requestData.healthManager;
      if (!(data && data.avatar)) {
        return emptyAvatar;
      }

      return getAvatarByPath(data);
    },

    getAvatarClinic() {
      const data = this.requestData.clinic;
      if (!(data && data.avatar)) {
        return emptyAvatar;
      }

      return getAvatarByPath(data);
    },

    showButton() {
      if (this.isVidasprime) {
        return false;
      }
      return this.isPending;
    },

    isRequestAccepted() {
      return this.requestData.status === STATUS.ACCEPTED.key;
    },
  },
  watch: {
    request(newValue) {
      this.requestData.id = newValue.id;
      this.requestData.createdAt = newValue.createdAt;
      this.requestData.caseNumber = newValue.caseNumber;
      this.requestData.level = newValue.level;
      this.requestData.hasInsurance = newValue.hasInsurance;
      this.requestData.hasRecentReports = newValue.hasRecentReports;
      this.requestData.patient = newValue.patient;
      this.requestData.specialty = newValue.specialty;
      this.requestData.pathology = newValue.pathology;
      this.requestData.medicalProcedure = newValue.medicalProcedure;
      this.requestData.status = newValue.status;
      this.requestData.requestStage = newValue.requestStage;
      this.requestData.healthManager = newValue.healthManager;
      this.requestData.clinic = newValue.clinic;
      this.requestData.documents = newValue.documents;
      this.requestData.notes = newValue.notes;
      this.requestData.owner = newValue.owner;
      this.requestData.accepted = newValue.accepted;
    },
  },
  components: {
    RequestHeader,
    RequestMiniCardBlock,
    RequestDetailActions,
    RequestSummaryInfo,
    DocumentTable,
    NotesTable,
    NoteModal,
    ContactBlock,
    UserMiniCard,
    Stage,
  },
};
</script>

<style lang="sass" scoped>
@import './SideForm.sass'
</style>
