export const VALIDATION_STATE = {
  VALID: null,
  INVALID: false,
};

export function isValidData(data) {
  return data !== ''
    && typeof data !== 'undefined'
    && data !== null;
}

export function isValidDataText(data, minLength = 0) {
  return isValidData(data) && data.length >= minLength;
}

export function isValidEmail(email) {
  if (!isValidDataText(email)) {
    return false;
  }

  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
}

export function getValidationState(data, minLength = 0) {
  return isValidDataText(data, minLength) ? VALIDATION_STATE.VALID : VALIDATION_STATE.INVALID;
}

export function getEmailValidationState(data) {
  return isValidEmail(data) ? VALIDATION_STATE.VALID : VALIDATION_STATE.INVALID;
}

export function getNumberValidationState(data) {
  return isValidData(data) ? VALIDATION_STATE.VALID : VALIDATION_STATE.INVALID;
}

export function isValidForm(states) {
  for (let i = 0; states.length > i; i += 1) {
    if (states[i] === false) {
      return false;
    }
  }

  return true;
}

export function isValidPhone(data, minLength = 9) {
  return data !== ''
    && typeof data !== 'undefined'
    && data !== null
    && data.length >= minLength;
}

export function getPhoneValidationState(data) {
  return isValidPhone(data) ? VALIDATION_STATE.VALID : VALIDATION_STATE.INVALID;
}

export function isValidPassword(data) {
  const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/;

  return re.test(data);
}

export function getPasswordValidationState(data) {
  return isValidPassword(data) ? VALIDATION_STATE.VALID : VALIDATION_STATE.INVALID;
}

export function sanitizeEmail(data) {
  return data.trim();
}

export function isSameValue(first, second) {
  return first === second;
}
