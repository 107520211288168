import InsuranceModel from './InsuranceModel';

class InsuranceFactory {
  constructor() {
    this.insurance = {};
  }

  getInsurance(insurance) {
    this.insurance = {
      id: insurance.id,
      name: insurance.name,
    };
    return new InsuranceModel(this.insurance);
  }
}

export default new InsuranceFactory();
