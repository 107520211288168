<template>
  <HeadingCards
    :title="title"
    :text="text"
    @handlerSelectInterval="handlerSelectInterval"
  >
    <ReportCard
      :label="kpis.label"
      :count="kpis.amount"
      :percentage="kpis.percentage"
      :isLoading="isLoading"
    />
  </HeadingCards>
</template>

<script>
import HeadingCards from '@/components/molecules/HeadingCards/HeadingCards.vue';
import ReportCard from '@/components/atoms/Cards/ReportCard/ReportCard.vue';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    kpis: {
      type: [Object, Array],
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handlerSelectInterval(interval) {
      this.$emit('handleSelectInterval', interval);
    },
  },
  components: {
    HeadingCards,
    ReportCard,
  },
};
</script>
