<template>
  <SelectMiniCard
    :id="`${id}-selectBoolean`"
    :label="label"
    :value="this.value"
    :options="booleanOptions"
    @handleChangeSelect="handleChangeSelect"
  />
</template>

<script>
import SelectMiniCard from '@/components/atoms/Select/SelectMiniCard/SelectMiniCard.vue';
import { BOOLEAN_SELECTION_OPTIONS } from '@/helpers/ComponentHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    label: String,
  },
  data() {
    return {
      booleanOptions: BOOLEAN_SELECTION_OPTIONS,
    };
  },
  methods: {
    handleChangeSelect(value) {
      const boolValue = value === 'true' || value === 'false';

      this.$emit('handleChangeSelect', boolValue);
    },
  },
  components: {
    SelectMiniCard,
  },
};
</script>
