import { getAvatarUrl } from '@/helpers/UserHelper';

export default class HealthManagerModel {
  constructor(healthManagerData) {
    this.id = healthManagerData.id;
    this.lastname = healthManagerData.lastname;
    this.market = healthManagerData.market;
    this.name = healthManagerData.name;
    this.patients = healthManagerData.patients;
    this.phone = healthManagerData.phone;
    this.requests = healthManagerData.requests;
    this.email = healthManagerData.email;
    this.avatar = healthManagerData.avatar;
  }

  getAvatarUrl() {
    if (this.avatar !== null && Object.prototype.hasOwnProperty.call(this.avatar, 'path')) {
      return getAvatarUrl(this.avatar);
    }

    return null;
  }
}
