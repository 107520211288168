import UserModel from './UserModel';

class UserFactory {
  constructor() {
    this.user = {};
  }

  getUser(data) {
    this.user = new UserModel({
      id: data.id,
      avatar: data.avatar,
      email: data.email,
      name: data.userType.name,
      lastname: data.userType.lastname,
      phone: data.userType.phone,
      city: data.userType.city,
      contactName: data.userType.contactName,
      credentials: data.userType.credentials,
      description: data.userType.description,
      googleMyBusinessAccount: data.userType.googleMyBusinessAccount,
      hasInsurances: data.userType.hasInsurances,
      insurances: data.userType.insurances,
      yearFoundation: data.userType.yearFoundation,
      market: data.userType.market,
      specialty: data.userType.specialty,
      bio: data.userType.bio,
      details: data.userType.details,
      experience: data.userType.experience,
      roles: data.roles,
    });

    return this.user;
  }
}

export default new UserFactory();
