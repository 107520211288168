import {
  getRequest,
  getRequestDocuments,
  getAllRequest,
  filterRequest,
  searchRequest,
  createRequest,
  updateRequest,
  deleteRequest,
  changeRequestStatus,
  downloadRequestDocument,
} from './requestActions';

const request = {
  namespaced: true,
  actions: {
    getRequest,
    getRequestDocuments,
    getAllRequest,
    filterRequest,
    searchRequest,
    createRequest,
    updateRequest,
    deleteRequest,
    changeRequestStatus,
    downloadRequestDocument,
  },
};

export default request;
