<template>
  <div class="Stage">
    <p class="text__psb">{{ $i18n.t('lb_stage') }}</p>
    <div class="sp__v" />
    <div class="Stage__info text__psb">{{ stage }}</div>
  </div>
</template>

<script>
import { STAGE } from '@/helpers/ComponentHelper';

export default {
  props: {
    stage: {
      type: String,
      default: STAGE.RECEIVED.key,
    },
  },
};
</script>

<style lang="sass">
  @import './Stage.sass'
</style>
