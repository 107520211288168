var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumb',{attrs:{"items":[
      {
        name: _vm.$i18n.t('lb_doctors'),
        hasAction: true,
      },
      {
        name: ((_vm.doctor.name) + " " + (_vm.doctor.lastname)),
      } ]},on:{"handleClickAction":_vm.hideDetail}}),_c('div',{staticClass:"detailCard"},[_c('Avatar',{attrs:{"image":_vm.getAvatar,"size":"80px"}}),_c('div',{staticClass:"detailCard__actionBlock"},[_c('b-button',{attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.handleDelete.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$i18n.t('btn_delete'))+" ")]),_c('span',{staticClass:"sp__1s5x"}),_c('b-button',{attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.handleEdit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$i18n.t('btn_edit'))+" ")])],1)],1),_c('div',{staticClass:"sp__v__1s5x"}),_c('h2',[_vm._v(_vm._s(((_vm.doctor.name) + " " + (_vm.doctor.lastname))))]),_c('LabelGroup',{attrs:{"label":_vm.$i18n.t('lb_specialty'),"text":_vm.doctor.specialty.name,"isClear":""}}),_c('div',{staticClass:"sp__v__2x"}),_c('LabelGroup',{attrs:{"label":((_vm.$i18n.t('lb_email')) + ":"),"text":_vm.doctor.email}}),_c('div',{staticClass:"sp__v"}),_c('LabelGroup',{attrs:{"label":((_vm.$i18n.t('lb_phone')) + ":"),"text":_vm.doctor.phone}}),_c('div',{staticClass:"sp__v__2x"}),_c('TextGroup',{attrs:{"label":((_vm.$i18n.t('lb_bio')) + ":"),"text":_vm.doctor.bio}}),_c('div',{staticClass:"sp__v__1d5x"}),_c('TextGroup',{attrs:{"label":((_vm.$i18n.t('lb_professional_experience')) + ":"),"text":_vm.doctor.experience}}),_c('div',{staticClass:"sp__v__1d5x"}),_c('TextGroup',{attrs:{"label":((_vm.$i18n.t('lb_others')) + ":"),"text":_vm.doctor.details}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }