export default {
  // Search
  cp_search: 'Buscar',
  // Whatsapp
  cp_whatsapp_contact: 'Contactar por Whatsapp',
  cp_whatsapp_auth: 'El paciente ha dado autorización para poder contactar vía WhatsApp.',
  // Whatsapp
  cp_file_uploader_help: 'Aspect ratio 1:1 (Ej: 100x100 px)',
  // Heading
  cp_created_at: 'Creado el {date}',
  cp_patient_info_title: 'Información del paciente',
  cp_patient_info_text: 'Información básica sobre el paciente',
  cp_patient_contact_title: 'Información de contacto',
  cp_patient_contact_text: 'Información de contacto del paciente',
  cp_patient_request_text: 'Solicitudes realizadas por el paciente',
  cp_request_inbox_title: 'Inbox de solicitudes',
  cp_request_inbox_text: 'Buenos días, {name}. Explora tus solicitudes.',
  // Pagination
  cp_pagination_count: '<b>{from}</b> a <b>{to}</b> de <b>{total}</b> resultados',
  // Degrees
  cp_degree_low: 'Bajo',
  cp_degree_medium: 'Medio',
  cp_degree_high: 'Alto',
  // Boolean
  cp_boolean_true: 'Si',
  cp_boolean_false: 'No',
  // Stages
  cp_stage_received: 'Recibida',
  cp_stage_documentation_revision: 'Revisión documentación',
  cp_stage_specialist_evaluation: 'Valoración especialista',
  cp_stage_treatment_plan: 'Plan de tratamiento',
  cp_stage_in_treatment: 'En tratamiento',
  cp_stage_medical_discharge: 'Alta médica',
  // Stages
  cp_status_pending: 'Pendiente',
  cp_status_accepted: 'Aceptada',
  cp_status_rejected: 'Rechazada',
  // File uploader
  cp_upload_a_file: 'Sube un archivo',
  cp_or_drag: 'o arrastralo.',
  // Table
  cp_not_result: 'No hay resultados',
  // Modal
  cp_coming_soon: 'Próximamente',
  cp_coming_soon_text: 'Nuevas funcionalidades disponibles en la plataforma',
};
