<template>
  <SideBar
    class="SideForm__bar"
    :id="id"
    @handleAccept="handleCopy"
    @onHiddenSidebar="handleCancel"
    showFooter
  >
    <slot></slot>
    <RequestForm
      id="copyRequestForm"
      ref="copyRequestForm"
      :title="$i18n.t('lb_copy_request')"
      @handleOk="handleCopy"
      @handleCancel="handleCancel"
      :request="getRequest"
      :isLoading="isLoading"
      :validationState="validationState"
    />
  </SideBar>
</template>

<script>
import { uuid } from 'vue-uuid';
import SideBar from '@/components/atoms/SideBar/SideBar.vue';
import RequestForm from '@/components/organisms/Request/RequestForm.vue';
import { getCaseNumber } from '@/helpers/ApiHelper';
import { getValidationState, isValidForm } from '@/helpers/ValidationHelper';
import { getCurrentDate, STATUS } from '@/helpers/ComponentHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    request: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      validationState: {
        patient: null,
        specialty: null,
        level: null,
        pathology: null,
        medicalProcedure: null,
        clinic: null,
      },
    };
  },
  methods: {
    handleCopy() {
      const data = this.$refs.copyRequestForm.getData();

      const patientValidationState = getValidationState(
        data.patient?.id,
      );

      const specialtyValidationState = getValidationState(
        data.specialty?.id,
      );

      const levelValidationState = getValidationState(
        data.level,
      );

      const pathologyValidationState = getValidationState(
        data.pathology,
      );

      const medicalProcedureValidationState = getValidationState(
        data.medicalProcedure,
      );

      const clinicValidationState = getValidationState(
        data.clinic?.id,
      );

      if (isValidForm([
        patientValidationState,
        specialtyValidationState,
        levelValidationState,
        pathologyValidationState,
        medicalProcedureValidationState,
        clinicValidationState,
      ])) {
        this.update(data);
      } else {
        this.validationState.patient = patientValidationState;
        this.validationState.specialty = specialtyValidationState;
        this.validationState.level = levelValidationState;
        this.validationState.pathology = pathologyValidationState;
        this.validationState.medicalProcedure = medicalProcedureValidationState;
        this.validationState.clinic = clinicValidationState;
      }
    },

    getCaseNumber(data) {
      const lastPatientId = this.$refs.copyRequestForm.getLastPatientId();

      if (!lastPatientId || data.patient?.id === lastPatientId) {
        return data.caseNumber;
      }

      return getCaseNumber();
    },

    getNotesForRequest(notes) {
      const newNotes = notes.map((note) => {
        const newNote = {
          id: uuid.v4(),
          content: note.content,
        };

        return newNote;
      });

      return newNotes;
    },

    getDocumentsForRequest(documents) {
      const newDocuments = documents.map((document) => {
        const newDocument = {
          id: uuid.v4(),
          documentId: document.id,
        };

        return newDocument;
      });

      return newDocuments;
    },

    update(data) {
      const oldData = { ...data };
      const newData = {
        caseNumber: this.getCaseNumber(data),
        patient: data.patient?.id,
        specialty: data.specialty?.id,
        level: data.level,
        pathology: data.pathology,
        medicalProcedure: data.medicalProcedure,
        hasInsurance: data.hasInsurance,
        hasRecentReports: data.hasRecentReports,
        healthManager: data.healthManager?.id,
        clinic: data.clinic?.id,
        notes: this.getNotesForRequest(data.notes),
        documents: this.getDocumentsForRequest(data.documents),
      };

      this.isLoading = true;

      this.$store.dispatch('request/createRequest', newData)
        .then((res) => {
          const defaultStatus = STATUS.PENDING.key;
          const resData = { ...res };
          resData.patient = oldData.patient;
          resData.clinic = oldData.clinic;
          resData.healthManager = oldData.healthManager;
          resData.doctor = oldData.doctor;
          resData.createdAt = getCurrentDate();
          resData.specialty = oldData.specialty;
          resData.requestStage = oldData.requestStage;
          resData.status = defaultStatus;
          this.$emit('copyRequest', data);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    handleCancel() {
      this.$emit('handleCancel');
      this.resetValidations();
    },

    resetValidations() {
      this.validationState.patient = null;
      this.validationState.specialty = null;
      this.validationState.level = null;
      this.validationState.pathology = null;
      this.validationState.medicalProcedure = null;
      this.validationState.clinic = null;
    },
  },
  computed: {
    getRequest() {
      return this.request;
    },
  },
  components: {
    SideBar,
    RequestForm,
  },
};
</script>
