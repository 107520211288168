<template>
  <div>
    <Spinner
      :isLoading="isLoading"
      :variant="variant"
    />
    <b-button
      v-if="!isLoading"
      @click.prevent="handleClick"
      :variant="variant"
      block
    >
      <slot></slot>
    </b-button>
  </div>
</template>

<script>
import Spinner from '@/components/atoms/Spinner/Spinner.vue';

export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    block: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.$emit('handleClick');
    },
  },
  components: {
    Spinner,
  },
};
</script>
