<template>
  <div class="Checkbox">
    <b-form-checkbox
      :id="`${id}-checkbox`"
      v-model="status"
      name="`${id}-checkbox`"
      @change="getState"
      size="sm"
      :switch="isSwitch"
    >
      {{ label }}
    </b-form-checkbox>
    <p
      v-if="showDescription"
      :class="
          isSwitch ?
          'Checkbox__switchDescription text-secondary' :
          'Checkbox__description text-secondary'"
    >
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    isSwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: this.value,
    };
  },
  methods: {
    getState(data) {
      this.$emit('handleChange', data);
    },
  },
  computed: {
    showDescription() {
      return this.description.length > 0;
    },
  },
};
</script>

<style lang="sass">
  @import './Checkbox.sass'
</style>
