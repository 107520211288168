import CountryApi from '../../domain/country/CountryApi';

export default function getAllCountries({ commit }, data) {
  const countriesApi = new CountryApi();
  const getAllCountry = countriesApi.getAllCountries(data);

  return getAllCountry
    .then((res) => {
      commit('countries/setCountries', res, { root: true });
      return res;
    })
    .catch((error) => {
      commit('error/setError', error, { root: true });
      return Promise.reject(error);
    });
}
