<template>
  <div class="NotificationCard w-card">
    <div class="NotificationCard__block">
      <img :src="this.getIcon(notification.type)" />
      <div class="sp" />
      <p class="text__psb">{{ notification.message }}</p>
    </div>
    <div class="NotificationCard__block">
      <button
        @click.prevent="handleActionNotification(notification)"
        class="text__psb text-primary"
      >
        {{ $i18n.t('btn_watch') }}
      </button>
      <div class="sp" />
      <button
        @click.prevent="handleDelete(notification.id)"
      >
        <img class="text-primary" src="@/assets/actions/close.svg" />
      </button>
    </div>
  </div>
</template>

<script>
import newNotification from '@/assets/components/new-notification.svg';
import notificationCalendar from '@/assets/components/notification-calendar.svg';

const NOTIFICATION_TYPE = {
  REQUEST: 'request',
  EVENT: 'event',
};

export default {
  props: {
    notification: {
      type: Object,
      default: () => ({
        id: null,
        type: null,
        createdAt: null,
        isDeleted: null,
        message: null,
      }),
    },
  },
  methods: {
    getIcon(type) {
      switch (type) {
        case NOTIFICATION_TYPE.REQUEST:
          return newNotification;
        case NOTIFICATION_TYPE.EVENT:
          return notificationCalendar;
        default:
          return '';
      }
    },

    handleActionNotification(notification) {
      if (notification.type === NOTIFICATION_TYPE.REQUEST) {
        this.$emit('handleActionNotification', notification.id);
      }
    },

    handleDelete(id) {
      this.$emit('handleDelete', id);
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './NotificationCard.sass'
</style>
