export function setError(state, error) {
  console.log('error.response', error);
  if (error.response.status === 401) {
    const { data } = error?.response;
    state.title = `Error ${data?.message}`;

    state.list = [
      {
        field: data.message,
        message: 'Acción no permitida',
      },
    ];
    state.showError = true;
    return;
  }

  if (error.response.status === 405
    || error.response.status === 500
  ) {
    const { data } = error?.response;

    state.title = data.title;
    state.list = [
      {
        field: data.title,
        message: data.detail,
      },
    ];
    state.showError = true;
    return;
  }

  if (error.response.status === 404
    || error.response.status === 409
    || error.response.status === 422
  ) {
    const { data } = error?.response;
    state.title = `Error ${data?.message}`;

    state.list = [
      {
        field: data.message,
        message: data.errors,
      },
    ];
    state.showError = true;
    return;
  }

  const { data } = error?.response;

  if (data?.errors.length > 0) {
    state.title = data.message;
    state.list = data.errors;
    state.showError = true;
    return;
  }

  state.title = error.title;
  state.message = error.message;
  state.showError = true;
}

export function setLoginError(state, error) {
  const { data } = error?.response;

  state.title = `Error ${data?.code}`;
  state.list = [
    {
      field: data.code,
      message: data.message,
    },
  ];

  state.showError = true;
}

export function cleanError(state) {
  state.title = null;
  state.message = null;
  state.showError = false;
}
