import { getAvatarUrl } from '@/helpers/UserHelper';

export default class UserModel {
  constructor(userData) {
    this.id = userData.id;
    this.avatar = userData.avatar;
    this.name = userData.name;
    this.lastname = userData.lastname;
    this.city = userData.city;
    this.contactName = userData.contactName;
    this.credentials = userData.credentials;
    this.description = userData.description;
    this.googleMyBusinessAccount = userData.googleMyBusinessAccount;
    this.phone = userData.phone;
    this.email = userData.email;
    this.yearFoundation = userData.yearFoundation;
    this.roles = userData.roles;
    this.hasInsurances = userData.hasInsurances;
    this.insurances = userData.insurances;
    this.market = userData.market;
    this.specialty = userData.specialty;
    this.bio = userData.bio;
    this.details = userData.details;
    this.experience = userData.experience;
  }

  getAvatarUrl() {
    if (this.avatar !== null && Object.prototype.hasOwnProperty.call(this.avatar, 'path')) {
      return getAvatarUrl(this.avatar);
    }
    return null;
  }
}
