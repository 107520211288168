<template>
  <div class="SideForm">
    <p class="SideForm__title">{{ title }}</p>
    <div class="SideForm__content">
      <div class="sp__v"/>
      <div v-if="!isDoctor">
        <p class="text__psb">{{ `${$i18n.t('lb_patient')}:` }}</p>
        <div class="sp__v" />
        <DropdownSearch
          :text="getPatientDetail"
          :icon="this.requestData.patient.image ||
            require('@/assets/user/empty-avatar-dark.svg')"
          :actionText="$i18n.t('lb_new_patient')"
          :items="patientItems"
          :isLoading="isPatientDropdownLoading"
          :isPatient="isPatient"
          @handleSearch="handleSearchPatient"
          @handleSelectItem="handleSelectPatient"
          @handleAction="handleShowCreatePatient"
          :isValid="getValidationState.patient"
        />
        <div class="sp__v__2x"/>
      </div>
      <hr />
      <div
        v-if="isEdit"
        class="SideForm__cards">
        <LabelMiniCard
          :label="$i18n.t('lb_date')"
        >
          <p class="text__psb">
            {{ this.requestData.createdAt | formatDate }}
          </p>
        </LabelMiniCard>
        <SelectMiniCard
          :id="`${id}-requestFormStatus`"
          :label="$i18n.t('lb_status')"
          :value="this.requestData.status"
          :options="statusOptions"
          :isDisabled="isDisabled"
          @handleChangeSelect="handleChangeStatus"
        />
        <SelectMiniCard
          :id="`${id}-requestFormStage`"
          :label="$i18n.t('lb_stage')"
          :value="getRequestStage"
          :options="stageOptions"
          @handleChangeSelect="handleChangeStage"
        />
      </div>
      <div v-if="isEdit" class="sp__v__1d5x"/>
      <div v-if="!isEdit" class="sp__v"/>
      <div class="SideForm__cards">
        <SelectMiniCard
          :id="`${id}-level`"
          :label="$i18n.t('lb_emergency_degree')"
          :value="this.requestData.level"
          :options="levelOptions"
          @handleChangeSelect="handleChangeLevel"
          :isValid="getValidationState.level"
        />
        <SelectBoolean
          :id="`${id}-hasInsurance`"
          :label="$i18n.t('lb_private_insurance')"
          :value="this.requestData.hasInsurance"
          @handleChangeSelect="handleChangeHasInsurance"
        />
        <SelectBoolean
          :id="`${id}-hasRecentReports`"
          :label="$i18n.t('lb_recent_reports')"
          :value="this.requestData.hasRecentReports"
          @handleChangeSelect="handleChangeRecentReports"
        />
      </div>
      <div class="sp__v__1d5x"/>
      <hr />
      <div class="sp__v"/>
      <b-tabs>
        <b-tab :title="$i18n.t('lb_summary')">
          <div class="sp__v__1d5x"/>
          <RequestSummary
            :id="id"
            :request="this.requestData"
            :validationState="getValidationState"
            @handleChangeSpecialty="handleChangeSpecialty"
            @handleChangePathology="handleChangePathology"
            @handleChangeMedicalProcedure="handleChangeMedicalProcedure"
          />
        </b-tab>
        <b-tab :title="getDocumentsTitle">
          <div class="sp__v__1d5x"/>
          <DocumentTable
            v-if="hasDocuments"
            :items="this.requestData.documents"
            @handleDelete="handleShowDeleteDocumentModal"
            canDelete
          />
          <div class="sp__v" />
          <b-button
            variant="primary"
            size="sm"
            @click.prevent="showDocumentModal"
          >
            {{ `+ ${$i18n.t('btn_new')}` }}
          </b-button>
          <div class="sp__v__1s5x" />
        </b-tab>
        <b-tab :title="getNotesTitle">
          <div class="sp__v__1d5x"/>
          <NotesTable
            v-if="hasNotes"
            :items="this.requestData.notes"
            @handleShowDetail="handleShowDetailNote"
            @handleEdit="handleShowEditNote"
            @handleDelete="handleShowDeleteNoteModal"
            canEdit
            canDelete
          />
          <div class="sp__v" />
            <b-button
              variant="primary"
              size="sm"
              @click.prevent="showCreateNoteModal"
            >
              {{ `+ ${$i18n.t('btn_new_f')}` }}
            </b-button>
          <div class="sp__v__1s5x" />
        </b-tab>
      </b-tabs>
      <div v-if="isVidasprime">
        <hr />
        <p class="text__psb">
          {{ $i18n.t('lb_health_manager') }}:
        </p>
        <div class="sp__v" />
        <DropdownSearch
          :text="hasHealthManager
            ? this.requestData.healthManager.name
            : $i18n.t('lb_add_health_manager')"
          :icon="hasHealthManager
            ? this.requestData.healthManager.image
            : require('@/assets/components/plus-dark.svg')"
          :actionText="$i18n.t('lb_new_health_manager')"
          :items="healthManagerItems"
          :isCard="true"
          :isLoading="isHealthManagerDropdownLoading"
          @handleSearch="handleSearchHealthManagers"
          @handleSelectItem="handleSelectHealthManager"
          @handleAction="handleShowCreateHealthManager"
        />
        <div class="sp__v__1s5x" />
      </div>
      <div v-if="isClinic">
        <hr />
        <p class="text__psb">
          {{ $i18n.t('lb_doctor') }}:
        </p>
        <div class="sp__v" />
        <DropdownSearch
          :text="hasDoctor
            ? this.requestData.doctor.name
            : $i18n.t('lb_add_doctor')"
          :icon="hasDoctor
            ? this.requestData.doctor.image
            : require('@/assets/components/plus-dark.svg')"
          :actionText="$i18n.t('lb_new_doctor')"
          :items="doctorItems"
          :isCard="true"
          :isLoading="isDoctorDropdownLoading"
          @handleSearch="handleSearchDoctors"
          @handleSelectItem="handleSelectDoctor"
          @handleAction="handleShowCreateDoctor"
        />
        <div class="sp__v__1s5x" />
      </div>
      <div v-if="isVPorHM">
        <hr />
        <div class="sp__v" />
        <p class="text__psb">{{ `${$i18n.t('lb_clinic')}:` }}</p>
        <div class="sp__v" />
        <!-- <DropdownSearchMultiple
          :text="this.requestData.clinic.name || $i18n.t('lb_add_clinic')"
          :icon="this.requestData.clinic.image ||
            require('@/assets/components/plus-dark.svg')"
          :actionText="$i18n.t('lb_new_clinic')"
          :items="clinicItems"
          :isCard="true"
          :isLoading="isClinicDropdownLoading"
          @handleSearch="handleSearchClinics"
          @handleAction="handleShowCreateClinic"
          @handleChange="handleChangeClinicList"
        /> -->
        <DropdownSearch
          :text="hasClinic
            ? this.requestData.clinic.name
            : $i18n.t('lb_add_clinic')"
          :icon="hasClinic
            ? this.requestData.clinic.image
            : require('@/assets/components/plus-dark.svg')"
          :actionText="$i18n.t('lb_new_clinic')"
          :items="clinicItems"
          :isCard="true"
          :isLoading="isClinicDropdownLoading"
          @handleSearch="handleSearchClinics"
          @handleSelectItem="handleSelectClinic"
          @handleAction="handleShowCreateClinic"
          :isValid="getValidationState.clinic"
        />
        <div class="sp__v" />
      </div>
    </div>
      <CreatePatient
        v-if="showCreatePatient"
        @addPatient="addPatient"
        @handleCancel="closeCreatePatient"
      />
      <CreateHealthManager
        v-if="showCreateHealthManager"
        @addHealthManager="addHealthManager"
        @handleCancel="closeCreateHealthManager"
      />
      <CreateClinic
        v-if="showCreateClinic"
        @addClinic="addClinic"
        @handleCancel="closeCreateClinic"
      />
      <CreateDoctor
        v-if="showCreateDoctor"
        @addDoctor="addDoctor"
        @handleCancel="closeCreateDoctor"
      />
      <DocumentModal
        :id="`${id}-requestFormDocumentModal`"
        @handleUploadFile="handleCreateDocument"
      />
      <DeleteConfirmModal
        :id="`${id}-requestFormDeleteDocument`"
        ref="requestFormDeleteDocument"
        @handleDelete="handleDeleteDocument"
        :title="$i18n.t('lb_confirm_title')"
        :text="$i18n.t('lb_confirm_text')"
      />
      <NoteModal
        :id="`${id}-requestFormCreateNoteModal`"
        ref="requestFormCreateNoteModal"
        :isLoading="isNoteLoaing"
        @handleUploadNote="handleCreateNote"
        isEditable
      />
      <NoteModal
        :id="`${id}-requestFormViewNoteModal`"
        ref="requestFormViewNoteModal"
        :isLoading="isNoteLoaing"
      />
      <NoteModal
        :id="`${id}-requestFormUpdateNoteModal`"
        ref="requestFormUpdateNoteModal"
        :isLoading="isNoteLoaing"
        @handleUploadNote="handleUploadNote"
        isEditable
      />
      <DeleteConfirmModal
        :id="`${id}-requestFormDeleteNote`"
        ref="requestFormDeleteNote"
        @handleDelete="handleDeleteNote"
        :title="$i18n.t('lb_confirm_title')"
        :text="$i18n.t('lb_confirm_text')"
      />
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import SelectMiniCard from '@/components/atoms/Select/SelectMiniCard/SelectMiniCard.vue';
import SelectBoolean from '@/components/atoms/Select/SelectBoolean.vue';
import LabelMiniCard from '@/components/atoms/Cards/LabelMiniCard/LabelMiniCard.vue';
import DropdownSearch from '@/components/atoms/Dropdown/DropdownSearch.vue';
// import DropdownSearchMultiple from '@/components/atoms/Dropdown/DropdownSearchMultiple.vue';
import defaultRequest from '@/views/Request/defaultRequest';
import CreatePatient from '@/components/organisms/Patient/CreatePatient.vue';
import CreateHealthManager from '@/components/organisms/HealthManager/CreateHealthManager.vue';
import CreateClinic from '@/components/organisms/Clinic/CreateClinic.vue';
import CreateDoctor from '@/components/organisms/Doctor/CreateDoctor.vue';
import DocumentTable from '@/components/molecules/Tables/DocumentTable/DocumentTable.vue';
import NotesTable from '@/components/molecules/Tables/NotesTable/NotesTable.vue';
import DocumentModal from '@/components/molecules/Modal/DocumentModal.vue';
import NoteModal from '@/components/molecules/Modal/NoteModal.vue';
import DeleteConfirmModal from '@/components/molecules/Modal/DeleteConfirmModal.vue';
import RequestSummary from './RequestSummary.vue';
import {
  getImageFormatted,
  getCurrentDate,
  EMERGENCY_DEGREE_OPTIONS,
  BOOLEAN_SELECTION_OPTIONS,
  STATUS_OPTIONS,
  STAGE_OPTIONS,
} from '@/helpers/ComponentHelper';
import HealthManagerModel from '@/domain/healthManager/HealthManagerModel';
import PatientModel from '@/domain/patient/PatientModel';
import ClinicModel from '@/domain/clinic/ClinicModel';
import DoctorModel from '@/domain/doctor/DoctorModel';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    request: {
      type: Object,
      default: () => defaultRequest,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    validationState: {
      type: Object,
      default: () => ({
        patient: null,
        specialty: null,
        level: null,
        pathology: null,
        medicalProcedure: null,
        clinic: null,
      }),
    },
  },
  data() {
    return {
      requestData: {
        id: this.request?.id,
        caseNumber: this.request?.caseNumber,
        createdAt: this.request?.createdAt,
        instance: this.request?.instance,
        level: this.request?.level,
        hasInsurance: this.request?.hasInsurance,
        hasRecentReports: this.request?.hasRecentReports,
        patient: {
          id: this.request?.id,
          created: this.request?.created,
          name: this.request?.name,
          lastname: this.request?.lastname,
          gender: this.request?.gender,
          origin: this.request?.origin,
          dni: this.request?.dni,
          dob: this.request?.dob,
          tutor: this.request?.tutor,
          insurance: this.request?.insurance,
          hospital: this.request?.hospital,
          email: this.request?.email,
          phone: this.request?.phone,
          requests: this.request?.requests,
          contactWhatsApp: this.request?.contactWhatsApp,
        },
        specialty: this.request?.specialty,
        pathology: this.request?.pathology,
        medicalProcedure: this.request?.medicalProcedure,
        status: this.request?.status,
        requestStage: this.request?.requestStage,
        healthManager: {
          id: this.request?.healthManager?.id,
          image: this.request?.healthManager?.image,
          name: this.request?.healthManager?.name,
          lastname: this.request?.healthManager?.lastname,
          market: this.request?.healthManager?.market,
          totalRequest: this.request?.healthManager?.totalRequest,
          patientsCount: this.request?.healthManager?.patientsCount,
          email: this.request?.healthManager?.email,
          phone: this.request?.healthManager?.phone,
        },
        clinics: [],
        clinic: {
          id: this.request?.clinic?.id,
          image: this.request?.clinic?.image,
          name: this.request?.clinic?.name,
          contactName: this.request?.clinic?.contactName,
          city: this.request?.clinic?.city,
          yearFoundation: this.request?.clinic?.yearFoundation,
          description: this.request?.clinic?.description,
          acreditation: this.request?.clinic?.acreditation,
          googleMyBusinessAccount: this.request?.clinic?.googleMyBusinessAccount,
          email: this.request?.clinic?.email,
          phone: this.request?.clinic?.phone,
          vpRequest: this.request?.clinic?.vpRequest,
          totalRequest: this.request?.clinic?.totalRequest,
          totalPatients: this.request?.clinic?.totalPatients,
        },
        doctor: {
          id: this.request?.doctor.id,
          avatar: this.request?.doctor.avatar,
          name: this.request?.doctor.name,
          lastname: this.request?.doctor.lastname,
          specialty: this.request?.doctor.specialty,
          bio: this.request?.doctor.bio,
          experience: this.request?.doctor.experience,
          details: this.request?.doctor.details,
          email: this.request?.doctor.email,
          phone: this.request?.doctor.phone,
        },
        documents: this.request?.documents,
        notes: this.request?.notes,
      },
      levelOptions: EMERGENCY_DEGREE_OPTIONS,
      booleanOptions: BOOLEAN_SELECTION_OPTIONS,
      statusOptions: STATUS_OPTIONS,
      stageOptions: STAGE_OPTIONS,
      patientItems: [],
      healthManagerItems: [],
      clinicItems: [],
      doctorItems: [],
      showCreatePatient: false,
      showCreateHealthManager: false,
      showCreateClinic: false,
      showCreateDoctor: false,
      isPatientDropdownLoading: false,
      isHealthManagerDropdownLoading: false,
      isClinicDropdownLoading: false,
      isDoctorDropdownLoading: false,
      lastPatientId: null,
      isNoteLoaing: false,
      isDocumentLoaing: false,
      isPatient: true,
    };
  },
  methods: {
    handleChangeLevel(text) {
      this.requestData.level = text;
    },

    handleChangeStatus(value) {
      this.requestData.status = value;
    },

    handleChangeStage(value) {
      this.requestData.requestStage = value;
    },

    handleChangeHasInsurance(value) {
      this.requestData.hasInsurance = value;
    },

    handleChangeRecentReports(value) {
      this.requestData.hasRecentReports = value;
    },

    handleChangeSpecialty(value) {
      this.requestData.specialty = value;
    },

    handleChangePathology(value) {
      this.requestData.pathology = value;
    },

    handleChangeMedicalProcedure(value) {
      this.requestData.medicalProcedure = value;
    },

    handleSelectPatient(value) {
      if (!this.lastPatientId) {
        this.lastPatientId = this.requestData.patient.id;
      }

      const newPatient = new PatientModel({
        id: value.id,
        created: value.created,
        name: value.name,
        lastname: value.lastname,
        gender: value.gender,
        origin: value.origin,
        dni: value.dni,
        dob: value.dob,
        tutor: value.tutor,
        insurance: value.insurance,
        hospital: value.hospital,
        email: value.email,
        phone: value.phone,
        requests: value.requests,
        contactWhatsApp: value.contactWhatsApp,
      });

      this.requestData.patient = newPatient;

      this.patientItems = [];
    },

    handleSelectHealthManager(value) {
      const newHealthManager = new HealthManagerModel({
        id: value.id,
        lastname: value.lastname,
        market: value.market,
        name: value.name,
        patients: value.patients,
        phone: value.phone,
        requests: value.requests,
        email: value.email,
        avatar: value.avatar,
      });

      this.requestData.healthManager = newHealthManager;

      this.requestData.healthManagerItems = [];
    },

    handleChangeClinicList(items) {
      this.requestData.clinics = items;
      this.clinicItems = [];
    },

    handleSelectClinic(value) {
      const newClinic = new ClinicModel({
        id: value.id,
        city: value.city,
        contactName: value.contactName,
        credentials: value.credentials,
        description: value.description,
        googleMyBusinessAccount: value.googleMyBusinessAccount,
        isEnabled: value.isEnabled,
        name: value.name,
        patients: value.patients,
        phone: value.phone,
        requests: value.requests,
        totalRequests: value.totalRequests,
        email: value.email,
        avatar: value.avatar,
        yearFoundation: value.yearFoundation,
      });

      this.requestData.clinic = newClinic;

      this.requestData.clinicItems = [];
    },

    handleSelectDoctor(value) {
      const newDoctor = new DoctorModel({
        id: value.id,
        avatar: value.avatar,
        name: value.name,
        lastname: value.lastname,
        specialty: value.specialty,
        bio: value.bio,
        experience: value.experience,
        details: value.details,
        email: value.email,
        phone: value.phone,
      });

      this.requestData.doctor = newDoctor;

      this.requestData.doctorItems = [];
    },

    resetData() {
      Object.assign(this.requestData, defaultRequest);
    },

    getFilters(text) {
      const filtersGroup = [];

      if (text !== null) {
        filtersGroup.push(
          `name:${text}`,
        );
      }

      return filtersGroup;
    },

    handleSearchPatient(text) {
      if (text !== null) {
        this.isPatientDropdownLoading = true;

        this.$store.dispatch('patient/searchPatient', this.getFilters(text))
          .then((res) => {
            this.patientItems = res.patientList;
            this.isPatientDropdownLoading = false;
          })
          .catch(() => {
            this.isPatientDropdownLoading = false;
          });
      }
    },

    handleSearchHealthManagers(text) {
      if (text !== null) {
        this.isHealthManagerDropdownLoading = true;

        this.$store.dispatch('healthManager/searchHealthManager', this.getFilters(text))
          .then((res) => {
            this.healthManagerItems = res.healthManagerList;
            this.isHealthManagerDropdownLoading = false;
          })
          .catch(() => {
            this.isHealthManagerDropdownLoading = false;
          });
      }
    },

    handleSearchClinics(text) {
      if (text !== null) {
        this.isClinicDropdownLoading = true;

        this.$store.dispatch('clinic/searchClinic', this.getFilters(text))
          .then((res) => {
            this.clinicItems = res.clinicList;
            this.isClinicDropdownLoading = false;
          })
          .catch(() => {
            this.isClinicDropdownLoading = false;
          });
      }
    },

    handleSearchDoctors(text) {
      if (text !== null) {
        this.isDoctorDropdownLoading = true;

        this.$store.dispatch('doctor/searchDoctor', this.getFilters(text))
          .then((res) => {
            this.doctorItems = res.doctorList;
            this.isDoctorDropdownLoading = false;
          })
          .catch(() => {
            this.isDoctorDropdownLoading = false;
          });
      }
    },

    handleShowCreatePatient() {
      this.showCreatePatient = true;
    },

    closeCreatePatient() {
      this.showCreatePatient = false;
    },

    handleShowCreateHealthManager() {
      this.showCreateHealthManager = true;
    },

    closeCreateHealthManager() {
      this.showCreateHealthManager = false;
    },

    handleShowCreateClinic() {
      this.showCreateClinic = true;
    },

    closeCreateClinic() {
      this.showCreateClinic = false;
    },

    handleShowCreateDoctor() {
      this.showCreateDoctor = true;
    },

    closeCreateDoctor() {
      this.showCreateDoctor = false;
    },

    getCreateData(data) {
      const newData = data;
      newData.image = getImageFormatted(data.image);

      return newData;
    },

    addPatient(data) {
      this.requestData.patient = this.getCreateData(data);

      this.closeCreatePatient();
    },

    addHealthManager(data) {
      this.requestData.healthManager = this.getCreateData(data);

      this.closeCreateHealthManager();
    },

    addClinic(data) {
      this.requestData.clinic = this.getCreateData(data);

      this.closeCreateClinic();
    },

    addDoctor(data) {
      this.requestData.doctor = this.getCreateData(data);

      this.closeCreateDoctor();
    },

    showDocumentModal() {
      this.$bvModal.show(`${this.id}-requestFormDocumentModal`);
    },

    closeDocumentModal() {
      this.$bvModal.hide(`${this.id}-requestFormDocumentModal`);
    },

    showCreateNoteModal() {
      const newNote = {
        id: uuid.v4(),
        content: '',
        createdAt: getCurrentDate(),
      };

      this.$refs.requestFormCreateNoteModal.setNote(newNote);
      this.$bvModal.show(`${this.id}-requestFormCreateNoteModal`);
    },

    handleShowDetailNote(note) {
      this.$refs.requestFormViewNoteModal.setNote(note);
      this.$bvModal.show(`${this.id}-requestFormViewNoteModal`);
    },

    handleShowEditNote(note) {
      this.$refs.requestFormUpdateNoteModal.setNote(note);
      this.$bvModal.show(`${this.id}-requestFormUpdateNoteModal`);
    },

    closeCreateNoteModal() {
      this.$bvModal.hide(`${this.id}-requestFormCreateNoteModal`);
    },

    closeUpdateNoteModal() {
      this.$bvModal.hide(`${this.id}-requestFormUpdateNoteModal`);
    },

    addDocument(id, name) {
      const newDocument = {
        id,
        fileName: name,
        createdAt: getCurrentDate(),
        path: null,
        requestDocumentId: null,
      };

      this.requestData.documents.push(newDocument);
    },

    handleCreateDocument(document) {
      const documentId = uuid.v4();

      const requestData = {
        patientId: this.requestData?.patient?.id,
        document: {
          id: documentId,
          fileName: document.file.name,
          encodedFile: document.base64.encodedFile,
          mimeType: document.file.type,
          extension: document.base64.extension,
        },
      };

      this.isDocumentLoaing = true;

      this.$store.dispatch('document/createDocument', requestData)
        .then(() => {
          this.addDocument(documentId, document.file.name);
          this.closeDocumentModal();
          this.isDocumentLoaing = false;
        })
        .catch(() => {
          this.isDocumentLoaing = false;
        });
    },

    addNewNote(note) {
      this.requestData.notes.unshift(note);

      this.closeCreateNoteModal();
    },

    createNoteRequest(note) {
      const requestData = {
        requestId: this.requestData?.id,
        note: {
          id: note.id,
          content: note.content,
        },
      };

      this.isNoteLoaing = true;

      this.$store.dispatch('note/createNote', requestData)
        .then(() => {
          this.addNewNote(note);
          this.isNoteLoaing = false;
        })
        .catch(() => {
          this.isNoteLoaing = false;
        });
    },

    handleCreateNote(note) {
      if (this.isEdit) {
        this.createNoteRequest(note);
      } else {
        this.addNewNote(note);
      }
    },

    updateNote(note) {
      for (let i = 0; this.requestData.notes.length; i += 1) {
        if (this.requestData.notes[i].id === note.id) {
          const newNote = {
            id: note.id,
            content: note.content,
            createdAt: note.createdAt,
          };

          this.requestData.notes.splice(i, 1);
          this.requestData.notes.splice(i, 0, newNote);
          break;
        }
      }

      this.closeUpdateNoteModal();
    },

    updateNoteRequest(note) {
      const requestData = {
        requestId: this.requestData?.id,
        noteId: note.id,
        content: note.content,
      };

      this.isNoteLoaing = true;

      this.$store.dispatch('note/updateNote', requestData)
        .then(() => {
          this.updateNote(note);
          this.isNoteLoaing = false;
        })
        .catch(() => {
          this.isNoteLoaing = false;
        });
    },

    handleUploadNote(note) {
      if (this.isEdit) {
        this.updateNoteRequest(note);
      } else {
        this.updateNote(note);
      }
    },

    handleShowDeleteDocumentModal(documentId) {
      this.$refs.requestFormDeleteDocument.setUuid(documentId);
      this.$bvModal.show(`${this.id}-requestFormDeleteDocument`);
    },

    deleteDocument(documentId) {
      this.$bvModal.hide(`${this.id}-requestFormDeleteDocument`);

      for (let i = 0; this.requestData.documents.length; i += 1) {
        if (this.requestData.documents[i].id === documentId) {
          this.requestData.documents.splice(i, 1);
          break;
        }
      }
    },

    getDocumentRequest(documentId) {
      for (let i = 0; i < this.requestData.documents.length; i += 1) {
        if (this.requestData.documents[i].id === documentId) {
          return this.requestData.documents[i].requestDocumentId;
        }
      }

      return null;
    },

    deleteDocumentRequest(action, requestData) {
      this.$store.dispatch(action, requestData)
        .then(() => {
          this.deleteDocument(requestData.documentId);
          this.isDocumentLoaing = false;
        })
        .catch(() => {
          this.isDocumentLoaing = false;
        });
    },

    handleDeleteDocument(documentId) {
      this.isDocumentLoaing = true;

      if (this.getDocumentRequest(documentId)) {
        const requestData = {
          requestId: this.requestData?.id,
          requestDocumentId: this.getDocumentRequest(documentId),
          documentId,
        };
        this.deleteDocumentRequest('document/deleteRequestDocument', requestData);
      } else {
        const requestData = {
          patientId: this.requestData?.patient?.id,
          documentId,
        };
        this.deleteDocumentRequest('document/deleteDocument', requestData);
      }
    },

    handleShowDeleteNoteModal(noteId) {
      this.$refs.requestFormDeleteNote.setUuid(noteId);
      this.$bvModal.show(`${this.id}-requestFormDeleteNote`);
    },

    deleteNote(noteId) {
      this.$bvModal.hide(`${this.id}-requestFormDeleteNote`);

      for (let i = 0; this.requestData.notes.length; i += 1) {
        if (this.requestData.notes[i].id === noteId) {
          this.requestData.notes.splice(i, 1);
          break;
        }
      }
    },

    deleteNoteRequest(noteId) {
      const requestData = {
        requestId: this.requestData?.id,
        noteId,
      };

      this.isNoteLoaing = true;

      this.$store.dispatch('note/deleteNote', requestData)
        .then(() => {
          this.deleteNote(noteId);
          this.isNoteLoaing = false;
        })
        .catch(() => {
          this.isNoteLoaing = false;
        });
    },

    handleDeleteNote(noteId) {
      if (this.isEdit) {
        this.deleteNoteRequest(noteId);
      } else {
        this.deleteNote(noteId);
      }
    },

    getLastPatientId() {
      return this.lastPatientId;
    },

    getData() {
      let newRequest = this.requestData;

      if (typeof this.requestData.requestStage === 'object') {
        newRequest = {
          ...this.requestData,
          requestStage: this.requestData.requestStage.id,
        };

        return newRequest;
      }
      return newRequest;
    },
  },
  computed: {
    hasDocuments() {
      return this.requestData.documents !== undefined && this.requestData.documents.length > 0;
    },

    getDocumentsTitle() {
      return this.requestData.documents !== undefined && this.requestData?.documents.length > 0 ? this.$i18n.t('lb_documents_count', { count: this.requestData.documents.length }) : this.$i18n.t('lb_documents');
    },

    hasNotes() {
      return this.requestData.notes !== undefined && this.requestData.notes.length > 0;
    },

    getNotesTitle() {
      return this.requestData.notes !== undefined && this.requestData?.notes.length > 0 ? this.$i18n.t('lb_notes_count', { count: this.requestData.notes.length }) : this.$i18n.t('lb_notes');
    },

    isVidasprime() {
      return this.$store.getters['user/isVidasprime'];
    },

    isHealthManager() {
      return this.$store.getters['user/isHealthManager'];
    },

    isClinic() {
      return this.$store.getters['user/isClinic'];
    },

    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },

    isVPorHM() {
      return this.isVidasprime || this.isHealthManager;
    },

    getValidationState() {
      return this.validationState;
    },

    hasHealthManager() {
      return this.requestData.healthManager
        && this.requestData.healthManager.id !== null
        && this.requestData.healthManager.id !== undefined;
    },

    hasClinic() {
      return this.requestData.clinic
        && this.requestData.clinic.id !== null
        && this.requestData.clinic.id !== undefined;
    },

    hasDoctor() {
      return this.requestData.doctor
        && this.requestData.doctor.id !== null
        && this.requestData.doctor.id !== undefined;
    },

    getRequestStage() {
      const stage = this.requestData.requestStage;
      return stage && (stage.id !== undefined)
        ? stage.id
        : stage;
    },

    getPatientDetail() {
      return this.requestData.patient.name
      || (this.requestData.patient.name && this.requestData.patient.lastname)
        ? `${this.requestData.patient.name} ${this.requestData.patient.lastname}`
        : this.$i18n.t('lb_select_patient');
    },
  },
  watch: {
    request(newValue) {
      this.requestData.id = newValue.id;
      this.requestData.caseNumber = newValue.caseNumber;
      this.requestData.createdAt = newValue.createdAt;
      this.requestData.instance = newValue.instance;
      this.requestData.level = newValue.level;
      this.requestData.hasInsurance = newValue.hasInsurance;
      this.requestData.hasRecentReports = newValue.hasRecentReports;
      this.requestData.patient = newValue.patient;
      this.requestData.specialty = newValue.specialty;
      this.requestData.pathology = newValue.pathology;
      this.requestData.medicalProcedure = newValue.medicalProcedure;
      this.requestData.status = newValue.status;
      this.requestData.requestStage = newValue.requestStage;
      this.requestData.healthManager = newValue.healthManager;
      this.requestData.clinic = newValue.clinic;
      this.requestData.documents = newValue.documents;
      this.requestData.notes = newValue.notes;
      this.requestData.doctor = newValue.doctor;
    },
  },
  components: {
    SelectMiniCard,
    SelectBoolean,
    RequestSummary,
    LabelMiniCard,
    DropdownSearch,
    // DropdownSearchMultiple,
    CreatePatient,
    CreateHealthManager,
    CreateClinic,
    CreateDoctor,
    DocumentTable,
    DocumentModal,
    DeleteConfirmModal,
    NotesTable,
    NoteModal,
  },
};
</script>

<style lang="sass" scoped>
@import './SideForm.sass'
</style>
