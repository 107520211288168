import i18n from '@/plugins/vue-i18n';

export default [
  {
    key: 'name',
    label: i18n.t('lb_name'),
    sortable: true,
  },
  {
    key: 'specialty',
    label: i18n.t('lb_specialty'),
    sortable: true,
  },
  {
    key: 'vidasprimeRequest',
    label: i18n.t('lb_vp_request'),
    sortable: true,
  },
  {
    key: 'totalRequest',
    label: i18n.t('lb_total_request'),
    sortable: true,
  },
  {
    key: 'patientsCount',
    label: i18n.t('lb_patients'),
    sortable: true,
  },
  {
    key: 'actions',
    label: '',
  },
];
