<template>
  <div class="ForgotPasswordForm">
    <img
      src="@/assets/logos/logo_inline.svg"
      class="w-75"
    />
    <div class="sp__v" />
    <p class="text__pr" v-html="getText" />
    <div class="sp__v__2x" />
    <InputGroup
      id="forgotEmail"
      placeholder="email"
      @handlerInputChange="handleChangeEmail"
      :isValid="this.validationState.email"
      :error="$i18n.t('val_email')"
    />
    <div class="sp__v__1d5x" />
    <FormButton
      @handleClick="handleForgotPassword"
      :isLoading="isLoading"
    >
      {{ getButtonForgotPasswordText }}
    </FormButton>
  </div>
</template>

<script>
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import FormButton from '@/components/atoms/Buttons/FormButton/FormButton.vue';
import {
  getEmailValidationState,
  isValidForm,
  sanitizeEmail,
} from '@/helpers/ValidationHelper';

export default {
  data: () => ({
    loginState: {
      email: null,
    },
    validationState: {
      email: null,
    },
    isLoading: false,
  }),

  methods: {
    handleChangeEmail(text) {
      this.loginState.email = text;
    },

    async resetPassword() {
      this.isLoading = true;
      const resetData = { email: this.loginState.email };
      await this.$store.dispatch('auth/userPasswordReset', resetData)
        .then((res) => {
          this.isLoading = false;
          if (res === true) {
            this.$router.push({ name: 'Login' });
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.resetState();
        });
    },

    handleForgotPassword() {
      if (this.loginState.email) {
        this.setSanitizedEmail();
      }

      this.isLoading = true;
      const emailValidationState = getEmailValidationState(this.loginState.email);

      if (isValidForm([emailValidationState])) {
        this.resetValidationState();
        this.resetPassword();
      } else {
        this.validationState.email = emailValidationState;
        this.isLoading = false;
        this.resetState();
      }
    },

    resetState() {
      this.loginState.email = null;
    },

    resetValidationState() {
      this.validationState.email = null;
    },

    setSanitizedEmail() {
      const sanitizedEmail = sanitizeEmail(this.loginState.email);
      this.loginState.email = sanitizedEmail;
    },
  },

  computed: {

    getText() {
      return this.$i18n.t('txt_forgot_password');
    },

    getButtonForgotPasswordText() {
      return this.$i18n.t('btn_forgot_password');
    },
  },

  components: {
    InputGroup,
    FormButton,
  },
};
</script>
