<template>
  <div class="d-flex">
    <p
      :class="
        isClear ?
        'text__sr text-secondary' :
        'text__sb'"
    >
      {{ label }}
    </p>
    <div class="sp__1xs5x"></div>
    <p
      :class="
        isClear ?
        'text__sr text-secondary' :
        'text__sr'"
    >
      {{ text }}
    </p>
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    isClear: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
