<template>
  <div class="Pagination">
    <PaginationCounter
      :pagination="pagination"
    />
    <b-pagination
      v-model="currentPage"
      :per-page="itemsPerPage"
      :total-rows="rows"
      @change="handleRequest"
      first-number
    />
  </div>
</template>

<script>
import PaginationCounter from './PaginationCounter.vue';
import { ITEMS_PER_PAGE } from '@/helpers/ApiHelper';

export default {
  props: {
    pagination: {
      type: Object,
      default: () => {},
    },
    itemsPerPage: {
      type: Number,
      default: ITEMS_PER_PAGE,
    },
  },
  data() {
    return {
      currentPage: this.pagination?.currentPage,
      perPage: this.pagination?.itemsPerPage,
      rows: this.pagination?.totalItems,
    };
  },
  methods: {
    handleRequest(page) {
      this.$emit('handleRequest', page);
    },
  },
  watch: {
    pagination(newValue) {
      this.currentPage = newValue.currentPage;
      this.perPage = newValue.itemsPerPage;
      this.rows = newValue.totalItems;
    },
  },
  components: {
    PaginationCounter,
  },
};
</script>

<style lang="sass" scoped>
  @import './Pagination.sass'
</style>
