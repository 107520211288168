<template>
  <div class="Badge pr-1">
    <b-badge
      class="d-flex align-items-center"
      pill
      :variant="this.variant"
    >
      <span class="pr-1">{{ this.item.name }}</span>
      <button
        @click.prevent="handleDelete"
      >
        <img
          :width="size"
          :height="size"
          src="@/assets/actions/close.svg"
        />
      </button>
    </b-badge>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      size: 10,
      variant: 'neutral',
    };
  },
  methods: {
    handleDelete() {
      this.$emit('handleDelete', this.item.id);
    },
  },
};
</script>
