import {
  getAllHealthManager,
  filterHealthManager,
  searchHealthManager,
  createHealthManager,
  updateHealthManager,
  deleteHealthManager,
} from './healthManagerActions';

const healthManager = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    getAllHealthManager,
    filterHealthManager,
    searchHealthManager,
    createHealthManager,
    updateHealthManager,
    deleteHealthManager,
  },
  getters: {
  },
};

export default healthManager;
