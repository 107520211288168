import getAxiosWithAuthHeader from '../../helpers/AuthHelper';
import {
  getDataWithUuid,
  getParameterUrl,
  getSearchUrl,
} from '../../helpers/ApiHelper';
import HealthManagerFactory from './HealthManagerFactory';

const HEALTH_MANAGERS = '/api/health-managers';
const SEARCH_HEALTH_MANAGERS = '/api/health-managers/search';

class healthManagerAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllHealthManager(currentPage) {
    const parameterUrl = getParameterUrl(HEALTH_MANAGERS, currentPage);

    return this.client
      .get(parameterUrl)
      .then((res) => HealthManagerFactory.getAllHealthManager(res.data))
      .catch((error) => Promise.reject(error));
  }

  filterHealthManager(data) {
    const parameterUrl = getParameterUrl(
      SEARCH_HEALTH_MANAGERS,
      data.page,
      data.filters,
      data.ordenation,
    );

    return this.client
      .get(parameterUrl)
      .then((res) => HealthManagerFactory.getAllHealthManager(res.data))
      .catch((error) => Promise.reject(error));
  }

  searchHealthManager(filters) {
    const filterUrl = getSearchUrl(SEARCH_HEALTH_MANAGERS, filters);

    return this.client
      .get(filterUrl)
      .then((res) => HealthManagerFactory.getAllHealthManager(res.data))
      .catch((error) => Promise.reject(error));
  }

  createHealthManager(data) {
    const idData = getDataWithUuid(data);

    return this.client
      .post(HEALTH_MANAGERS, idData)
      .then(() => idData)
      .catch((error) => Promise.reject(error));
  }

  updateHealthManager(requestData) {
    const url = `${HEALTH_MANAGERS}/${requestData.id}`;

    return this.client
      .put(url, requestData.body)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteHealthManager(id) {
    const url = `${HEALTH_MANAGERS}/${id}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default healthManagerAPI;
